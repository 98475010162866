import { FinancialProps } from './../../models/FinancialProps'
import { api, getConfigApi } from './../../services/api'

export const getFinancialContent = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('financial-sustainability', args)
}

export const createFinancialContent = async (params?: any, data?: FinancialProps): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.post('financial-sustainability/create', data, args)
}

export const updateFinancialContent = async (data: FinancialProps): Promise<unknown> => {
    return await api.patch('financial-sustainability/update', data, getConfigApi())
}
