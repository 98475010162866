import styled from "styled-components";
import { Variables } from "../../../styles/variables";

export const Container = styled.div`
.modal-filters {
    .ods-options {
        .ui.checkbox input:checked~label:after {
            top: -25%;
            transform: translateY(50%);
        }

        label {
            display: flex;
            align-items: center;

            &:before {
                top: -25%;
                transform: translateY(50%);
            }
            
            img {
                height: 24px;
                margin-right: 8px;
            }

            .text {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                color: #000000;
            }
        }
    }
}
    .modal {
        &.fade {
            z-index: 1111;
            background: rgba(0,0,0,.5);
        }

        .modal-dialog {
            max-width: 720px;
            width: 100%;

            .modal-content {
                border-radius: 28px;
                padding: 0px;

                .modal-header {
                    padding: 0px;
                    border: unset;
                    padding: 0 24px;
                    display: flex;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);

                    .icon {
                        img {
                            width: 16px;
                            height: 18px;
                        }
                    }
                    
                    .modal-title {
                        color: #1C1B1F;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.1px;
                    }
                }

                .modal-body {
                    padding: 16px 24px;
                }
                
                .modal-footer {
                    padding: 10px 24px;
                    border: unset;

                    .btn-footer {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.1px;
                        background: transparent;
                        border: unset;

                        .btn-first {
                            color: #516274;
                        }

                        &.btn-create {
                            color: #006768;
                        }

                        &.btn-delete {
                            color: #B3261E;
                        }
                    }
                }
            }
        }
    }
`