import axios from 'axios'

const baseURL = 'https://api.perifasul.org/api/v1/'

export const api = axios.create({ baseURL })

export const getSimpleConfigApi = () => {
    return {
        headers: {
            Accept: 'application/json',
        }
    }
}

export const getConfigApi = () => {
    const localStorageKeys = Object.keys(localStorage)
    let token = ''

    if (localStorageKeys.includes('usuario')) {
        token = localStorage.getItem('usuario') ? JSON.parse(localStorage.getItem('usuario') || '{}')?.authorisation.token : ''
    } else if (localStorageKeys.includes('authentication-admin')) {
        token = localStorage.getItem('authentication-admin') ? JSON.parse(localStorage.getItem('authentication-admin') || '{}').authorisation.token : ''
    }

    return {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
        }
    }
}

export const getConfigApiNoAccept = () => {
    const localStorageKeys = Object.keys(localStorage)
    let token = ''

    if (localStorageKeys.includes('usuario')) {
        token = localStorage.getItem('usuario') ? JSON.parse(localStorage.getItem('usuario') || '{}')?.authorisation.token : ''
    } else if (localStorageKeys.includes('authentication-admin')) {
        token = localStorage.getItem('authentication-admin') ? JSON.parse(localStorage.getItem('authentication-admin') || '{}').authorisation.token : ''
    }

    return {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
        }
    }
}