import { Variables } from './../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
.btn-filter {
    button {
        background: #516274;
        color: #FFF;
        border-radius: 8px;
        margin-bottom: 16px;
    }
}

.show-initiative-summary-modal {
    .modal-content {
        padding: 0px !important;

        .modal-header {
            display: flex;
            justify-content: right;
            text-align: right !important;

            .icon,
            .modal-title {
                display: none;
            }

            .btn-close {
                margin: 5px 10px 0px 0px;
            }
        }

        .modal-body {
            .modal-description {
                margin-bottom: 0px !important;
            }

            .initiative-resume {
                border: unset;
            }
        }

        .modal-footer {
            display: none;
        }
    }
}

.companies {
    min-height: calc(100vh - 102px);

    .title {
        display: flex;

        .text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }

        hr {
            border: 0.5px solid #516274;
            width: 100%;
            margin-left: 21px;
        }
    }

    .cards {
        margin-top: 25px;
        margin-bottom: 35px;

        .card-btn {
            display: none;
        }

        .card {
            cursor: pointer;
            min-height: 68px;
            background: rgba(243, 243, 243, 0.9);
            opacity: 0.95;
            padding: 8px 16px;
            border-radius: 12px;
            border: unset;
            margin-top: 25px;

            .info {
                .name {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 4px;
                }

                .address {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }
            }

            .type-status {
                position: relative;
                background: ${Variables.gray};
                margin-top: 0px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0px;
                width: 140px;
                height: 40px;
                border: 1px solid #516274;
                border-radius: 50px;

                .type {
                    cursor: pointer;
                    color: #fff;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    padding: 10px 16px;
                    z-index: 11;
                }

                .bg-type {
                    position: absolute;

                    &.status {
                        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                        border-radius: 50px;
                        height: 40px;
                        
                        &.inactive {
                            background: #F4C600;
                            left: -3px;
                            min-width: 82px;
                            transition: all 0.7s ease-in-out;
                        }
                        
                        &.active {
                            background: ${Variables.success};
                            left: 72px;
                            min-width: 76px;
                            transition: all 0.7s ease-in-out;
                        }
                    }
                }
            }

            .status {
                display: flex;

                &.active {
                    .circle {
                        background-color: ${Variables.success};
                    }

                    .text {
                        color: ${Variables.success};
                    }
                }

                &.inactive {
                    .circle {
                        background-color: ${Variables.danger};
                    }

                    .text {
                        color: ${Variables.danger};
                    }
                }

                .circle {
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    display: inline-block;
                }

                .text {
                    margin-left: 16px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                }
            }
        }
    }
}

.modal {
    .modal-dialog {
        width: 312px;
        .modal-content {
            padding: 24px;
            width: unset;
        }
    }
}

.all-filter-modal {
    overflow: unset;

    .modal-dialog {
        margin-top: 25px;
        width: auto;

        .modal-content {
            padding: unset;

            .modal-body {
                max-height: calc(100vh - 170px);
                overflow-y: auto;
            }

            .modal-footer {
                box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.12);
            }
        }
    }
}

@media (max-width: 575.99px) {
    .show-initiative-summary-modal {
        .modal-content {
            padding: 0px !important;
        }
    }

    .companies {
        .cards {
            .card {
                .type-status {
                    margin: 12px 0px;
                }
            }
        }
    }

    .all-filter-modal {
        .modal-dialog {
            margin-top: 0px;
        }
    }
}
`