import { SolidarityBankProps } from './../../models/SolidarityBankProps'
import { api, getConfigApi } from './../../services/api'

export const getSolidarityBank = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('solidarity-bank', args)
}

export const createSolidarityBank = async (data?: SolidarityBankProps): Promise<unknown> => {
    return await api.post('solidarity-bank/create', data, getConfigApi())
}

export const updateSolidarityBank = async (data: SolidarityBankProps): Promise<unknown> => {
    return await api.patch('solidarity-bank/update', data, getConfigApi())
}

// CONGRATULATIONS

export const getCongratulations = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('congratulations', args)
}