import { NetworksPartnersProps } from './../../models/NetworksPartnersProps'
import { api, getConfigApi } from './../../services/api'

export const getNetworksPartners = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('networks-partner', args)
}

export const createNetworksPartners = async (data?: NetworksPartnersProps): Promise<unknown> => {
    return await api.post('networks-partner/create', data, getConfigApi())
}

export const updateNetworksPartners = async (data: NetworksPartnersProps): Promise<unknown> => {
    return await api.patch('networks-partner/update', data, getConfigApi())
}

export const fetchInitiatives = async (): Promise<unknown> => {
    return await api.get('networks-partner/initiatives', getConfigApi())
}

export const fetchInvitations = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('invite', args)
}

export const sendInvitation = async (data: any): Promise<unknown> => {
    return await api.post('networks-partner/invite', data, getConfigApi())
}
