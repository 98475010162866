import styled from "styled-components";

export const Container = styled.div`
position: relative;
min-height: calc(100vh - 60px);
padding-top: 32px;
padding-bottom: 104px;

.text-01 {
    color: #000;
    font-weight: 600;
    margin-bottom: 23px;
}

.text-02 {
    color: #000;
    margin-bottom: 37px;
}

.text-03 {
    color: #000;
    margin-bottom: 14px;
}

.ui {
    &.form {
        position: unset;

        .line {
            margin: 40px 0px 32px;
        }

        .pdfs-btns {
            display: flex;
            margin-top: 34px;
        }

        .footer {
            width: 100%;
            position: absolute;
            bottom: 28px;
        }
    }
}

.use-term-modal,
.privacy-policy-modal {
    .modal-dialog {
        max-width: 100%;
        margin: 20px;

        .modal-content {
            padding: 16px;

            .modal-header {
                display: block;
                text-align: right;
                margin-right: 5px;
                margin-bottom: 10px;

                .icon,
                .modal-title {
                    display: none;
                }

                .btn-close {
                    border: 2px solid;
                    border-radius: 100px;
                    transform: rotate(-180deg);
                    transition: all 300ms ease-in-out;

                    &:hover {
                        transform: rotate(180deg);
                        transition: all 300ms ease-in-out;
                    }
                }
            }

            .modal-body {
                .modal-description {
                    margin-bottom: 0px;
                }
            }

            .modal-footer {
                padding: 10px 0px;

                .btn-first {
                    background: #F4C600;
                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                    border-radius: 50px;
                    height: 40px;
                    padding: 1px 24px;
                    font-weight: 600;
                }
            }
        }
    }
}
`