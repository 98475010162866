import styled from "styled-components";

export const Container = styled.div`
    .col-md-6:first-of-type {
        background: #516274;
    }

    .left-content {
        background: #516274;
        position: relative;
        height: 100vh;

        .box-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translatex(-50%) translatey(-50%);

            .title-content {
                top: 0px;
                text-align: center;
                position: absolute;
                color: #fff;
                font-weight: 600;
                font-size: 32px;
                line-height: 44px;
                width: 100%;
            }
            
            .box {
                margin-top: 155px;
                padding: 16px 56px;
                width: 370px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 16px;

                .title {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                }
    
                .form {
                    margin: 24px 0px;
                    width: 258px;
        
                    .field {
                        label {
                            text-align: left;
                        }
                    }
            
                    .btn-submit {
                        width: 258px;
                    }
                }
            }
            
        }
    }

    .right-content {
        height: 90vh;
        padding: 20px 0px;

        img {
            width: 80%;
            height: 80%;
        }
    }

    @media (max-width: 575.98px) {
        .left-content {
            .box-container {
                .title-content {
                    font-size: 30px;
                }

                .box {
                    width: 100%;
                    padding: 16px 40px;
                }
            }
        }
    }
`