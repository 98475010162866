import { Form, Radio } from "semantic-ui-react"
import { withMask } from "use-mask-input"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import { annualBudgetOptions, approvedProjectLawsOptions, areasExpertiseOptions, cityActionsOptions, coverageAreaOtions, defaultOptions, districtLocaleOptions, documentsOptions, hasTitleQualificationOptions, initiativeHasOptions, initiativeIsOtions, initiativeNatureOtions, legalFormatOtions, marketSegmentOptions, monthlyBillingOptions, motivatingPublicOptions, odsOptions, offerTerritoryAvailableOptions, percentWomenOptions, productTypeOptions, providersAvailableOptions, publicOptions, serviceTypeOptions, socialImpactDimensionOptions, whatIdoOptions, whichArtOptions, youAreOptions } from "../../../api/utils/fieldsOptions"

type Props = {
    youAre: number[],
    setYouAre: (value: number[]) => void
    yearFoundation: string,
    setYearFoundation: (value: string) => void
    whatIdo: number[],
    setWhatIdo: (value: number[]) => void
    initiativeNature: number[],
    setInitiativeNature: (value: number[]) => void
    initiativeIs: number[],
    setInitiativeIs: (value: number[]) => void
    legalFormat: number[],
    setLegalFormat: (value: number[]) => void
    coverageArea: number[],
    setCoverageArea: (value: number[]) => void
    motivatingPublic: number[],
    setMotivatingPublic: (value: number[]) => void
    socialImpactDimension: number[],
    setSocialImpactDimension: (value: number[]) => void
    hasTitleQualification: number[],
    setHasTitleQualification: (value: number[]) => void
    percentWomen: number[],
    setPercentWomen: (value: number[]) => void
    marketSegment: number[],
    setMarketSegment: (value: number[]) => void
    whichArt: number[],
    setWhichArt: (value: number[]) => void
    documents: number[],
    setDocuments: (value: number[]) => void
    approvedProjectLaws: number[],
    setApprovedProjectLaws: (value: number[]) => void
    initiativeHas: number[],
    setInitiativeHas: (value: number[]) => void
    annualBudget: number[],
    setAnnualBudget: (value: number[]) => void
    monthlyBilling: number[],
    setMonthlyBilling: (value: number[]) => void
    odsValues: number[],
    setOdsValues: (value: number[]) => void
    areasExpertise: number[],
    setAreasExpertise: (value: number[]) => void
    city: number[],
    setCity: (value: number[]) => void
    district: number[],
    setDistrict: (value: number[]) => void
    hasCnpj: number | undefined,
    setHasCnpj: (value: number | undefined) => void
    publicValues: number[],
    setPublicValues: (value: number[]) => void
    productType: number[],
    setProductType: (value: number[]) => void
    serviceType: number[],
    setServiceType: (value: number[]) => void
    isSouthPeripherySP: number | undefined,
    setIsSouthPeripherySP: (value: number | undefined) => void
    hasPoliticalParty: number | undefined,
    setHasPoliticalParty: (value: number | undefined) => void
    hasChurchAffiliation: number | undefined,
    setHasChurchAffiliation: (value: number | undefined) => void
    hasDrt: number | undefined,
    setHasDrt: (value: number | undefined) => void
    hasMission: number | undefined,
    setHasMission: (value: number | undefined) => void
    hasVision: number | undefined,
    setHasVision: (value: number | undefined) => void
    passAudit: number | undefined,
    setPassAudit: (value: number | undefined) => void
    hasCounter: number | undefined,
    setHasCounter: (value: number | undefined) => void
    hasEquip: number | undefined,
    setHasEquip: (value: number | undefined) => void
    partnerForumNetwork: number | undefined,
    setPartnerForumNetwork: (value: number | undefined) => void
    offerTerritoryAvailable: number[],
    setOfferTerritoryAvailable: (value: number[]) => void
    providersAvailable: number[],
    setProvidersAvailable: (value: number[]) => void
}

export const ModalFilterContent = ({
    youAre,
    setYouAre,
    yearFoundation,
    setYearFoundation,
    whatIdo,
    setWhatIdo,
    initiativeNature,
    setInitiativeNature,
    initiativeIs,
    setInitiativeIs,
    legalFormat,
    setLegalFormat,
    coverageArea,
    setCoverageArea,
    motivatingPublic,
    setMotivatingPublic,
    socialImpactDimension,
    setSocialImpactDimension,
    hasTitleQualification,
    setHasTitleQualification,
    percentWomen,
    setPercentWomen,
    marketSegment,
    setMarketSegment,
    whichArt,
    setWhichArt,
    documents,
    setDocuments,
    approvedProjectLaws,
    setApprovedProjectLaws,
    initiativeHas,
    setInitiativeHas,
    hasMission,
    setHasMission,
    hasVision,
    setHasVision,
    passAudit,
    setPassAudit,
    annualBudget,
    setAnnualBudget,
    monthlyBilling,
    setMonthlyBilling,
    hasCounter,
    setHasCounter,
    hasEquip,
    setHasEquip,
    partnerForumNetwork,
    setPartnerForumNetwork,
    odsValues,
    setOdsValues,
    areasExpertise,
    setAreasExpertise,
    city,
    setCity,
    district,
    setDistrict,
    hasCnpj,
    setHasCnpj,
    publicValues,
    setPublicValues,
    productType,
    setProductType,
    serviceType,
    setServiceType,
    isSouthPeripherySP,
    setIsSouthPeripherySP,
    hasPoliticalParty,
    setHasPoliticalParty,
    hasChurchAffiliation,
    setHasChurchAffiliation,
    hasDrt,
    setHasDrt,
    offerTerritoryAvailable,
    setOfferTerritoryAvailable,
    providersAvailable,
    setProvidersAvailable,
}: Props): JSX.Element => {

    const onChangeYouAre = (checked: boolean | undefined, key: number): void => {
        let youAreList: number[] = youAre;
        
        if (checked) {
            youAreList = [key]
        } else {
            youAreList = []
        }

        setYouAre(youAreList)
    }

    const onChangeWhatIdo = (checked: boolean | undefined, key: number): void => {
        let whatIdoList: number[] = [...whatIdo];

        if (checked) {
            whatIdoList.push(key)
        } else {
            whatIdoList = whatIdoList.filter(k => k !== key)
        }

        setWhatIdo(whatIdoList)
    }

    const onChangeInitiativeNature = (checked: boolean | undefined, key: number): void => {
        let initiativeNatureList: number[] = [...initiativeNature];

        if (checked) {
            initiativeNatureList.push(key)
        } else {
            initiativeNatureList = initiativeNatureList.filter(k => k !== key)
        }

        setInitiativeNature(initiativeNatureList)
    }

    const onChangeInitiativeIs = (checked: boolean | undefined, key: number): void => {
        let initiativeIsList: number[] = [...initiativeIs];

        if (checked) {
            initiativeIsList.push(key)
        } else {
            initiativeIsList = initiativeIsList.filter(k => k !== key)
        }

        setInitiativeIs(initiativeIsList)
    }

    const onChangeLegalFormat = (checked: boolean | undefined, key: number): void => {
        let legalFormatList: number[] = [...legalFormat];

        if (checked) {
            legalFormatList.push(key)
        } else {
            legalFormatList = legalFormatList.filter(k => k !== key)
        }

        setLegalFormat(legalFormatList)
    }

    const onChangeCoverageArea = (checked: boolean | undefined, key: number): void => {
        let coverageAreaList: number[] = [...coverageArea];

        if (checked) {
            coverageAreaList.push(key)
        } else {
            coverageAreaList = coverageAreaList.filter(k => k !== key)
        }

        setCoverageArea(coverageAreaList)
    }

    const onChangeSocialImpactDimension = (checked: boolean | undefined, key: number): void => {
        let socialImpactDimensionList: number[] = [...socialImpactDimension];

        if (checked) {
            socialImpactDimensionList.push(key)
        } else {
            socialImpactDimensionList = socialImpactDimensionList.filter(k => k !== key)
        }

        setSocialImpactDimension(socialImpactDimensionList)
    }

    const onChangeHasTitleQualification = (checked: boolean | undefined, key: number): void => {
        let hasTitleQualificationList: number[] = [...hasTitleQualification];

        if (checked) {
            hasTitleQualificationList.push(key)
        } else {
            hasTitleQualificationList = hasTitleQualificationList.filter(k => k !== key)
        }

        setHasTitleQualification(hasTitleQualificationList)
    }

    const onChangePercentWomen = (checked: boolean | undefined, key: number): void => {
        let percentWomenList: number[] = [...percentWomen];

        if (checked) {
            percentWomenList.push(key)
        } else {
            percentWomenList = percentWomenList.filter(k => k !== key)
        }

        setPercentWomen(percentWomenList)
    }

    const onChangeMarketSegment = (checked: boolean | undefined, key: number): void => {
        let marketSegmentList: number[] = [...marketSegment];

        if (checked) {
            marketSegmentList.push(key)
        } else {
            marketSegmentList = marketSegmentList.filter(k => k !== key)
        }

        setMarketSegment(marketSegmentList)
    }

    const onChangeWhichArt = (checked: boolean | undefined, key: number): void => {
        let whichArtList: number[] = [...whichArt];

        if (checked) {
            whichArtList.push(key)
        } else {
            whichArtList = whichArtList.filter(k => k !== key)
        }

        setWhichArt(whichArtList)
    }

    const onChangeDocuments = (checked: boolean | undefined, key: number): void => {
        let documentsList: number[] = [...documents];

        if (checked) {
            documentsList.push(key)
        } else {
            documentsList = documentsList.filter(k => k !== key)
        }

        setDocuments(documentsList)
    }

    const onChangeApprovedProjectLaws = (checked: boolean | undefined, key: number): void => {
        let approvedProjectLawsList: number[] = [...approvedProjectLaws];

        if (checked) {
            approvedProjectLawsList.push(key)
        } else {
            approvedProjectLawsList = approvedProjectLawsList.filter(k => k !== key)
        }

        setApprovedProjectLaws(approvedProjectLawsList)
    }

    const onChangeInitiativeHas = (checked: boolean | undefined, key: number): void => {
        let initiativeHasList: number[] = [...initiativeHas];

        if (checked) {
            initiativeHasList.push(key)
        } else {
            initiativeHasList = initiativeHasList.filter(k => k !== key)
        }

        setInitiativeHas(initiativeHasList)
    }

    const onChangeAnnualBudget = (checked: boolean | undefined, key: number): void => {
        let annualBudgetList: number[] = [...annualBudget];

        if (checked) {
            annualBudgetList.push(key)
        } else {
            annualBudgetList = annualBudgetList.filter(k => k !== key)
        }

        setAnnualBudget(annualBudgetList)
    }

    const onChangeMonthlyBilling = (checked: boolean | undefined, key: number): void => {
        let monthlyBillingList: number[] = [...monthlyBilling];

        if (checked) {
            monthlyBillingList.push(key)
        } else {
            monthlyBillingList = monthlyBillingList.filter(k => k !== key)
        }

        setMonthlyBilling(monthlyBillingList)
    }

    const onChangeOds = (checked: boolean | undefined, key: number): void => {
        let odsList: number[] = [...odsValues];

        if (checked) {
            odsList.push(key)
        } else {
            odsList = odsList.filter(k => k !== key)
        }

        setOdsValues(odsList)
    }

    const onChangeAreasExpertise = (checked: boolean | undefined, key: number): void => {
        let areasExpertiseList: number[] = [...areasExpertise];

        if (checked) {
            areasExpertiseList.push(key)
        } else {
            areasExpertiseList = areasExpertiseList.filter(k => k !== key)
        }

        setAreasExpertise(areasExpertiseList)
    }

    const onChangeCity = (checked: boolean | undefined, key: number): void => {
        let cityList: number[] = [...city];

        if (checked) {
            cityList.push(key)
        } else {
            cityList = cityList.filter(k => k !== key)
        }

        setCity(cityList)
    }

    const onChangeDistrict = (checked: boolean | undefined, key: number): void => {
        let districtList: number[] = [...district];

        if (checked) {
            districtList.push(key)
        } else {
            districtList = districtList.filter(k => k !== key)
        }

        setDistrict(districtList)
    }

    const onChangePublicValues = (checked: boolean | undefined, key: number): void => {
        let publicValuesList: number[] = publicValues;

        if (checked) {
            publicValuesList = [key]
        } else {
            publicValuesList = []
        }

        setPublicValues(publicValuesList)
    }

    const onChangeProductType = (checked: boolean | undefined, key: number): void => {
        let productTypeList: number[] = [...productType];

        if (checked) {
            productTypeList.push(key)
        } else {
            productTypeList = productTypeList.filter(k => k !== key)
        }

        setProductType(productTypeList)
    }

    const onChangeServiceType = (checked: boolean | undefined, key: number): void => {
        let serviceTypeList: number[] = [...serviceType];

        if (checked) {
            serviceTypeList.push(key)
        } else {
            serviceTypeList = serviceTypeList.filter(k => k !== key)
        }

        setServiceType(serviceTypeList)
    }

    const onChangeOfferTerritoryAvailable = (checked: boolean | undefined, key: number, filter?: boolean): void => {
        let offerTerritoryAvailableList: number[] = [...offerTerritoryAvailable];

        if (checked) {
            offerTerritoryAvailableList.push(key)
        } else {
            offerTerritoryAvailableList = offerTerritoryAvailableList.filter(k => k !== key)
        }

        setOfferTerritoryAvailable(offerTerritoryAvailableList)
    }

    const onChangeProvidersAvailable = (checked: boolean | undefined, key: number, filter?: boolean): void => {
        let providersAvailableList: number[] = [...providersAvailable];

        if (checked) {
            providersAvailableList.push(key)
        } else {
            providersAvailableList = providersAvailableList.filter(k => k !== key)
        }

        setProvidersAvailable(providersAvailableList)
    }

    return (
        <>
            <div className="row ods-options mb-16">
                <div className="fw-600 mb-16 fc-black">Tipo da Iniciativa</div>

                {youAreOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><img src={o.icon} /><div className="text">{o.text}</div></label>}
                            checked={youAre.length ? youAre?.includes(o.value) : false}
                            onChange={(e, { checked }) => {
                                onChangeYouAre(checked, o.value)
                            }}
                        />
                    </div>
                ))}
            </div>

            {/* <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">O que faço</div>

                {whatIdoOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={whatIdo.length ? whatIdo?.includes(o.key) : false}
                            onChange={(e, { checked }) => {
                                onChangeWhatIdo(checked, o.key)
                            }}
                        />
                    </div>
                ))}
            </div> */}

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Natureza da iniciativa</div>

                {initiativeNatureOtions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={initiativeNature.length ? initiativeNature?.includes(o.key) : false}
                            onChange={(e, { checked }) => {
                                onChangeInitiativeNature(checked, o.key)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Iniciativa é</div>

                {initiativeIsOtions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={initiativeIs.length ? initiativeIs?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeInitiativeIs(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Formato jurídico</div>

                {legalFormatOtions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={legalFormat.length ? legalFormat?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeLegalFormat(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row ods-options mb-16">
                <div className="fw-600 mb-16 fc-black">ODSs (Objetivos do Desenvolvimento Sustentável) que iniciativa está relacionada</div>

                {odsOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><img src={o.icon} /><div className="text">{o.text}</div></label>}
                            checked={odsValues.length ? odsValues?.includes(o.key) : false}
                            onChange={(e, { checked }) => {
                                onChangeOds(checked, o.key)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Área de atuação da iniciativa</div>

                {areasExpertiseOptions.map((o, index) => (
                    <div className="col-md-12" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={areasExpertise.length ? areasExpertise?.includes(o.key) : false}
                            onChange={(e, { checked }) => {
                                onChangeAreasExpertise(checked, o.key)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Cidade que a iniciativa desenvolve ações</div>

                {cityActionsOptions.slice(1).map((o, index) => (
                    <div className="col-md-4" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={city.length ? city?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeCity(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Distrito em que a iniciativa está localizada</div>

                {districtLocaleOptions.slice(1).map((o, index) => (
                    <div className="col-md-4" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={district.length ? district?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeDistrict(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Área de abrangência de atuação da iniciativa</div>

                {coverageAreaOtions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={coverageArea.length ? coverageArea?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeCoverageArea(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Iniciativa possui CNPJ</div>

                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasCnpj === o.value}
                            onChange={(e, { value }) => setHasCnpj(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Há quanto tempo a iniciativa existe? Insira o ano de fundação.</div>

                <div className="col-md-6">
                    <Form.Input
                        fluid
                        size="huge"
                        placeholder='AAAA'
                        value={yearFoundation}
                        onChange={function (e, { value }) {
                            setYearFoundation(value)
                        }}
                    >
                        <label className="label-inside">Tempo</label>
                        <input ref={withMask('9999')} />
                        <div
                            className="img-icon flex-cc"
                            onClick={() => {
                                setYearFoundation('')
                            }}>
                            <img src={closeInputIcon} alt="close-input" />
                        </div>
                    </Form.Input>
                </div>
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Público motivador da iniciativa</div>

                {publicOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={publicValues.length ? publicValues?.includes(o.value) : false}
                            onChange={(e, { checked }) => {
                                onChangePublicValues(checked, o.value)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Qual a dimensão que melhor descreve o impacto social produzido pela iniciativa</div>

                {socialImpactDimensionOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={socialImpactDimension.length ? socialImpactDimension?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeSocialImpactDimension(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Título ou qualificação da iniciativa</div>

                {hasTitleQualificationOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={hasTitleQualification.length ? hasTitleQualification?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeHasTitleQualification(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Quantos % dos colaboradores da iniciativa são mulheres</div>

                {percentWomenOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={percentWomen.length ? percentWomen?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangePercentWomen(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa é ligada a algum partido político ou algum de seus representantes/funcionários exercem algum cargo político/público</div>

                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasPoliticalParty === o.value}
                            onChange={(e, { value }) => setHasPoliticalParty(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa ou algum de seus representantes/funcionários tem afiliação com alguma igreja</div>

                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasChurchAffiliation === o.value}
                            onChange={(e, { value }) => setHasChurchAffiliation(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Tipo do produto</div>

                {productTypeOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={productType.length ? productType?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeProductType(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Tipo do serviço</div>

                {serviceTypeOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={serviceType.length ? serviceType?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeServiceType(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Qual é a arte</div>

                {whichArtOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={whichArt.length ? whichArt?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeWhichArt(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Possui DRT</div>

                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasDrt === o.value}
                            onChange={(e, { value }) => setHasDrt(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Documentos que sua iniciativa possui</div>
                {documentsOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={documents.length ? documents?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeDocuments(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Leis de incentivo a iniciativa tem projetos aprovados</div>
                {approvedProjectLawsOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={approvedProjectLaws.length ? approvedProjectLaws?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeApprovedProjectLaws(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Iniciativa possui</div>
                {initiativeHasOptions.map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={initiativeHas.length ? initiativeHas?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeInitiativeHas(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa tem uma missão definida</div>
                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasMission === o.value}
                            onChange={(e, { value }) => setHasMission(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa tem uma visão definida</div>
                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasVision === o.value}
                            onChange={(e, { value }) => setHasVision(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa passa por auditoria</div>
                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={passAudit === o.value}
                            onChange={(e, { value }) => setPassAudit(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Orçamento anual da iniciativa</div>
                {annualBudgetOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={annualBudget.length ? annualBudget?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeAnnualBudget(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Faturamento mensal da iniciativa</div>

                {monthlyBillingOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={monthlyBilling.length ? monthlyBilling?.includes(o.key as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeMonthlyBilling(checked, o.key as number)
                            }}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Segmento de mercado</div>
                {marketSegmentOptions.slice(1).map((o, index) => (
                    <div className="col-md-6" key={index}>
                        <Form.Checkbox
                            key={o.key}
                            className="mb-0"
                            label={<label><div className="text">{o.text}</div></label>}
                            checked={marketSegment.length ? marketSegment?.includes(o.value as number) : false}
                            onChange={(e, { checked }) => {
                                onChangeMarketSegment(checked, o.value as number)
                            }}
                        />
                    </div>
                ))}
            </div>



            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa possui um contador dedicado</div>
                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasCounter === o.value}
                            onChange={(e, { value }) => setHasCounter(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">A iniciativa tem equipe dedicada para captação de recursos</div>
                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={hasEquip === o.value}
                            onChange={(e, { value }) => setHasEquip(value as number)}
                        />
                    </div>
                ))}
            </div>

            <div className="row mb-16">
                <div className="fw-600 mb-16 fc-black">Sua iniciativa atua em parceria ou faz parte de algum fórum ou rede</div>
                {defaultOptions.slice(1).map((o, index) => (
                    <div className="col-md-3" key={index}>
                        <Radio
                            label={o.text}
                            value={o.value}
                            checked={partnerForumNetwork === o.value}
                            onChange={(e, { value }) => setPartnerForumNetwork(value as number)}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}