import { Variables } from './../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
    height: 56px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #F4C600;
    border-radius: 0px 0px 8px 8px;
    position: fixed;
    z-index: 1111;
    width: 100%;

    .navbar {
        .logo {
            cursor: pointer;
        }
        
        .dropdown-img {
            button {
                background: transparent;
                border: unset;

                img {
                    height: 40px;
                    border-radius: 100%;
                }
            }

            .dropdown-menu {
                right: 0px;
                
                li {
                    padding: 8px;
                    gap: 8px;
                
                    &:hover {
                        background: ${Variables.yellow};
                    }

                    .dropdown-item {
                        padding: 0px;
                        
                        &:hover {
                            background: unset;
                        }
                    }
                }
            }
        }

        .collapse {
            .login-text {
                cursor: pointer;
                margin-right: 16px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                margin-right: 20px;
                color: #516274;
            }

            .leave-text {
                cursor: pointer;
                margin-left: 16px;
                margin-right: 16px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                height: 42px;
                color: #516274;

                .icon {
                    margin-right: 9.5px;
                }
            }

            .btn-register {
                margin-right: 24px;
            }
        }
    }

    @media (max-width: 575.98px) {
        .navbar {
            .navbar-collapse {
                margin-top: 12px;
            }
        }
    }

    @media (max-width: 767.98px) {}

    @media (max-width: 991.98px) {
        height: auto;
    }

    @media (max-width: 1199.98px) {}

    @media (max-width: 1399.98px) {}
`