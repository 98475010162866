import { createContext } from "react";
import { CompanyProps } from "../models/CompanyProps";

interface AdminPageContextData {
    isEdition: boolean,
    setIsEdition: (value: boolean) => void
    isInitialRegister: boolean,
    setIsInitialRegister: (value: boolean) => void
    currentCompany: CompanyProps,
    setCurrentCompany: (data: CompanyProps) => void
}

export const AdminPageContext = createContext<AdminPageContextData>({} as AdminPageContextData)