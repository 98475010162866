import { useContext, useEffect, useState } from "react";
import { Button } from "../../../designSystems/components/Button";
import { AdProps } from "../../../models/AdProps";
import { Container } from "./style";
import { Modal } from "../../../designSystems/components/Modal";
import { UploadImg } from "../../../designSystems/components/UploadImg";
import { Input, TextArea } from "semantic-ui-react";
import addIcon from "../../../assets/img/icons/add-white.svg"
import emptyImg from "../../../assets/img/empty-img.svg"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import { addAdPortal, listAds } from "../../../api/admin";
import { GlobalContext } from "../../../context/GlobalContext";
import { Navigate, useNavigate } from "react-router";

const URL = 'https://api.perifasul.org/public/'

export const AdsContent = (): JSX.Element => {
    const [nameModal, setNameModal] = useState<any>(undefined);
    const [description, setDescription] = useState<any>(undefined);
    const [logoFile, setLogoFile] = useState<any>(undefined);

    const [adssData, setAdsData] = useState<any[]>([])

    const { setIsLoading, userData, setDataToast, getErrorMessage, vertifyAuthenticatedRedirect } = useContext(GlobalContext)
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        listAds().then((response: any) => {
            setIsLoading(false)

            if (response.data.data.length) {
                setAdsData(response.data.data)
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const clearModalData = (): void => {
        setNameModal(undefined)
        setDescription(undefined)
        setLogoFile(undefined)
    }

    const convertDate = (ad: any): string => {
        return new Date(ad.created_at).toLocaleString('pt-br').slice(0, -3).replace(' ', ' - ')
    }

    const onSubmitCreateAdd = (): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.new-ad-modal .btn-first') as HTMLElement

        const dataForm: any = {
            title: nameModal,
            description: description,
            image: logoFile
        }

        setIsLoading(true)

        addAdPortal(dataForm).then((data: any) => {
            setIsLoading(false)
            btnCloseElem.click()
            setDataToast({ show: true, text: 'Anúncio criado com sucesso!!!' })
            fetchData()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    return (
        <Container>
            <div className="ads">
                <div className="title">
                    <div className="text">Anúncios</div>
                    <hr />
                    {[1, 2, 200].includes(userData.permissaos_id as number) &&
                        <Button text="Novo anúncio" className="btn-gray radius-100" icon={addIcon} isBtnModal targetModalName="newAdModal" onClick={() => clearModalData()} />
                    }
                </div>

                <div className="cards">
                    {adssData.length ? adssData.map((ad: any, index: number) =>
                        <div className="card" key={index}>
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="info">
                                        <img src={ad.image ? `${URL + ad.image}` : emptyImg} alt="" />
                                        <div>
                                            <div className="name">{ad.title}</div>
                                            <div className="description">{ad.description}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 flex-cc">
                                    <div className="status">
                                        <div className="status">Enviado</div>
                                        <div className="date">{convertDate(ad)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) :
                        <div><h4>Você não possui anúncios</h4></div>
                    }
                </div>
            </div>

            <Modal
                title="Novo anúncio"
                type="create"
                className="new-ad-modal"
                targetModalName="newAdModal"
                btnSecondText="Enviar"
                confirmAction={() => onSubmitCreateAdd()}
            >
                <div className="modal-description">
                    Complete os campos abaixo para adicionar um novo anúncio. Um e-mail será enviado às iniciativas que desejam receber anúncios.
                </div>

                <div className="add-image">
                    <UploadImg
                        label="Adicione uma imagem ao anúncio:"
                        logoUrl={logoFile}
                        changeLogoFile={setLogoFile}
                    />
                </div>

                <div className="fields">
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Escreva o título do anúncio'
                            value={nameModal}
                            onChange={function (e, { value }) {
                                setNameModal(value)
                            }}
                        >
                            <label className="label-inside">Nome</label>
                            <input />
                            <div
                                className="img-icon flex-cc"
                                onClick={() => setNameModal('')}
                            >
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>
                    </div>
                    <div className="field">
                        <label className="label-outside">Descrição</label>
                        <div>
                            <TextArea
                                placeholder='Escreva a descrição do anúncio'
                                value={description}
                                onChange={function (e, { value }) {
                                    setDescription(value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </Container>
    )
}