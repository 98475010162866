import React, { useContext, useEffect, useState } from "react"
import { Button } from "../../../designSystems/components/Button";
import { Container } from "./style";
import download from "../../../assets/img/icons/download.svg"
import trash from "../../../assets/img/icons/trash.svg"
import addIcon from "../../../assets/img/icons/add-white.svg"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import trashRedIcon from "../../../assets/img/icons/trash-red.svg"
import { Form, Input, Radio } from "semantic-ui-react";
import { AdminPageConfigProps } from "../../../models/AdminPageConfigProps";
import { OtherAccountProps } from "../../../models/OtherAccountProps";
import { Modal } from "../../../designSystems/components/Modal";
import { UploadImg } from "../../../designSystems/components/UploadImg";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { withMask } from 'use-mask-input';
import { RegisterPageContext } from "../../../context/RegisterPageContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { addUserConfigPortal, updateUseTerm, deleteUserConfigPortal, getAdminConfig, getUseTerm, passwordDataProps, updateAdminConfig, updatePassword } from "../../../api/admin";
import { useNavigate } from "react-router";
import _ from "lodash";
import axios from "axios";
import { UploadPdf } from "../../../designSystems/components/UploadPdf";

export const ConfigContent = (): JSX.Element => {
    const [otherAccounts, setOtherAccounts] = useState<OtherAccountProps[]>([])
    const [logoFile, setLogoFile] = useState<any>(undefined);
    const [useTermFile, setUseTermFile] = useState<any>(undefined);
    const [servicePolicyFile, setServicePolicyFile] = useState<any>(undefined);
    const [filesId, setFilesId] = useState<any>(undefined);

    const [currentPassword, setCurrentPassword] = useState<any>(undefined)
    const [currentPasswordElem, setCurrentPasswordElem] = useState<any>(undefined)

    const [showModalError, setShowModalError] = useState(false)

    const [newPassword, setNewPassword] = useState<any>(undefined)
    const [newPasswordElem, setNewPasswordElem] = useState<any>(undefined)

    const [repeatPassword, setRepeatPassword] = useState<any>(undefined)
    const [repeatPasswordElem, setRepeatPasswordElem] = useState<any>(undefined)

    const [deleteUserId, setDeleteUserId] = useState<number>(-1)
    const [errorNameModal, setErrorNameModal] = useState(false)

    const [newAccountModal, setNewAccountModal] = useState<OtherAccountProps>({} as OtherAccountProps);
    const [accountModalNameElem, setAccountModalNameElem] = useState<any>(undefined)
    const [accountModalEmailElem, setAccountModalEmailElem] = useState<any>(undefined)
    const [accountModalCpfElem, setAccountModalCpfElem] = useState<any>(undefined)

    const { setIsLoading, setDataToast, getErrorMessage, vertifyAuthenticatedRedirect, userData, setUserData } = useContext(GlobalContext)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm<AdminPageConfigProps>(
        {
            defaultValues: {
                name: userData?.name,
                receiveAdvertisement: userData.receiveAdvertisement,
                phone: userData.phone,
            }
        }
    );

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
    }, [otherAccounts])

    const fetchData = (): void => {
        setIsLoading(true)
        setOtherAccounts([])

        axios.all([
            getAdminConfig(),
            getUseTerm()
        ]).then((response: any) => {
            const result = response[0].data?.data
            setIsLoading(false)

            if (result?.accounts && result?.accounts.length) {
                if (userData.permissaos_id === 200) {
                    setOtherAccounts(result.accounts)
                } else {
                    setOtherAccounts(result.accounts.filter((item: OtherAccountProps) => item.permissaos_id !== 200))
                }
            }

            if (result?.user && result?.user.length) {
                setLogoFile(result?.user[0].logo)
            }

            setFilesId(response[1].data.data?.id)
            setUseTermFile(response[1].data.data?.useTerm)
            setServicePolicyFile(response[1].data.data?.privacyPolicy)

            // update localstorage and navbar data
            const userAlias = userData.is_admin ? 'authentication-admin' : 'usuario'
            const storageData = JSON.parse(localStorage.getItem(userAlias) || '{}')

            if (result.user[0].logo !== storageData.user.logo || result.user[0].name !== storageData.user.name) {
                storageData.user = {
                    ...storageData.user,
                    logo: result.user[0].logo,
                    name: result.user[0].name
                }

                localStorage.setItem(userAlias, JSON.stringify(storageData))

                setUserData({
                    ...userData,
                    logo: result.user[0].logo,
                    name: result.user[0].name
                })
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const onSubmit: SubmitHandler<AdminPageConfigProps> = data => {
        const dataForm = {
            user: { ...data, logo: logoFile || '' },
            account: otherAccounts.map(({ id, name, permissaos_id }) => ({ id, name, permissaos_id }))
        }

        setIsLoading(true)

        updateAdminConfig(dataForm).then((data: any) => {
            const userAlias = userData.is_admin ? 'authentication-admin' : 'usuario'
            const storageData = JSON.parse(localStorage.getItem(userAlias) || '{}')

            storageData.user = {
                ...storageData.user,
                name: dataForm.user.name,
                receiveAdvertisement: dataForm.user.receiveAdvertisement,
                phone: dataForm.user.phone
            }

            localStorage.setItem(userAlias, JSON.stringify(storageData))

            setUserData({
                ...userData,
                name: dataForm.user.name,
                receiveAdvertisement: dataForm.user.receiveAdvertisement,
                phone: dataForm.user.phone
            })

            setIsLoading(false)
            setDataToast({ show: true, text: 'Configurações salvas com sucesso!!!' })
            fetchData()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar!!!' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const savePdfFiles = (): void => {
        const dataForm = {
            id: filesId,
            useTerm: useTermFile,
            privacyPolicy: servicePolicyFile
        }

        setIsLoading(true)

        updateUseTerm(dataForm).then((response: any) => {
            setIsLoading(false)
        }).catch((err: any) => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar pdf' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err?.response?.data?.message)) {
                goToPage('/')
            }
        })
    }

    // CHANGE PASSWORD MODAL

    const onSubmitChangePassword = (): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.change-password-modal .btn-first') as HTMLElement

        const dataForm: passwordDataProps = {
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: repeatPassword
        }

        updatePassword(dataForm).then((data: any) => {
            setIsLoading(false)
            btnCloseElem.click()
            setDataToast({ show: true, text: 'Sua nova senha foi salva com sucesso!!!' })
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const clearChangePasswordForm = (): void => {
        setCurrentPassword(undefined)
        setNewPassword(undefined)
        setRepeatPassword(undefined)

        currentPasswordElem.target.value = ''
        newPasswordElem.target.value = ''
        repeatPasswordElem.target.value = ''
    }

    // NEW ACCOUNT MODAL

    const openNewAccountModal = (): void => {
        setShowModalError(false)

        setNewAccountModal({
            name: undefined,
            email: undefined,
            cpf: undefined,
            permissaos_id: 0
        })

        accountModalNameElem.target.value = ''
        accountModalCpfElem.target.value = ''
        accountModalEmailElem.target.value = ''
    }

    const createNewAccountModal = (): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.new-account-modal .btn-first') as HTMLElement

        const dataForm: any = {
            name: newAccountModal.name,
            email: newAccountModal.email,
            cpf: newAccountModal.cpf?.replaceAll('.', '').replace('-', ''),
            permissaos_id: newAccountModal?.permissaos_id,
        }

        if (!newAccountModal.name || !newAccountModal.email || (!newAccountModal.cpf || newAccountModal.cpf.includes('_')) || !newAccountModal.permissaos_id) {
            setShowModalError(true)
            return
        }

        setIsLoading(true)

        addUserConfigPortal(dataForm).then((data: any) => {
            setIsLoading(false)
            btnCloseElem.click()
            setDataToast({ show: true, text: 'Usuário adicionado com sucesso!!!' })
            fetchData()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao adicionar usuário' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const deleteAccountModal = (): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.delete-modal .btn-first') as HTMLElement

        setIsLoading(true)

        deleteUserConfigPortal({ user_id: deleteUserId }).then((data: any) => {
            setIsLoading(false)
            btnCloseElem.click()
            setDataToast({ show: true, text: 'Usuário deletado com sucesso!!!' })
            fetchData()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao deletar usuário' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="config-content">
                    <div className="title">
                        <div className="text">Configurações</div>
                        <hr />
                    </div>

                    <div className="config-section">
                        <div className="row">
                            <div className="col-md-4">
                                <UploadImg
                                    label="Adicione a logo da sua iniciativa:"
                                    logoUrl={logoFile}
                                    changeLogoFile={setLogoFile}
                                />
                            </div>

                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field">
                                            <Controller
                                                name="name"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Form.Input
                                                            fluid
                                                            size="huge"
                                                            placeholder='Digite aqui ...'
                                                            className={errors.name && 'field-error'}
                                                            value={value}
                                                            onChange={function (e, { value }) {
                                                                onChange(value)
                                                            }}
                                                        >
                                                            <label className="label-inside">Nome do usuário *</label>
                                                            <input />
                                                            <div
                                                                className="img-icon flex-cc"
                                                                onClick={() => {
                                                                    onChange(undefined)
                                                                    setValue('name', '')
                                                                }}>
                                                                <img src={closeInputIcon} alt="close-input" />
                                                            </div>
                                                        </Form.Input>
                                                        {errors.name && <p className="field-error-msg">Campo obrigatório</p>}
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="field">
                                            <Form.Input
                                                fluid
                                                disabled
                                                size="huge"
                                                type="email"
                                                placeholder='Escreva o e-mail institucional'
                                                value={userData.email}
                                            >
                                                <label className="label-inside">E-mail *</label>
                                                <input />
                                                <div className="img-icon flex-cc">
                                                    <img src={closeInputIcon} alt="close-input" />
                                                </div>
                                            </Form.Input>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="field">
                                            <Form.Input
                                                fluid
                                                disabled
                                                size="huge"
                                                placeholder='999.999.999-99'
                                                value={userData.cpf}
                                            >
                                                <label className="label-inside">CPF *</label>
                                                <input ref={withMask('999.999.999-99')} />
                                                <div className="img-icon flex-cc">
                                                    <img src={closeInputIcon} alt="close-input" />
                                                </div>
                                            </Form.Input>
                                        </div>
                                        <div className="field">
                                            <Controller
                                                name="phone"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Form.Input
                                                            fluid
                                                            size="huge"
                                                            placeholder='(99) 99999-9999'
                                                            className={errors.phone && 'field-error'}
                                                            value={value}
                                                            onChange={function (e, { value }) {
                                                                onChange(value)
                                                            }}
                                                        >
                                                            <label className="label-inside">Telefone *</label>
                                                            <input ref={withMask('(99) 99999-9999')} />
                                                            <div
                                                                className="img-icon flex-cc"
                                                                onClick={() => {
                                                                    onChange(undefined)
                                                                    setValue('phone', '')
                                                                }}
                                                            >
                                                                <img src={closeInputIcon} alt="close-input" />
                                                            </div>
                                                        </Form.Input>
                                                        {errors.phone && <p className="field-error-msg">Campo obrigatório</p>}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Button text="Trocar de senha" type="button" className="btn-outline-gray radius-100" isBtnModal targetModalName="changePasswordModal" onClick={() => clearChangePasswordForm()} />
                                </div>
                            </div>
                        </div>

                        {userData.permissaos_id === 3 &&
                            <div className="receive-ad">
                                <Controller
                                    name="receiveAdvertisement"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Form.Checkbox
                                                className="mb-0"
                                                label={<label>Desejo receber publicidade de nossos parceiros.</label>}
                                                checked={value ? true : false}
                                                onChange={function (e, { checked }) {
                                                    setValue('receiveAdvertisement', checked ? 1 : 0)
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        }
                    </div>
                </div>

                {[1, 200].includes(userData?.permissaos_id as number) &&
                    <div className="accounts-content">
                        <div className="title">
                            <div className="text">Outras contas</div>
                            <hr />
                            <Button
                                type="button"
                                text="Nova conta"
                                className="btn-gray radius-100"
                                isBtnModal
                                icon={addIcon}
                                targetModalName="newAccountModal"
                                onClick={() => openNewAccountModal()}
                            />
                        </div>

                        {otherAccounts.length ? otherAccounts.map((account: OtherAccountProps, index: number) => (
                            <div key={index}>
                                {index > 0 && <hr />}
                                <div className="account">
                                    <Button
                                        type="button"
                                        className="trash-icon"
                                        icon={trashRedIcon}
                                        background="transparent"
                                        isBtnModal
                                        targetModalName="deleteModal"
                                        onClick={() => setDeleteUserId(account.id || 0)}
                                    />

                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                icon
                                                size="huge"
                                                placeholder='Digite aqui ...'
                                                value={account.name}
                                                onChange={function (e, { value }) {
                                                    const otherAccountTemp = [...otherAccounts]
                                                    otherAccountTemp[index].name = value
                                                    setOtherAccounts(otherAccountTemp)
                                                }}
                                            >
                                                <label className="label-inside">Nome do usuário</label>
                                                <input />
                                                <div
                                                    className="img-icon flex-cc"
                                                    onClick={() => {
                                                        const otherAccountTemp = [...otherAccounts]
                                                        otherAccountTemp[index].name = ''
                                                        setOtherAccounts(otherAccountTemp)
                                                    }}
                                                >
                                                    <img src={closeInputIcon} alt="close-input" />
                                                </div>
                                            </Input>
                                        </div>
                                        <div className="col-md-4">
                                            <Input icon disabled size="huge" placeholder='999.999.999-99' value={account.cpf}>
                                                <label className="label-inside">CPF</label>
                                                <input ref={withMask('999.999.999-99')} />
                                                <div className="img-icon flex-cc">
                                                    <img src={closeInputIcon} alt="close-input" />
                                                </div>
                                            </Input>
                                        </div>
                                        <div className="col-md-4">
                                            <Input icon disabled size="huge" placeholder='example@example.com' value={account.email}>
                                                <label className="label-inside">E-mail</label>
                                                <input />
                                                <div className="img-icon flex-cc">
                                                    <img src={closeInputIcon} alt="close-input" />
                                                </div>
                                            </Input>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="field type">
                                                <label className="label-default">Tipo de Conta</label>
                                                <div style={{ display: 'flex' }}>
                                                    <Radio
                                                        label='Editor'
                                                        value={2}
                                                        checked={account.permissaos_id === 2}
                                                        onChange={(e, { value }) => {
                                                            const otherAccountTemp = [...otherAccounts]
                                                            otherAccountTemp[index].permissaos_id = value as number
                                                            setOtherAccounts(otherAccountTemp)
                                                        }}
                                                    />
                                                    <Radio
                                                        label='Visualizador'
                                                        value={4}
                                                        checked={account.permissaos_id === 4}
                                                        onChange={(e, { value }) => {
                                                            const otherAccountTemp = [...otherAccounts]
                                                            otherAccountTemp[index].permissaos_id = value as number
                                                            setOtherAccounts(otherAccountTemp)
                                                        }}
                                                    />
                                                    {userData.permissaos_id === 200 &&
                                                        <Radio
                                                            label='Administrador'
                                                            value={1}
                                                            checked={account.permissaos_id === 1}
                                                            onChange={(e, { value }) => {
                                                                const otherAccountTemp = [...otherAccounts]
                                                                otherAccountTemp[index].permissaos_id = value as number
                                                                setOtherAccounts(otherAccountTemp)
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div>Não existem outras contas.</div>}
                    </div>
                }

                {[1, 200].includes(userData.permissaos_id as number) &&
                    <div className="files-content">
                        <div className="title">
                            <div className="text" style={{ minWidth: 110 }}>Arquivos</div>
                            <hr />
                        </div>
                        <div className="files-section">
                            <div className="row">
                                <div className="col-md-4">
                                    <UploadPdf
                                        nameId="useTermPdf"
                                        label="Adicione o pdf de termos de uso:"
                                        logoUrl={useTermFile}
                                        changeLogoFile={setUseTermFile}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <UploadPdf
                                        nameId="servicePolicyPdf"
                                        label="Adicione o pdf de políticas de serviço:"
                                        logoUrl={servicePolicyFile}
                                        changeLogoFile={setServicePolicyFile}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <Button text="Salvar arquivos" type="button" className="files-btn radius-100 f-right" onClick={() => savePdfFiles()} />
                            </div>
                        </div>
                    </div>
                }

                <div className="footer">
                    <hr />
                    <Button text="Salvar" className="radius-100 f-right" />
                </div>
            </Form>

            <Modal
                title="Excluir conta"
                type="delete"
                className="delete-modal"
                targetModalName="deleteModal"
                btnSecondText="Excluir"
                confirmAction={() => deleteAccountModal()}
            >
                <div className="modal-description">
                    Deseja realmente exlcuir a conta deste usuário?
                </div>
            </Modal>

            <Modal
                title="Nova conta"
                type="create"
                className="new-account-modal"
                targetModalName="newAccountModal"
                btnSecondText="Cadastrar"
                confirmAction={() => createNewAccountModal()}
            >
                <div className="modal-description">
                    Complete os campos abaixo para cadastrar uma nova conta de usuário conectado à iniciativa. Após o cadastro, um e-mail será enviado ao usuário com a senha de acesso.
                </div>

                <div className="fields">
                    <div className={`field ${errorNameModal && 'simple-field-error'}`}>
                        <Input
                            icon
                            size="huge"
                            placeholder='Escreva o nome completo'
                            value={newAccountModal.name}
                            onChange={function (e, { value }) {
                                setNewAccountModal({ ...newAccountModal, name: value })
                                setAccountModalNameElem(e)
                            }}
                        >
                            <label className="label-inside">Nome do usuário *</label>
                            <input />
                            <div
                                className="img-icon flex-cc"
                                onClick={() => {
                                    setNewAccountModal({ ...newAccountModal, name: undefined })
                                    accountModalNameElem.target.value = ''
                                }}
                            >
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>

                        {(!newAccountModal.name && showModalError) && <div className="field-error-msg">Campo obrigatório</div>}
                    </div>
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='999.999.999-99'
                            value={newAccountModal.cpf}
                            onChange={function (e, { value }) {
                                setNewAccountModal({ ...newAccountModal, cpf: value })
                                setAccountModalCpfElem(e)
                            }}
                        >
                            <label className="label-inside">CPF *</label>
                            <input ref={withMask('999.999.999-99')} />
                            <div
                                className="img-icon flex-cc"
                                onClick={() => {
                                    setNewAccountModal({ ...newAccountModal, cpf: undefined })
                                    accountModalCpfElem.target.value = ''
                                }}
                            >
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>

                        {((!newAccountModal.cpf || newAccountModal.cpf.includes('_')) && showModalError) && <div className="field-error-msg">Campo obrigatório</div>}
                    </div>
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Escreva o e-mail do usuário'
                            value={newAccountModal.email}
                            onChange={function (e, { value }) {
                                setNewAccountModal({ ...newAccountModal, email: value })
                                setAccountModalEmailElem(e)
                            }}
                        >
                            <label className="label-inside">E-mail *</label>
                            <input />
                            <div
                                className="img-icon flex-cc"
                                onClick={() => {
                                    setNewAccountModal({ ...newAccountModal, email: undefined })
                                    accountModalEmailElem.target.value = ''
                                }}
                            >
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>

                        {(!newAccountModal.email && showModalError) && <div className="field-error-msg">Campo obrigatório</div>}
                    </div>
                    <div className="field">
                        <label className="label-default">Tipo de Conta *</label>
                        <div>
                            <Radio
                                label='Editor'
                                value={2}
                                checked={newAccountModal.permissaos_id === 2}
                                onChange={(e, { value }) => setNewAccountModal({ ...newAccountModal, permissaos_id: value as number })}
                            />
                            <Radio
                                label='Visualizador'
                                value={4}
                                checked={newAccountModal.permissaos_id === 4}
                                onChange={(e, { value }) => setNewAccountModal({ ...newAccountModal, permissaos_id: value as number })}
                            />
                            {userData.permissaos_id === 200 &&
                                <Radio
                                    label='Administrador'
                                    value={1}
                                    checked={newAccountModal.permissaos_id === 1}
                                    onChange={(e, { value }) => setNewAccountModal({ ...newAccountModal, permissaos_id: value as number })}
                                />
                            }
                        </div>

                        {(!newAccountModal.permissaos_id && showModalError) && <div className="field-error-msg">Campo obrigatório</div>}
                    </div>
                </div>
            </Modal>

            <Modal
                title="Trocar senha"
                type="create"
                className="change-password-modal"
                targetModalName="changePasswordModal"
                btnSecondText="Confirmar"
                confirmAction={() => onSubmitChangePassword()}
            >
                <div className="modal-description">
                    Para trocar a senha, digite a senha atual e a nova senha, logo depois, confirme a nova senha:
                </div>

                <div className="fields">
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Digite senha atual'
                            type="password"
                            value={currentPassword}
                            onChange={function (e, { value }) {
                                setCurrentPassword(value)
                                setCurrentPasswordElem(e)
                            }}
                        >
                            <label className="label-inside">Senha atual</label>
                            <input />
                            <div className="img-icon flex-cc" onClick={() => {
                                setCurrentPassword(undefined)
                                currentPasswordElem.target.value = ''
                            }}>
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>
                    </div>
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Digite nova senha'
                            type="password"
                            value={newPassword}
                            onChange={function (e, { value }) {
                                setNewPassword(value)
                                setNewPasswordElem(e)
                            }}
                        >
                            <label className="label-inside">Nova senha</label>
                            <input />
                            <div className="img-icon flex-cc" onClick={() => {
                                setNewPassword(undefined)
                                newPasswordElem.target.value = ''
                            }}>
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>
                    </div>
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Repita nova senha'
                            type="password"
                            value={repeatPassword}
                            onChange={function (e, { value }) {
                                setRepeatPassword(value)
                                setRepeatPasswordElem(e)
                            }}
                        >
                            <label className="label-inside">Confirmar nova senha</label>
                            <input />
                            <div className="img-icon flex-cc" onClick={() => {
                                setRepeatPassword(undefined)
                                repeatPasswordElem.target.value = ''
                            }}>
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>
                    </div>
                </div>
            </Modal>
        </Container>
    )
}