import { Variables } from '../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
position: relative;
min-height: calc(100vh - 56px);
padding-bottom: 100px;

.conclusion {
    margin-top: 27px;
    max-width: 450px;
    text-align: center;
    
    .msg {
        .title {
            color: #000000;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
            margin-bottom: 14px;
        }
        
        .description {
            color: #000000;
            margin-bottom: 14px;
        }
    }
}

.page-footer {
    width: 100%;
    position: absolute;
    bottom: 28px;

    .btn-actions {
        display: flex;
    }
}

@media (max-width: 575.99px) {}
`