import { useContext, useEffect, useState } from "react";
import { CompanyProps } from "../../../models/CompanyProps"
import { Button } from "../../../designSystems/components/Button";
import trashRed from '../../../assets/img/icons/trash-red.svg';
import edit from '../../../assets/img/icons/edit.svg';
import { Container } from "./style";
import { Modal } from "../../../designSystems/components/Modal";
import { AdminPageContext } from "../../../context/AdminPageContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { useNavigate } from "react-router";
import { deleteInitiative, updateInitiativeStatus } from "../../../api/admin";
import { getStepGranted } from "../../../api/utils";
import _ from "lodash";
import { CongratsProps } from "../../../models/CongratsProps";
import { cityActionsOptions, districtLocaleOptions, youAreOptions } from "../../../api/utils/fieldsOptions";
import { InitiativeSummaryCard } from "../InitiativeSummaryCard";
import { ModalFilter } from "../../../designSystems/components/ModalFilter";
import { ModalFilterContent } from "../../../designSystems/components/ModalFilterContent";
import { searchInitiatives } from "../../../api/map";

type Props = {
    companies?: CompanyProps[],
    fetchData: () => void
}

export const InitiativesContent = ({ companies, fetchData }: Props): JSX.Element => {
    const [dataPageResume, setDataPageResume] = useState<CongratsProps>({})
    const [companiesData, setCompaniesData] = useState<CompanyProps[]>([])
    const [companiesDataAux, setCompaniesDataAux] = useState<CompanyProps[]>([])
    const [currentCompany, setCurrentCompany] = useState<CompanyProps>({} as CompanyProps)

    const [offerTerritoryAvailable, setOfferTerritoryAvailable] = useState<number[]>([])
    const [providersAvailable, setProvidersAvailable] = useState<number[]>([])
    const [city, setCity] = useState<number[]>([])
    const [publicValues, setPublicValues] = useState<number[]>([])
    const [youAre, setYouAre] = useState<number[]>([])
    const [whatIdo, setWhatIdo] = useState<number[]>([])
    const [odsValues, setOdsValues] = useState<number[]>([])
    const [initiativeNature, setInitiativeNature] = useState<number[]>([])
    const [initiativeIs, setInitiativeIs] = useState<number[]>([])
    const [legalFormat, setLegalFormat] = useState<number[]>([])
    const [coverageArea, setCoverageArea] = useState<number[]>([])
    const [motivatingPublic, setMotivatingPublic] = useState<number[]>([])
    const [socialImpactDimension, setSocialImpactDimension] = useState<number[]>([])
    const [hasTitleQualification, setHasTitleQualification] = useState<number[]>([])
    const [percentWomen, setPercentWomen] = useState<number[]>([])
    const [marketSegment, setMarketSegment] = useState<number[]>([])
    const [whichArt, setWhichArt] = useState<number[]>([])
    const [documents, setDocuments] = useState<number[]>([])
    const [approvedProjectLaws, setApprovedProjectLaws] = useState<number[]>([])
    const [initiativeHas, setInitiativeHas] = useState<number[]>([])
    const [annualBudget, setAnnualBudget] = useState<number[]>([])
    const [monthlyBilling, setMonthlyBilling] = useState<number[]>([])
    const [areasExpertise, setAreasExpertise] = useState<number[]>([])
    const [initiativeName, setInitiativeName] = useState<any>({})
    const [district, setDistrict] = useState<number[]>([])
    const [hasCnpj, setHasCnpj] = useState<number | undefined>(undefined)
    const [productType, setProductType] = useState<number[]>([])
    const [serviceType, setServiceType] = useState<number[]>([])
    const [hasProvider, setHasProvider] = useState<number | undefined>(undefined)
    const [hasPoliticalParty, setHasPoliticalParty] = useState<number | undefined>(undefined)
    const [hasChurchAffiliation, setHasChurchAffiliation] = useState<number | undefined>(undefined)
    const [hasDrt, setHasDrt] = useState<number | undefined>(undefined)
    const [yearFoundation, setYearFoundation] = useState<string>('')
    const [hasMission, setHasMission] = useState<number | undefined>(undefined)
    const [hasVision, setHasVision] = useState<number | undefined>(undefined)
    const [passAudit, setPassAudit] = useState<number | undefined>(undefined)
    const [hasCounter, setHasCounter] = useState<number | undefined>(undefined)
    const [hasEquip, setHasEquip] = useState<number | undefined>(undefined)
    const [partnerForumNetwork, setPartnerForumNetwork] = useState<number | undefined>(undefined)
    const [isSouthPeripherySP, setIsSouthPeripherySP] = useState<number | undefined>(undefined)

    const { setIsEdition, setIsInitialRegister } = useContext(AdminPageContext)
    const { setIsLoading, setDataToast, changeLastStep, getErrorMessage, vertifyAuthenticatedRedirect, setUserId, userData, setUserData, setLastStep, isMobile } = useContext(GlobalContext)

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setCompaniesData(companies || [])
        setCompaniesDataAux(companies || [])
        setLastStep(1)
    }, [companies])

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const editIniciative = (company: CompanyProps): void => {
        let userId = company?.user_id || 0

        if (userData.is_admin) {
            setUserData({ ...userData, tipo_organizacao_id: company.tipo_organizacao_id })
        }

        setUserId(userId)
        setIsInitialRegister(false)
        setIsEdition(true)
        setIsLoading(true)


        getStepGranted({ user_id: userId }).then((response: any) => {
            setIsLoading(false)
            const result: any[] = response?.data?.data
            changeLastStep(result.length ? _.max(result.map(item => item.step)) : 0)
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }
    const continueIniciativeRegister = (): void => {
        let userId = userData.id
        setUserId(userId as number)
        setIsInitialRegister(true)
        localStorage.setItem('usuario-temp', '{}')
        setIsEdition(true)
    }

    const changeIniciativeStatus = (company: CompanyProps): void => {
        let userId = company?.user_id || 0

        setIsLoading(true)

        updateInitiativeStatus({ user_id: userId, status: company.status === '1' ? '0' : '1' })
            .then((response: any) => {
                setIsLoading(false)
                fetchData()
            }).catch(err => {
                setIsLoading(false)
                const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao alterar status da iniciativa' : getErrorMessage(err.response.data.message)
                setDataToast({ show: true, text: textError })
                if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                    goToPage('/')
                }
            })
    }

    const deleteIniciative = (company: CompanyProps): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.delete-initiative-modal .btn-first') as HTMLElement
        let userId = company?.user_id || 0

        setIsLoading(true)

        deleteInitiative({ user_id: userId }).then((response: any) => {
            setIsLoading(false)
            fetchData()
            setDataToast({ show: true, text: 'Iniciativa deletada com sucesso!!!' })
        }).catch((err: any) => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err?.response?.data?.message)) {
                goToPage('/')
            }
        }).finally(() => {
            btnCloseElem.click()
        })
    }

    const showInitiativeSummaryModal = (company: any): void => {
        const cityName = cityActionsOptions.filter(item => item.value === company.cityActions)[0]?.text || ''
        const districtName = districtLocaleOptions.filter(item => (JSON.parse(company.districtLocale)).includes(item.value)).map(d => d.text || '').join(', ')

        setDataPageResume({
            title: company.initiativeName,
            ods: company.ods ? JSON.parse(company.ods) : '',
            thematics: company.areasExpertise ? JSON.parse(company.areasExpertise) : '',
            description: company.initiativeDescription,
            offerTerritory: company.offerTerritoryAvailable ? JSON.parse(company.offerTerritoryAvailable) : '',
            offerTerritoryOthers: company.offerTerritoryOthers ? JSON.parse(company.offerTerritoryOthers) : '',
            providers: [1],
            localization: company.cityActions === 1 ? `${cityName} - ${districtName}` : `${cityName}`,
            networks: company.networks ? JSON.parse(company.networks) : '',
            email: company.email,
            facebook: company.facebook,
            instagram: company.instagram,
            linkedin: company.linkedin,
            tiktok: company.tiktok,
            website: company.website,
            youtube: company.youtube,
            logo: company.logo,
        })

        let btnOpenModalElem: HTMLElement = document.querySelector('.summary-card-btn') as HTMLElement
        btnOpenModalElem.click()
    }

    const clearFilter = (): any => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.all-filter-modal .btn-first') as HTMLElement

        const dataForm = {
            initiativeName: '',
            tipo_organizacao_id: [],
            ods: [],
            whatIdo: [],
            initiativeNature: [],
            initiativeIs: [],
            legalFormat: [],
            coverageArea: [],
            motivatingPublic: [],
            socialImpactDimension: [],
            hasTitleQualification: [],
            percentWomen: [],
            marketSegment: [],
            whichArt: [],
            documents: [],
            approvedProjectLaws: [],
            initiativeHas: [],
            annualBudget: [],
            monthlyBilling: [],
            areasExpertise: [],
            cityActions: [],
            districtLocale: [],
            offerTerritoryAvailable: [],
            providersAvailable: [],
            hasCnpj: undefined,
            productType: [],
            serviceType: [],
            hasProvider: undefined,
            hasPoliticalParty: undefined,
            hasChurchAffiliation: undefined,
            hasDrt: undefined,
            yearFoundation: '',
            hasMission: undefined,
            hasVision: undefined,
            passAudit: undefined,
            hasCounter: undefined,
            hasEquip: undefined,
            partnerForumNetwork: undefined,
            isSouthPeripherySP: undefined
        }

        setInitiativeName({})
        setYouAre([])
        setOdsValues([])
        setWhatIdo([])
        setInitiativeNature([])
        setInitiativeIs([])
        setLegalFormat([])
        setCoverageArea([])
        setMotivatingPublic([])
        setSocialImpactDimension([])
        setHasTitleQualification([])
        setPercentWomen([])
        setMarketSegment([])
        setWhichArt([])
        setDocuments([])
        setApprovedProjectLaws([])
        setInitiativeHas([])
        setAnnualBudget([])
        setMonthlyBilling([])
        setAreasExpertise([])
        setCity([])
        setPublicValues([])
        setOfferTerritoryAvailable([])
        setProvidersAvailable([])
        setDistrict([])
        setHasCnpj(undefined)
        setProductType([])
        setServiceType([])
        setHasProvider(undefined)
        setHasPoliticalParty(undefined)
        setHasChurchAffiliation(undefined)
        setHasDrt(undefined)
        setYearFoundation('')
        setHasMission(undefined)
        setHasVision(undefined)
        setPassAudit(undefined)
        setHasCounter(undefined)
        setHasEquip(undefined)
        setPartnerForumNetwork(undefined)
        setIsSouthPeripherySP(undefined)

        setIsLoading(true)
        searchInitiatives(dataForm).then((response: any) => {
            setIsLoading(false)

            const resultData: any[] = response?.data?.data

            if (resultData.length) {
                setCompaniesData(companiesDataAux.filter(c => resultData.map(r => r.iniciativa_id).includes(c.user_id)))
            } else {
                setCompaniesData([])
            }

            btnCloseElem.click()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
        })
    }

    const filtrar = (): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.all-filter-modal .btn-first') as HTMLElement
        const dataForm = {
            initiativeName: initiativeName.text,
            tipo_organizacao_id: youAre,
            ods: odsValues,
            whatIdo: whatIdo,
            initiativeNature: initiativeNature,
            initiativeIs: initiativeIs,
            legalFormat: legalFormat,
            coverageArea: coverageArea,
            socialImpactDimension: socialImpactDimension,
            hasTitleQualification: hasTitleQualification,
            percentWomen: percentWomen,
            marketSegment: marketSegment,
            whichArt: whichArt,
            documents: documents,
            approvedProjectLaws: approvedProjectLaws,
            initiativeHas: initiativeHas,
            annualBudget: annualBudget,
            monthlyBilling: monthlyBilling,
            hasCounter: hasCounter,
            areasExpertise: areasExpertise,
            cityActions: city,
            districtLocale: district,
            motivatingPublic: publicValues,
            offerTerritoryAvailable: offerTerritoryAvailable,
            providersAvailable: providersAvailable,
            hasCnpj,
            productType: productType,
            serviceType: serviceType,
            hasProvider,
            hasPoliticalParty,
            hasChurchAffiliation,
            hasMission,
            hasDrt,
            yearFoundation,
            passAudit,
            hasVision,
            hasEquip,
            partnerForumNetwork,
            isSouthPeripherySP
        }

        setIsLoading(true)
        searchInitiatives(dataForm).then((response: any) => {
            setIsLoading(false)

            const resultData: any[] = response?.data?.data

            if (resultData.length) {
                setCompaniesData(companiesDataAux.filter(c => resultData.map(r => r.iniciativa_id).includes(c.user_id)))
            } else {
                setCompaniesData([])
            }

            btnCloseElem.click()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
        })
    }

    return (
        <Container>
            <div style={{ display: 'none' }}>
                <Button className="summary-card-btn" text="summary-modal" isBtnModal targetModalName="initiativeSummaryModal" />
            </div>

            <div className="btn-filter">
                <Button className="btn-all" text="Todos os filtros" isBtnModal targetModalName="allFilters" />
            </div>

            <div className="companies">
                <div className="title">
                    <div className="text">Iniciativas</div>
                    <hr />
                </div>

                <div className="cards">
                    {companiesData.length ? companiesData.map((company: CompanyProps, index: number) =>
                        <div className="card" key={index} data-bs-toggle="show-initiative-summary-modal" onClick={() => { showInitiativeSummaryModal(company) }}>
                            <div className="row">
                                <div className={`col-md-${[1, 200].includes(userData.permissaos_id as number) ? '6' : '8'}`}>
                                    <div className="info">
                                        <div className="name">{company.initiativeName}</div>
                                        <div className="address">{_.first(youAreOptions.filter(y => y.value === company.tipo_organizacao_id))?.text}</div>
                                    </div>
                                </div>

                                {[1, 200].includes(userData.permissaos_id as number) &&
                                    <div className={`col-md-2 ${isMobile ? 'flex-cc' : 'flex-ce'}`}>
                                        <div
                                            className="type-status"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                changeIniciativeStatus(company)
                                            }}
                                        >
                                            <div className={`bg-type ${company?.status === '0' ? 'status inactive' : 'status active'}`}></div>
                                            <div className="type">
                                                Inativo
                                            </div>
                                            <div className="type">
                                                Ativo
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className={`col-md-${[1, 200].includes(userData.permissaos_id as number) ? '2' : '4'} col ${isMobile ? 'flex-cc' : 'flex-ce'}`}>
                                    <div className="btn-actions">
                                        <Button
                                            className="btn-outline-gray radius-100"
                                            text="Editar"
                                            icon={edit}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                editIniciative(company)
                                            }}
                                        />
                                    </div>
                                </div>
                                {[1, 200].includes(userData.permissaos_id as number) &&
                                    <div className={`col-md-2 col ${isMobile ? 'flex-cc' : 'flex-ce'}`}>
                                        <div className="btn-actions">
                                            <Button
                                                className="btn-outline-red radius-100"
                                                text="Excluir"
                                                isBtnModal
                                                targetModalName="deleteModal"
                                                icon={trashRed}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setCurrentCompany(company)
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    ) :
                        <>
                            {(userData.permissaos_id === 3 && !userData.cpf) &&
                                <div style={{ display: 'inline-block' }}>
                                    <h4>A sua iniciativas não está ativa, inicie o seu cadastro!</h4>
                                    <div className="d-flex">
                                        <Button text="Iniciar cadastro" type="button" className="files-btn radius-100 f-right" onClick={() => continueIniciativeRegister()} />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>

            <Modal
                escClick
                outsideClick
                showTopBtnclone
                title="Excluir iniciativa"
                type="create"
                className="show-initiative-summary-modal"
                btnFirstText="Fechar"
                targetModalName="initiativeSummaryModal"
            >
                <div className="modal-description">
                    <InitiativeSummaryCard dataPage={dataPageResume} />
                </div>
            </Modal>

            <Modal
                title="Excluir iniciativa"
                type="delete"
                className="delete-initiative-modal"
                targetModalName="deleteModal"
                btnSecondText="Excluir"
                confirmAction={() => deleteIniciative(currentCompany)}
            >
                <div className="modal-description">
                    Deseja realmente exlcuir o(a) <b>{currentCompany.initiativeName}</b> do Mapa PerifaSul?
                </div>
            </Modal>

            <ModalFilter
                title="Todos os Filtros"
                targetModalName="allFilters"
                className="all-filter-modal"
                btnSecondText="Enviar"
                confirmAction={() => filtrar()}
                clearAction={() => clearFilter()}
            >
                <div className="modal-description modal-filters">
                    <ModalFilterContent
                        youAre={youAre}
                        setYouAre={setYouAre}
                        whatIdo={whatIdo}
                        setWhatIdo={setWhatIdo}
                        initiativeNature={initiativeNature}
                        setInitiativeNature={setInitiativeNature}
                        initiativeIs={initiativeIs}
                        setInitiativeIs={setInitiativeIs}
                        legalFormat={legalFormat}
                        setLegalFormat={setLegalFormat}
                        coverageArea={coverageArea}
                        setCoverageArea={setCoverageArea}
                        motivatingPublic={motivatingPublic}
                        setMotivatingPublic={setMotivatingPublic}
                        socialImpactDimension={socialImpactDimension}
                        setSocialImpactDimension={setSocialImpactDimension}
                        hasTitleQualification={hasTitleQualification}
                        setHasTitleQualification={setHasTitleQualification}
                        percentWomen={percentWomen}
                        setPercentWomen={setPercentWomen}
                        marketSegment={marketSegment}
                        setMarketSegment={setMarketSegment}
                        whichArt={whichArt}
                        setWhichArt={setWhichArt}
                        documents={documents}
                        setDocuments={setDocuments}
                        approvedProjectLaws={approvedProjectLaws}
                        setApprovedProjectLaws={setApprovedProjectLaws}
                        initiativeHas={initiativeHas}
                        setInitiativeHas={setInitiativeHas}
                        hasMission={hasMission}
                        setHasMission={setHasMission}
                        hasVision={hasVision}
                        setHasVision={setHasVision}
                        passAudit={passAudit}
                        setPassAudit={setPassAudit}
                        annualBudget={annualBudget}
                        setAnnualBudget={setAnnualBudget}
                        monthlyBilling={monthlyBilling}
                        setMonthlyBilling={setMonthlyBilling}
                        odsValues={odsValues}
                        setOdsValues={setOdsValues}
                        areasExpertise={areasExpertise}
                        setAreasExpertise={setAreasExpertise}
                        city={city}
                        setCity={setCity}
                        district={district}
                        setDistrict={setDistrict}
                        hasCnpj={hasCnpj}
                        setHasCnpj={setHasCnpj}
                        publicValues={publicValues}
                        setPublicValues={setPublicValues}
                        productType={productType}
                        setProductType={setProductType}
                        serviceType={serviceType}
                        setServiceType={setServiceType}
                        isSouthPeripherySP={isSouthPeripherySP}
                        setIsSouthPeripherySP={setIsSouthPeripherySP}
                        hasPoliticalParty={hasPoliticalParty}
                        setHasPoliticalParty={setHasPoliticalParty}
                        hasChurchAffiliation={hasChurchAffiliation}
                        setHasChurchAffiliation={setHasChurchAffiliation}
                        hasDrt={hasDrt}
                        setHasDrt={setHasDrt}
                        yearFoundation={yearFoundation}
                        setYearFoundation={setYearFoundation}
                        hasCounter={hasCounter}
                        setHasCounter={setHasCounter}
                        hasEquip={hasEquip}
                        setHasEquip={setHasEquip}
                        partnerForumNetwork={partnerForumNetwork}
                        setPartnerForumNetwork={setPartnerForumNetwork}
                        offerTerritoryAvailable={offerTerritoryAvailable}
                        setOfferTerritoryAvailable={setOfferTerritoryAvailable}
                        providersAvailable={providersAvailable}
                        setProvidersAvailable={setProvidersAvailable}
                    />
                </div>
            </ModalFilter>
        </Container>
    )
}