import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;

    .all-filter-modal {
        overflow: unset;

        .modal-dialog {
            margin-top: 50px;

            .modal-content {
                .modal-body {
                    max-height: calc(100vh - 220px);
                    overflow-y: auto;
                }

                .modal-footer {
                    box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.12);
                }
            }
        }
    }

    .btn-more {
        display: none;
    }

    .show-initiative-summary-modal {
        .modal-content {
            margin-top: 70px;

            width: unset;
            padding: 0px !important;

            .modal-header {
                display: flex !important;
                justify-content: right;
    
                .icon,
                .modal-title {
                    display: none;
                }
    
                .btn-close {
                    margin: 5px 10px 0px 0px;
                }
            }

            .modal-body {
                .modal-description {
                    margin-bottom: 0px !important;
                }

                .initiative-resume {
                    border: unset;
                }
            }

            .modal-footer {
                display: none;
            }
        }
    }

    .leaflet-container {
        width: 100vw;
        height: calc(100vh - 56px);
    }

    .leaflet-popup-content{
        margin: 15px;

        .map-content {
            .title {
                color: #000;
                font-size: 16px;
                font-weight: 900;
                margin: 8px;
            }
            
            .description {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                margin: 8px;
            }

            button {
                padding: 14px 16px;
                height: 34px;
            }
        }
    }

    .filter {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 16px 24px 16px 68px;
        z-index: 1111;

        .filter-search {
            .search {
                width: 273px;
            }
        }

        .ui {
            &.menu {
                .ui {
                    &.dropdown {
                        .menu {
                            .item {
                                &.active {
                                    background: #F4C600 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ui {
        &.menu {
            border: unset;
            margin: 0px;
            margin-left: 48px;
            min-height: 32px;
            background: transparent;
            width: 933px;
            justify-content: space-between;

            .link {
                &.item {
                    padding: 0px;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                    border-radius: 8px;

                    .btn-all {
                        background: #516274;
                        color: #fff;
                        height: 32px;
                    }
                }
            }

            .ui {
                &.dropdown {
                    &.item {
                        font-weight: 600;
                        padding: 6px 16px;
                        background: #516274;
                        height: 32px;

                        .text,
                        .icon {
                            color: #fff;
                        }

                        .menu {
                            background: linear-gradient(0deg, rgba(81, 98, 116, 0.08), rgba(81, 98, 116, 0.08)), #FFFFFF;
                            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
                            border-radius: 4px;
                            
                            :not(.city) {
                                max-height: calc(100vh - 150px);
                                overflow: auto;
                            }

                            .header {
                                color: #1C1B1F;
                                padding: 8px 12px;
                            }

                            &.thematic {
                                width: 465px;

                                .item {
                                    .content {
                                        display: flex;
                                        white-space: break-spaces;

                                        .text {
                                            margin-left: 20px;

                                        }
                                    }
                                }
                            }

                            &::after {
                                display: none;
                            }

                            .item {
                                padding: 8px 12px !important;
                                
                                &.first {
                                    padding: 0px !important;
                                    
                                    .ui.dropdown {
                                        width: 100%;
                                        padding: 8px 12px !important;
                                    }
                                }
                                
                                .text {
                                    color: #1C1B1F;
                                }

                                .checkbox {
                                    label {
                                        padding-left: 34px;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 16px;
                                        color: #1C1B1F;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 575.98px) {
        .ui {
            &.menu {
                margin-left: 0px;
            }
        }

        .all-filter-modal {
            overflow: unset;
    
            .modal-dialog {
                margin-top: 33px;
                max-width: fit-content;
                
                .modal-content {
                    .modal-body {
                        max-height: calc(100vh - 200px);
                    }
                }
            }
        }
    }

    @media (min-width: 767.99px) and (max-width: 991.98px) {
        .filter {
            display: block;
            
            >div {
                margin-bottom: 8px;
            }

            .ui {
                &.menu {
                    display: table-row;

                    >div {
                        margin-bottom: 8px;
                        width: max-content;
                    }
                }
            }
        }
    }
`