import { useContext, useEffect, useState } from "react"
import { Checkbox, Dropdown, Form, Input } from "semantic-ui-react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { NetworksPartnersProps } from "../../../models/NetworksPartnersProps"
import { Modal } from "../../../designSystems/components/Modal"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import { NewInitiativeProps } from "../../../models/NewInitiativeProps"
import { GlobalContext } from "../../../context/GlobalContext"
import { useNavigate } from "react-router"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { getNetworksPartners, createNetworksPartners, updateNetworksPartners, sendInvitation, fetchInvitations, fetchInitiatives } from "../../../api/networks-partners"
import axios from "axios"
import { InitiativePartnerOtherProps } from "../../../models/InitiativePartnerOtherProps"

const defaultOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Sim', value: 1 },
    { key: 2, text: 'Não', value: 2 },
]

export const NetworksPartnersContent = (): JSX.Element => {
    const [isSubmitAction, setIsSubmitAction] = useState(false)
    const [initiatives, setInitiatives] = useState<number[]>([])
    const [newInitiativeNameModal, setNewInitiativeNameModal] = useState<any>(undefined);
    const [newInitiativeEmailModal, setNewInitiativeEmailModal] = useState<any>(undefined);
    const [updateAction, setUpdateAction] = useState(false)
    const [initiativesOptions, setInitiativesOptions] = useState<any[]>([])
    const [initiativesPartnersOthers, setInitiativesPartnersOthers] = useState<InitiativePartnerOtherProps[]>([])

    const { setCurrentPage, handleScroll } = useContext(RegisterPageContext)
    const { setIsLoading, setDataToast, changeLastStep, getErrorMessage, vertifyAuthenticatedRedirect, userId, userData, pathnameIsAdmin, showNextRegisterButton } = useContext(GlobalContext)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm<NetworksPartnersProps>(
        {
            defaultValues: {
                id: undefined,
                partnerForumNetwork: undefined,
                initiatives: [],
                initiativesOthers: [],
            }
        }
    );

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        axios.all([
            getNetworksPartners(pathnameIsAdmin() ? { user_id: userId } : null),
            fetchInitiatives()
        ]).then((response: any) => {
            setIsLoading(false)

            if (response[1].data.data.initiative.length) {
                const options = response[1].data.data.initiative.map((item: any) => ({ 'key': item.id, 'text': item.initiativeName, 'value': item.id }))
                setInitiativesOptions(options)
            }

            if (response[1].data.data.others.length) {
                setInitiativesPartnersOthers(response[1].data.data.others.filter((item: any) => item !== null))
            }

            if (response[0].data.data.length) {
                setUpdateAction(true)
                const responseData: NetworksPartnersProps = response[0].data.data[0]
                fillForm(responseData, response[1].data.data.initiative)
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const fillForm = (data: NetworksPartnersProps, options: any[]): void => {
        setValue('id', data.id)
        let initiativesOptionsValues: number[] = []

        if (options.length) {
            initiativesOptionsValues = options.map((item: any) => ({ 'key': item.id, 'text': item.initiativeName, 'value': item.id })).map(item => item.value)
        }

        const initiatives = (data.initiatives as number[]).filter(d => initiativesOptionsValues.includes(d))

        setValue('partnerForumNetwork', data.partnerForumNetwork)
        setValue('initiatives', initiatives)
        setInitiatives(initiatives)
    }

    const onChangeInitiatives = (checked: boolean | undefined, key: number): void => {
        let initiativesList: number[] = [...initiatives];

        if (checked) {
            initiativesList.push(key)
        } else {
            initiativesList = initiativesList.filter(k => k !== key)
        }

        setInitiatives(initiativesList)
    }

    const isInitiativeChecked = (key: number, dataList: number[]): boolean => {
        return dataList.length ? dataList?.includes(key) : false
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const clearInvitePartner = (): void => {
        setNewInitiativeNameModal('')
        setNewInitiativeEmailModal('')
    }

    const invitePartner = (): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.new-account-modal .btn-first') as HTMLElement

        const dataForm = {
            name: newInitiativeNameModal,
            email: newInitiativeEmailModal,
        }

        setIsLoading(true)

        sendInvitation(dataForm).then((response: any) => {
            setIsLoading(false)
            setDataToast({ show: true, text: 'Convite enviado com sucesso!!!' })
            fetchData()
        }).catch((err: any) => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao enviar convite!' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err?.response?.data?.message)) {
                goToPage('/')
            }
        }).finally(() => {
            btnCloseElem.click()
        })
    }

    const isInitiativePartnerOthersChecked = (data: InitiativePartnerOtherProps, dataList: InitiativePartnerOtherProps[]): boolean => {
        return dataList.length > 0 && dataList.filter(item => item.id === data.id).length > 0
    }

    const onChangeInitiativePartnerOthers = (checked: boolean | undefined, info: InitiativePartnerOtherProps): void => {
        let initiativesPartnersOthersList: InitiativePartnerOtherProps[] = [...initiativesPartnersOthers];

        if (!checked) {
            initiativesPartnersOthersList = initiativesPartnersOthersList.filter(o => o.id !== info.id)
        }

        setInitiativesPartnersOthers(initiativesPartnersOthersList)
        setValue('initiativesOthers', initiativesPartnersOthersList)
    }

    const create = (dataForm: NetworksPartnersProps): void => {
        setIsLoading(true)
        createNetworksPartners(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('solidarity-bank')
            changeLastStep(5)
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        }).finally(() => setIsSubmitAction(false))
    }

    const update = (dataForm: NetworksPartnersProps): void => {
        setIsLoading(true)
        updateNetworksPartners(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('solidarity-bank')
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        }).finally(() => setIsSubmitAction(false))
    }

    const onSubmit: SubmitHandler<NetworksPartnersProps> = data => {
        const dataForm: NetworksPartnersProps = { ...data, partnerForumNetwork: data.partnerForumNetwork || 0, initiativesOthers: [...initiativesPartnersOthers] }

        if (isSubmitAction) {
            updateAction ? update(dataForm) : create(dataForm)
        }
    }

    return (
        <Container
            countInitiatives={initiatives.length}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6 field-col-align">
                                <Controller
                                    name="partnerForumNetwork"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Form.Select
                                                fluid
                                                size="huge"
                                                label='Sua iniciativa atua em parceria ou faz parte de algum fórum ou rede?'
                                                placeholder='Selecione ...'
                                                value={value}
                                                options={defaultOptions}
                                                onChange={function (e, { value }) {
                                                    onChange(value)
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <div className="col-md-6 field-col-align">
                                <label className="label-form-default">Selecione as iniciativas:</label>
                                <div className="field">
                                    <Dropdown
                                        placeholder='Selecione ...'
                                        fluid
                                        multiple
                                        search
                                        selection
                                        clearable
                                        options={initiativesOptions}
                                        value={initiatives}
                                        onChange={function (e, { value }) {
                                            setInitiatives(value as number[])
                                            setValue('initiatives', value as number[])
                                        }}
                                    >
                                        <Dropdown.Menu>
                                            {initiativesOptions.map(op => (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        let resValues = []

                                                        if (initiatives.includes(op.key)) {
                                                            resValues = initiatives.filter((item: number) => item !== op.key)
                                                        } else {
                                                            resValues = [...initiatives, op.key]
                                                        }

                                                        setInitiatives(resValues)
                                                        setValue('initiatives', resValues as number[])
                                                    }}
                                                >
                                                    <Checkbox
                                                        label={op.text}
                                                        checked={initiatives.includes(op.key)}
                                                    />
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    {userData.permissaos_id === 3 &&
                        <div className="col-md-4 pos-relative">
                            <div className="new-initiatives">
                                <div className="text">
                                    Selecione as iniciativas já cadastradas ao lado ou convide novas iniciativas ainda não cadastradas:
                            </div>

                                {initiatives.length > 0 &&
                                    <div className="initiatives-list">
                                        {initiativesOptions.map((o, index) => {
                                            return isInitiativeChecked(o.key, initiatives) && (
                                                <div className="col-md-12" key={o.key}>
                                                    <Checkbox
                                                        label={o.text}
                                                        checked={isInitiativeChecked(o.key, initiatives)}
                                                        onChange={(e, { checked }) => {
                                                            onChangeInitiatives(checked, o.key)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                {initiativesPartnersOthers.length > 0 &&
                                    <div className="initiatives-others">
                                        <hr />
                                        {initiativesPartnersOthers.map((info, index) => (
                                            <div className="col-md-12" key={index}>
                                                <Checkbox
                                                    label={info?.name}
                                                    checked={isInitiativePartnerOthersChecked(info, initiativesPartnersOthers)}
                                                    onChange={(e, { checked }) => {
                                                        onChangeInitiativePartnerOthers(checked, info)
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                }
                                <div>
                                    <Button
                                        text="Convidar parceiro"
                                        className="radius-100 btn-outline-gray"
                                        isBtnModal
                                        type="button"
                                        targetModalName="newInitiativeModalTarget"
                                        onClick={() => clearInvitePartner()}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="footer">
                    <hr />
                    <div className="btn-actions f-right">
                        <Button
                            text="Anterior"
                            className="radius-100 btn-outline-gray mr-32"
                            onClick={() => setCurrentPage([1, 4].includes(userData.tipo_organizacao_id as number) ? 'financial' : 'about-initiative')}
                        />
                        {showNextRegisterButton(updateAction) &&
                            <Button
                                type="submit"
                                text="Próxima"
                                className="radius-100"
                                onClick={() => setIsSubmitAction(true)}
                            />
                        }
                    </div>
                </div>
            </Form>

            <Modal
                title="Convidar parceiro"
                type="create"
                className="new-account-modal"
                targetModalName="newInitiativeModalTarget"
                btnSecondText="Aplicar"
                confirmAction={() => invitePartner()}
            >
                <div className="modal-description">
                    Complete os campos abaixo para convidar seu parceiro para fazer parte do Mapa PerifaSul:
                </div>

                <div className="fields">
                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Escreva o nome completo'
                            value={newInitiativeNameModal}
                            onChange={function (e, { value }) {
                                setNewInitiativeNameModal(value)
                            }}
                        >
                            <label className="label-inside">Nome da iniciativa</label>
                            <input />
                            <div
                                className="img-icon flex-cc"
                                onClick={() => setNewInitiativeNameModal('')}
                            >
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>
                    </div>

                    <div className="field">
                        <Input
                            icon
                            size="huge"
                            placeholder='Escreva o e-mail institucional'
                            value={newInitiativeEmailModal}
                            onChange={function (e, { value }) {
                                setNewInitiativeEmailModal(value)
                            }}
                        >
                            <label className="label-inside">E-mail</label>
                            <input />
                            <div
                                className="img-icon flex-cc"
                                onClick={() => setNewInitiativeEmailModal('')}
                            >
                                <img src={closeInputIcon} alt="close-input" />
                            </div>
                        </Input>
                    </div>
                </div>
            </Modal>
        </Container>
    )
}