import emptyImg from "../../../assets/img/empty-img.svg"
import checkIcon from "../../../assets/img/icons/check.svg"
import ods1 from "../../../assets/img/icons/ods/E-WEB-Goal-01.svg"
import ods2 from "../../../assets/img/icons/ods/E-WEB-Goal-02.svg"
import ods3 from "../../../assets/img/icons/ods/E-WEB-Goal-03.svg"
import ods4 from "../../../assets/img/icons/ods/E-WEB-Goal-04.svg"
import ods5 from "../../../assets/img/icons/ods/E-WEB-Goal-05.svg"
import ods6 from "../../../assets/img/icons/ods/E-WEB-Goal-06.svg"
import ods7 from "../../../assets/img/icons/ods/E-WEB-Goal-07.svg"
import ods8 from "../../../assets/img/icons/ods/E-WEB-Goal-08.svg"
import ods9 from "../../../assets/img/icons/ods/E-WEB-Goal-09.svg"
import ods10 from "../../../assets/img/icons/ods/E-WEB-Goal-10.svg"
import ods11 from "../../../assets/img/icons/ods/E-WEB-Goal-11.svg"
import ods12 from "../../../assets/img/icons/ods/E-WEB-Goal-12.svg"
import ods13 from "../../../assets/img/icons/ods/E-WEB-Goal-13.svg"
import ods14 from "../../../assets/img/icons/ods/E-WEB-Goal-14.svg"
import ods15 from "../../../assets/img/icons/ods/E-WEB-Goal-15.svg"
import ods16 from "../../../assets/img/icons/ods/E-WEB-Goal-16.svg"
import ods17 from "../../../assets/img/icons/ods/E-WEB-Goal-17.svg"
import thematicIcon1 from "../../../assets/img/icons/thematic/thematic-01.svg"
import thematicIcon2 from "../../../assets/img/icons/thematic/thematic-02.svg"
import thematicIcon3 from "../../../assets/img/icons/thematic/thematic-03.svg"
import thematicIcon4 from "../../../assets/img/icons/thematic/thematic-04.svg"
import thematicIcon5 from "../../../assets/img/icons/thematic/thematic-05.svg"
import thematicIcon6 from "../../../assets/img/icons/thematic/thematic-06.svg"
import thematicIcon7 from "../../../assets/img/icons/thematic/thematic-07.svg"
import thematicIcon8 from "../../../assets/img/icons/thematic/thematic-08.svg"
import thematicIcon9 from "../../../assets/img/icons/thematic/thematic-09.svg"
import thematicIcon10 from "../../../assets/img/icons/thematic/thematic-10.svg"
import thematicIcon11 from "../../../assets/img/icons/thematic/thematic-11.svg"
import thematicIcon12 from "../../../assets/img/icons/thematic/thematic-12.svg"
import thematicIcon13 from "../../../assets/img/icons/thematic/thematic-13.svg"
import thematicIcon14 from "../../../assets/img/icons/thematic/thematic-14.svg"
import thematicIcon15 from "../../../assets/img/icons/thematic/thematic-15.svg"
import thematicIcon16 from "../../../assets/img/icons/thematic/thematic-16.svg"
import thematicIcon17 from "../../../assets/img/icons/thematic/thematic-17.svg"
import thematicIcon18 from "../../../assets/img/icons/thematic/thematic-18.svg"
import thematicIcon19 from "../../../assets/img/icons/thematic/thematic-19.svg"
import thematicIcon20 from "../../../assets/img/icons/thematic/thematic-20.svg"
import thematicIcon21 from "../../..//assets/img/icons/thematic/thematic-21.svg"
import thematicIcon22 from "../../..//assets/img/icons/thematic/thematic-22.svg"
import thematicIcon23 from "../../..//assets/img/icons/thematic/thematic-23.svg"
import thematicIcon24 from "../../..//assets/img/icons/thematic/thematic-24.svg"
import thematicIcon25 from "../../..//assets/img/icons/thematic/thematic-25.svg"
import thematicIcon26 from "../../..//assets/img/icons/thematic/thematic-26.svg"
import thematicIcon27 from "../../..//assets/img/icons/thematic/thematic-27.svg"
import thematicIcon28 from "../../..//assets/img/icons/thematic/thematic-28.svg"
import thematicIcon29 from "../../..//assets/img/icons/thematic/thematic-29.svg"
import websiteIcon from "../../../assets/img/icons/website.svg"
import facebookIcon from "../../../assets/img/icons/facebook.svg"
import instagramIcon from "../../../assets/img/icons/instagram.svg"
import youtubeIcon from "../../../assets/img/icons/youtube.svg"
import linkedinIcon from "../../../assets/img/icons/linkedin.svg"
import tiktokIcon from "../../../assets/img/icons/tiktok.svg"
import emailIcon from "../../../assets/img/icons/email.svg"
import { CongratsProps } from "../../../models/CongratsProps"
import { Container } from "./style"

type Props = {
    dataPage: CongratsProps
}

const URL = 'https://api.perifasul.org/public/'

const odsOptions = [
    { key: 100, icon: ods1 },
    { key: 200, icon: ods2 },
    { key: 300, icon: ods3 },
    { key: 400, icon: ods4 },
    { key: 500, icon: ods5 },
    { key: 600, icon: ods6 },
    { key: 700, icon: ods7 },
    { key: 800, icon: ods8 },
    { key: 900, icon: ods9 },
    { key: 1010, icon: ods10 },
    { key: 1110, icon: ods11 },
    { key: 1210, icon: ods12 },
    { key: 1310, icon: ods13 },
    { key: 1410, icon: ods14 },
    { key: 1510, icon: ods15 },
    { key: 1610, icon: ods16 },
    { key: 1710, icon: ods17 },
]

const thematicOptions = [
    { key: 100, text: 'Ações Afirmativas: raça, gênero e protagonismo jovem', icon: thematicIcon1 },
    { key: 200, text: 'Advocacy & Incidêcia Politica', icon: thematicIcon2 },
    { key: 300, text: 'Arte & Cultura', icon: thematicIcon3 },
    { key: 400, text: 'Assistência Social', icon: thematicIcon4 },
    { key: 500, text: 'Bem Viver & Qualidade de Vida', icon: thematicIcon5 },
    { key: 600, text: 'Comunicação & Marketing', icon: thematicIcon6 },
    { key: 700, text: 'Defesa de direitos da população LGBTQIAP+', icon: thematicIcon7 },
    { key: 800, text: 'Defesa de direitos da população negra', icon: thematicIcon8 },
    { key: 900, text: 'Defesa de direitos das Mulheres', icon: thematicIcon9 },
    { key: 1010, text: 'Defesa de direito dos povos indígenas', icon: thematicIcon10 },
    { key: 1110, text: 'Desenvolvimento Comunitário', icon: thematicIcon11 },
    { key: 1210, text: 'Direito dos Animais', icon: thematicIcon12 },
    { key: 1310, text: 'Direitos humanos, justiça social e cidadania', icon: thematicIcon13 },
    { key: 1410, text: 'Economia criativa e solidária', icon: thematicIcon14 },
    { key: 1510, text: 'Educação', icon: thematicIcon15 },
    { key: 1610, text: 'Esporte & Saúde', icon: thematicIcon16 },
    { key: 1710, text: 'Habitação', icon: thematicIcon17 },
    { key: 1810, text: 'Inclusão Digital', icon: thematicIcon18 },
    { key: 1910, text: 'Inclusão Produtiva (empreendedorismo, empregabilidade e capacitação para o mercado de trabalho, geração de trabalho e renda)', icon: thematicIcon19 },
    { key: 2010, text: 'Justiça Racial', icon: thematicIcon20 },
    { key: 2110, text: 'Meio ambiente / Sustentabilidade', icon: thematicIcon21 },
    { key: 2210, text: 'Mobilidade Urbana', icon: thematicIcon22 },
    { key: 2310, text: 'Paz & Justiça', icon: thematicIcon23 },
    { key: 2410, text: 'PCD - Pessoas com Deficiência', icon: thematicIcon24 },
    { key: 2510, text: 'Pesquisa / Produção de Conhecimento', icon: thematicIcon25 },
    { key: 2610, text: 'Refugiados', icon: thematicIcon26 },
    { key: 2710, text: 'Saúde', icon: thematicIcon27 },
    { key: 2810, text: 'Tecnologias Sociais', icon: thematicIcon28 },
    { key: 2910, text: 'Outro (especifique)', icon: thematicIcon29 },
]

const offerTerritoryAvailableOptions = [
    { key: 1, text: 'Biblioteca', value: 1 },
    { key: 2, text: 'Espaço para eventos', value: 2 },
    { key: 3, text: 'Equipamentos', value: 3 },
    { key: 4, text: 'Materiais', value: 4 },
    { key: 5, text: 'Sala de informática', value: 5 },
    { key: 6, text: 'Espaço de coworking', value: 6 },
    { key: 7, text: 'Estúdio de gravação', value: 7 },
    { key: 8, text: 'Quadras', value: 8 }
]

const providersAvailableOptions = [
    { key: 1, text: 'Professor', value: 1 },
    { key: 2, text: 'Carreto', value: 2 },
    { key: 3, text: 'Lanches', value: 3 },
    { key: 4, text: 'Costureira', value: 4 },
    { key: 5, text: 'Estamparia', value: 5 },
    { key: 6, text: 'Contador', value: 6 },
    { key: 7, text: 'Fotógrafo', value: 7 }
]

const networksSelectOtions = [
    { alias: 'website', icon: websiteIcon, },
    { alias: 'facebook', icon: facebookIcon, },
    { alias: 'instagram', icon: instagramIcon, },
    { alias: 'youtube', icon: youtubeIcon, },
    { alias: 'linkedin', icon: linkedinIcon, },
    { alias: 'tiktok', icon: tiktokIcon, },
    { alias: 'email', icon: emailIcon, },
]

export const InitiativeSummaryCard = ({ dataPage }: Props): JSX.Element => {
    const getOdsImg = (ods: number[]): JSX.Element => {
        if (ods.length) {
            return (
                <div className="ods">
                    {odsOptions.filter(o => ods.includes(o.key)).map((item, index) =>
                        <div className="image" key={index}>
                            <img src={item.icon} alt="" />
                        </div>
                    )}
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getThematics = (thematics: number[]): JSX.Element => {
        if (thematics.length) {
            return (
                <div className="thematics">
                    {thematicOptions.filter(o => thematics.includes(o.key)).map((item, index) =>
                        <div className="thematic" key={index}>
                            <img src={item.icon} alt="" />
                            <span>{item.text}</span>
                        </div>
                    )}
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getOfferTerritory = (values: number[]): JSX.Element => {
        if (values.length) {
            return (
                <div className="items">
                    {offerTerritoryAvailableOptions.filter(o => values.includes(o.key)).map((item, index) =>
                        <div className="item" key={index}>
                            <img src={checkIcon} alt="" />
                            <span>{item.text}</span>
                        </div>
                    )}
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getOfferTerritoryOthers = (values: string[]): JSX.Element => {
        if (values.length) {
            return (
                <div className="items">
                    {values.map((item, index) =>
                        <div className="item" key={index}>
                            <img src={checkIcon} alt="" />
                            <span>{item}</span>
                        </div>
                    )}
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getProviders = (values: number[]): JSX.Element => {
        if (values.length) {
            return (
                <div className="items">
                    {providersAvailableOptions.filter(o => values.includes(o.key)).map((item, index) =>
                        <div className="item" key={index}>
                            <img src={checkIcon} alt="" />
                            <span>{item.text}</span>
                        </div>
                    )}
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getSocialNetworkInfo = (): any[] => {
        return [
            { iconImg: websiteIcon, text: dataPage.website || '' },
            { iconImg: facebookIcon, text: dataPage.facebook || '' },
            { iconImg: instagramIcon, text: dataPage.instagram || '' },
            { iconImg: youtubeIcon, text: dataPage.youtube || '' },
            { iconImg: linkedinIcon, text: dataPage.linkedin || '' },
            { iconImg: tiktokIcon, text: dataPage.tiktok || '' },
            { iconImg: emailIcon, text: dataPage.email || '' },
        ]
    }

    return (
        <Container>
            <div className="initiative-resume">
                <div className="head">
                    <div className="info flex-sc">
                        <img src={dataPage.logo ? `${URL + dataPage.logo}` : emptyImg} alt="empty-image" />
                        <div className="title">{dataPage.title}</div>
                    </div>
                </div>

                <div className="body">
                    <div className="first-info">
                        {dataPage.ods && getOdsImg(dataPage.ods)}

                        {dataPage.thematics && getThematics(dataPage.thematics)}

                        <div className="description">{dataPage.description}</div>
                    </div>
                    <div className="about-initiative">
                        <div className="title">SOBRE A INICIATIVA</div>

                        <div className="row">
                            {getSocialNetworkInfo().map(item => (item.text &&
                                <div className="col-md-12">
                                    <div className="item">
                                        <img src={item.iconImg} alt="website-icon" className="mr-24" />
                                        <span>{item.text}</span>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                    <div className="solidarity">
                        <div className="title">BANCO DA SOLIDARIEDADE</div>

                        <div className="row">
                            <div className="col-md-12">
                                {dataPage.offerTerritory && getOfferTerritory(dataPage.offerTerritory)}
                            </div>
                            <div className="col-md-12">
                                {dataPage.offerTerritoryOthers && getOfferTerritoryOthers(dataPage.offerTerritoryOthers)}
                            </div>
                        </div>
                    </div>
                    <div className="final">
                        <div className="title">CIDADE - DISTRITO</div>
                        <div className="localization">{dataPage.localization}</div>
                    </div>
                    <div className="footer">
                        <div className="select-networks">
                            {networksSelectOtions.map((o, index) => (
                                <div
                                    key={index}
                                    className={dataPage.networks && dataPage.networks.includes(o.alias) ? 'item selected' : 'item'}
                                >
                                    <img src={o.icon} alt={o.alias} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}