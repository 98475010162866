import { Button } from "../../../designSystems/components/Button"
import leftArrow from "../../../assets/img/icons/left-arrow-white.svg"
import illustraMap from '../../../assets/img/illustra-map.svg'
import fundacaoAbh from '../../../assets/img/fundacao-abh.svg'
import fundacaoMboi from '../../../assets/img/fundacao-mboi.svg'
import eyeFillIcon from "../../../assets/img/icons/eye-fill.svg"
import eyeSlashFillIcon from "../../../assets/img/icons/eye-slash-fill.svg"
import { useNavigate, useParams } from "react-router"
import { Container } from "./style"
import { useContext, useState } from "react"
import { Form } from "semantic-ui-react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { GlobalContext } from "../../../context/GlobalContext"
import { newPasswordSecurity } from "../../../api/authentication"

type Props = {
    newPassword: string
    repeatNewPassword: string
}

export const NewPasswordPage = (): JSX.Element => {
    const { setIsLoading, setDataToast } = useContext(GlobalContext)
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false)
    const [isEqual, setIsEqual] = useState(true)
    const { email, code } = useParams()
    const { handleSubmit, control, getValues, formState: { errors } } = useForm<Props>(
        { defaultValues: { newPassword: '', repeatNewPassword: '' } }
    )

    const navigate = useNavigate()

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const onSubmit: SubmitHandler<Props> = data => {
        if ((data.newPassword && data.repeatNewPassword) && (data.newPassword === data.repeatNewPassword)) {
            const result = {
                email,
                code,
                password: data.newPassword,
                password_confirmation: data.repeatNewPassword
            }

            setIsLoading(true)
            newPasswordSecurity(result).then((response: any) => {
                setIsLoading(false)
                setDataToast({ show: true, text: response.data.message })
                goToPage('/login')
            }).catch(err => {
                setIsLoading(false)
                const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao atualizar nova senha' : err.response.data.message
                setDataToast({ show: true, text: textError })
            })
        }
    }

    return (
        <Container>
            <div className="btn-back">
                <Button
                    text={'Voltar ao mapa'}
                    icon={leftArrow}
                    height="42px"
                    background="transparent"
                    className="radius-8 float-left btn-register"
                    onClick={e => goToPage('/mapa')}
                />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="left-content">
                            <div className="box-container flex-cc">
                                <div className="title-content">
                                    Pronto, redefina <br />a sua senha
                                </div>

                                <div className="box flex-cc">
                                    <div className="d-block text-center">
                                        <div className="title">Nova senha</div>
                                        <div className="subtitle">Confirme a sua nova senha para acessar a plataforma Mapa PerifaSul:</div>

                                        <div className="flex-cc">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <Controller
                                                    name="newPassword"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <Form.Input
                                                                fluid
                                                                type={showNewPassword ? 'text' : 'password'}
                                                                size="huge"
                                                                label='Nova senha *'
                                                                placeholder='*********'
                                                                className={errors.newPassword && 'field-error'}
                                                                value={value}
                                                                onChange={function (e, { value }) {
                                                                    setNewPassword(value)
                                                                    onChange(value)
                                                                }}
                                                            >
                                                                <input style={{ paddingRight: 40 }} />
                                                                <div className="img-icon flex-cc" onClick={() => {
                                                                    setShowNewPassword(!showNewPassword)
                                                                }}>
                                                                    <img src={showNewPassword ? eyeFillIcon : eyeSlashFillIcon} alt="close-input" />
                                                                </div>
                                                            </Form.Input>
                                                            {errors.newPassword && <p className="field-error-msg">Campo obrigatório</p>}
                                                        </>
                                                    )}
                                                />

                                                <Controller
                                                    name="repeatNewPassword"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <Form.Input
                                                                fluid
                                                                type={showRepeatNewPassword ? 'text' : 'password'}
                                                                size="huge"
                                                                label='Confirmar nova senha *'
                                                                placeholder='*********'
                                                                className={errors.repeatNewPassword && 'field-error'}
                                                                value={value}
                                                                onChange={function (e, { value }) {
                                                                    setRepeatNewPassword(value)
                                                                    onChange(value)
                                                                }}
                                                            >
                                                                <input style={{ paddingRight: 40 }} />
                                                                <div className="img-icon flex-cc" onClick={() => {
                                                                    setShowRepeatNewPassword(!showRepeatNewPassword)
                                                                }}>
                                                                    <img src={showRepeatNewPassword ? eyeFillIcon : eyeSlashFillIcon} alt="close-input" />
                                                                </div>
                                                            </Form.Input>
                                                            {errors.repeatNewPassword && <p className="field-error-msg">Campo obrigatório</p>}
                                                            {newPassword && repeatNewPassword && newPassword !== repeatNewPassword && <p className="field-error-msg">Senhas devem ser iguais</p>}
                                                        </>
                                                    )}
                                                />

                                                <Button className="btn-submit radius-100" type="submit" text="Confirmar nova senha" />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="right-content flex-cc">
                            <img src={illustraMap} alt="home-map" />
                        </div>
                        <div className='row margin-top-20'>
                            <div className="col-md-12">
                                <div className="display: flex; justify-content: center text-center">
                                    <img src={fundacaoAbh} alt="bg-img-home" />
                                    <img src={fundacaoMboi} alt="bg-img-home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}