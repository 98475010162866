import { useState } from "react";
import { Container } from "./style";
import download from "../../../assets/img/icons/download.svg"
import trash from "../../../assets/img/icons/trash.svg"
import emptyImg from "../../../assets/img/empty-img.svg"
import _ from "lodash";

type Props = {
    label: string,
    logoUrl?: string,
    changeLogoFile?: any
}

const URL = 'https://api.perifasul.org/public/'

export const UploadImg = ({ label, changeLogoFile, logoUrl }: Props): JSX.Element => {
    const [file, setFile] = useState<any>(undefined);
    const [picture, setPicture] = useState(undefined);
    const [imgData, setImgData] = useState<any>(undefined);

    const handleChange = (e: any) => {
        let selected = e.target.files[0];
        if (selected) {
            setPicture(e.target.files[0].name);

            const reader = new FileReader();
            reader.readAsDataURL(selected);

            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });

            reader.onload = function (upload) {
                setFile(reader?.result);
                changeLogoFile(reader?.result);
            };
        } else {
            setFile(undefined);
            changeLogoFile(undefined);
        }
    };

    const deletePicture = (): void => {
        setPicture(undefined)
        setImgData(undefined)
        changeLogoFile(undefined);
    }

    return (
        <Container>
            <div>{label}</div>

            <div className="previewProfilePic">
                <img className="trash" src={trash} onClick={deletePicture} />
                {logoUrl && !imgData ?
                    <img className="playerProfilePic_home_tile" src={`${URL + logoUrl}`} /> :
                    <img className="playerProfilePic_home_tile" src={imgData || emptyImg} />
                }
                <div className="text">{picture}</div>
            </div>

            <div className="register_profile_image">
                <label htmlFor="logoImgFile" className="btn radius-100">
                    <img className="download-icon" src={download} />
                    <span>Adicionar</span>
                </label>
                <input id="logoImgFile" type="file" style={{ "visibility": "hidden" }} accept="image/png, image/jpeg" onChange={handleChange} />
            </div>
        </Container>
    )
}