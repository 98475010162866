import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { updateStepGranted } from '../api/utils';
import { dataToastProps, GlobalContext } from '../context/GlobalContext';
import { Toast } from '../designSystems/components/Toast';
import useIsMobile from '../hooks/use-is-mobile.hook';
import { userProps } from '../models/userProps';
import { AdminPage } from '../presentation/pages/Admin';
import { HomePage } from '../presentation/pages/Home';
import { LoginPage } from '../presentation/pages/Login';
import { MapPage } from '../presentation/pages/Map';
import { MapRegisterPage } from '../presentation/pages/MapInscricao';
import { NewPasswordPage } from '../presentation/pages/NewPassword';
import { RegisterPage } from '../presentation/pages/Register';
import { RequestNewPasswordPage } from '../presentation/pages/RequestNewPassword';
import GlobalStyle from '../styles/global'

const TYPE_ERRORS: any = {
    'Unauthenticated.': 'Seu tempo expirou.\nFaça login novamente'
}

export const App = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)
    const [userId, setUserId] = useState(0)
    const [userData, setUserData] = useState<userProps>({ name: 'Nome do usuário' })
    const [dataToast, setDataToast] = useState<dataToastProps>({ show: false, text: '' })
    const [lastStep, setLastStep] = useState(1)
    const [menuItemActivated, setMenuItemActivated] = useState<string>('iniciativas')

    const isAuthenticated = (localStorage.getItem('usuario') || localStorage.getItem('authentication-admin')) !== null

    const isMobile = useIsMobile()

    const getErrorMessage = (errorMessage: string): string => {
        return Object.keys(TYPE_ERRORS).includes(errorMessage) ? TYPE_ERRORS[`${errorMessage}`] : errorMessage
    }

    const vertifyAuthenticatedRedirect = (errorMessage: string): boolean => {
        if (errorMessage.includes('Unauthenticated')) {
            localStorage.removeItem('usuario')
            localStorage.removeItem('usuario-temp')
            localStorage.removeItem('authentication-admin')
            return true
        }

        return false
    }

    const changeLastStep = (newStep: number): void => {
        if (newStep === 0 || newStep > lastStep) {
            const newStepAux = newStep === 0 ? 1 : newStep

            setLastStep(newStepAux)
            setIsLoading(true)

            updateStepGranted(newStepAux, userId).then(() => {
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
                setDataToast({ show: true, text: textError })
            })
        }
    }

    const pathnameIsAdmin = (): boolean => {
        return window.location.pathname === '/admin'
    }

    const showNextRegisterButton = (updateAction: boolean): boolean => {
        return (([1, 2, 200].includes(userData?.permissaos_id || 0) && updateAction) || userData?.permissaos_id === 3)
    }

    return (
        <GlobalContext.Provider value={{
            isLoading,
            setIsLoading,
            dataToast,
            setDataToast,
            isAuthenticated,
            getErrorMessage,
            userData,
            setUserData,
            vertifyAuthenticatedRedirect,
            userId,
            setUserId,
            changeLastStep,
            lastStep,
            setLastStep,
            pathnameIsAdmin,
            showNextRegisterButton,
            menuItemActivated,
            setMenuItemActivated,
            isMobile
        }}>
            <Router>
                <GlobalStyle />
                {isLoading &&
                    <div className="loading">
                        <div className="spinner-border text-warning" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }

                {dataToast.show &&
                    <Toast text={dataToast.text} />
                }

                <Routes>
                    <Route path="/">
                        <Route index element={<HomePage />} />
                        <Route path="mapa" element={<MapRegisterPage />}>
                            <Route index element={<MapPage />} />
                            <Route path="inscricao" element={<RegisterPage fetchData={() => undefined} />} />
                        </Route>
                        <Route path="/login" element={isAuthenticated ? <Navigate to="/admin" replace /> : <LoginPage />} />
                        <Route path="/solicitar-nova-senha" element={<RequestNewPasswordPage />} />
                        <Route path="/nova-senha" element={<NewPasswordPage />} />
                        <Route path="/nova-senha/:email/:code" element={<NewPasswordPage />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route path="admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" replace />}></Route>
                        {/* {isAuthenticated ?
                        <Route path="admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" replace />}>
                            <Route path="*" element={<Navigate to="/admin" replace />} />
                        </Route> :
                        <Route path="*" element={<Navigate to="/login" replace />} />
                        } */
                        }

                    </Route>
                </Routes>
            </Router>
        </GlobalContext.Provider >
    );
}

export default App;
