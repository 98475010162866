import styled from "styled-components";
import { Variables as obj, Variables } from './../../../styles/variables'

export const Container = styled.div`
.register-steps {
    margin-top: 35px;
    display: flex;
    height: 48px;
    margin-bottom: 32px;
    border-bottom: 0.5px solid #516274;

    .step {
        cursor: pointer;
        min-width: 20%;
        
        &:not(:last-of-type) {
            padding-right: 8px;
        }

        &.current {
            .content {
                .circle {
                    color: #FFF;
                    background: ${Variables.yellow};
                }

                .text {
                    color: #000000;
                }
            }
        }

        &.block {
            position: relative;

            .block-content {
                position: absolute;
                background: #ffffffd9;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: start;
                justify-content: center;

                img {
                    width: 24px;
                    opacity: 0;
                    scale: 0;
                    transition: all 0.3s ease;
                }

                &:hover {
                    img {
                        opacity: 0.7;
                        scale: 1;
                    }   
                }
            }
        }

        .content {
            display: flex;
            align-items: baseline;
            
            .circle {
                min-width: 24px;
                min-height: 24px;
                color: #FFF;
                background: ${Variables.gray};
                border-radius: 100px;
                margin-bottom: 4px;
            }
    
            .text {
                margin-left: 12px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: ${Variables.gray};
            }
        }
    }
}

.bar-footer {
    height: 14px;
    width: 100%;
    background: ${obj.yellow};
    position: fixed;
    bottom: 0px;
}

.back-btn {
    margin-top: 20px;
    margin-bottom: -10px;
    width: fit-content;
    cursor: pointer;

    img {
        width: 18px;
    }
}

@media (max-width: 575.98px) {
    .register-steps {
        overflow-x: auto;
        overflow-y: hidden;
    
        .step {
            min-width: 60%;

            &:nth-child(n + 2) {
                min-width: 50%;
            }

            &:nth-child(n + 3) {
                min-width: 70%;
            }

            &:nth-child(n + 4) {
                min-width: 50%;
            }

            &:nth-child(n + 5) {
                min-width: 60%;
            }
        }
    }
}

@media (min-width: 575.99px) and (max-width: 767.98px) {}

@media (min-width: 767.99px) and (max-width: 991.98px) {
    .register-steps {
        .step {
            min-width: 20%;

            &:nth-child(n + 2) {
                min-width: 17%;
            }

            &:nth-child(n + 3) {
                min-width: 26%;
            }

            &:nth-child(n + 4) {
                min-width: 17%;
            }
        }
    }
}

@media (min-width: 991.99px) and (max-width: 1199.98px) {}

@media (min-width: 1399.99px) {}
`