import { useContext, useEffect, useState } from "react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { CongratsProps } from "../../../models/CongratsProps"
import { useNavigate } from "react-router"
import { GlobalContext } from "../../../context/GlobalContext"
import { getCongratulations } from "../../../api/solidarity-bank"
import { AdminPageContext } from "../../../context/AdminPageContext"
import { InitiativeSummaryCard } from "../InitiativeSummaryCard"
import { cityActionsOptions, districtLocaleOptions } from "../../../api/utils/fieldsOptions"

export const CongratsContent = (): JSX.Element => {
    const [dataPage, setDataPage] = useState<CongratsProps>({})

    const { setCurrentPage } = useContext(RegisterPageContext)
    const { setIsEdition, isEdition } = useContext(AdminPageContext)
    const { setIsLoading, setDataToast, getErrorMessage, vertifyAuthenticatedRedirect, userId, pathnameIsAdmin } = useContext(GlobalContext)

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        getCongratulations(pathnameIsAdmin() ? { user_id: userId } : null).then((response: any) => {
            setIsLoading(false)

            if (response.data.data.length) {
                const responseData: CongratsProps = response.data.data[0]
                const cityName = cityActionsOptions.filter(item => item.value === responseData.cityActions)[0]?.text || ''
                let districtName = ''

                if (responseData?.districtLocale && responseData?.districtLocale.length) {
                    districtName = districtLocaleOptions.filter(item => (JSON.parse(responseData?.districtLocale as any) || [] as number[]).includes(item.value as number)).map(d => d.text || '').join(', ')
                }

                setDataPage({
                    title: responseData.initiativeName,
                    ods: responseData.ods ? JSON.parse(responseData.ods) : '',
                    thematics: responseData.areasExpertise ? JSON.parse(responseData.areasExpertise) : '',
                    description: responseData.initiativeDescription,
                    offerTerritory: responseData.offerTerritoryAvailable ? JSON.parse(responseData.offerTerritoryAvailable) : '',
                    offerTerritoryOthers: responseData.offerTerritoryOthers ? JSON.parse(responseData.offerTerritoryOthers) : '',
                    providers: [1],
                    localization: responseData.cityActions === 1 ? `${cityName} - ${districtName}` : `${cityName}`,
                    networks: responseData.networks ? JSON.parse(responseData.networks) : '',
                    email: responseData.email,
                    facebook: responseData.facebook,
                    instagram: responseData.instagram,
                    linkedin: responseData.linkedin,
                    tiktok: responseData.tiktok,
                    website: responseData.website,
                    youtube: responseData.youtube,
                    logo: responseData.logo,
                })
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    return (
        <Container>
            <div className="row">
                <div className="col-md-12 flex-cc">
                    <div className="conclusion">
                        <div className="msg">
                            <div className="title">Parabéns!</div>
                            <div className="description">
                                Você acabou de adicionar uma iniciativa no mapa da PerifaSul 2050!
                                Seu card aparecerá desta forma no mapa:
                            </div>
                        </div>

                        <div className="flex-cc">
                            <InitiativeSummaryCard dataPage={dataPage} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-footer">
                <hr />
                <div className="btn-actions f-right">
                    <Button
                        text="Voltar à edição"
                        className="radius-100 btn-gray mr-32"
                        onClick={() => setCurrentPage('solidarity-bank')}
                    />
                    <Button
                        type="submit"
                        text="Concluir"
                        className="radius-100"
                        onClick={() => isEdition ? setIsEdition(!isEdition) : goToPage('/admin')}
                    />
                </div>
            </div>
        </Container>
    )
}