import styled from "styled-components";

export const Container = styled.div`
.ads {
    min-height: calc(100vh - 102px);

    .title {
        display: flex;
        margin-bottom: 32px;

        .text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }

        hr {
            border: 0.5px solid #516274;
            width: 100%;
            margin-left: 21px;
            margin-right: 21px;
            margin-top: 19px;
        }
    }

    .cards {
        margin-top: 25px;
        margin-bottom: 35px;

        .card {
            min-height: 68px;
            background: rgba(243, 243, 243, 0.9);
            opacity: 0.95;
            padding: 8px 16px;
            border-radius: 12px;
            border: unset;
            margin-top: 25px;

            .info {
                display: flex;

                img {
                    height: 56px;
                    margin-right: 14px;
                }

                .name {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 4px;
                }

                .description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }
            }

            .status {
                .status {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 4px;
                }

                .date {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }
            }
        }
    }
}
`