import { Variables } from '../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
position: relative;
min-height: calc(100vh - 60px);
padding-bottom: 141px;

.select-networks {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;

    .item {
        border: 3px solid #FFF;
        border-radius: 100px;

        &.selected {
            border: 3px solid ${Variables.yellow};
        }
    }
}

.ui {
    &.form {
        position: unset;

        .field {
            margin-bottom: 14px;
        }

        .footer {
            width: 100%;
            position: absolute;
            bottom: 28px;

            .btn-actions {
                display: flex;
            }
        }

        .ods-options {
            .ui.checkbox input:checked~label:after {
                top: 0;
                transform: translateY(50%);
            }

            label {
                display: flex;
                align-items: center;

                &:before {
                    top: 0;
                    transform: translateY(50%);
                }
                
                img {
                    width: 36px;
                    margin-right: 8px;
                }

                .text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    color: #000000;
                }
            }
        }
    }
}
`