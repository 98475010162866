import styled from "styled-components";
import { Variables } from "../../../styles/variables";

export const Container = styled.div`
.register_profile_image{
    margin-top: 15px;

    .btn {
        background: ${Variables.gray};
        color: #FFF;
        padding: 8px 20px;
        display: inline-flex;
        align-items: center;
        transition: background .15s ease-in-out;

        span {
            color: #FFF;
        }

        :hover {
            background: #66788a;
        }

        .download-icon {
            margin-right: 11px;
        }
    }
}

.previewProfilePic {
    position: relative;
    display: flex;
    margin-top: 15px;

    .trash {
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 18px;
    }

    .text {
        margin-left: 24px;
        margin-right: 24px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #49454F;
        word-break: break-all;
    }

    img {
        height: 86px;
    }
}`