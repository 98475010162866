import { api, getConfigApi, getSimpleConfigApi } from './../../services/api'
import { FirstInformationProps } from './../../models/FirstInformationProps'

const convertFirstInfo = (args: FirstInformationProps): any => {
    return {
        id: args.id,
        whatIdo: args.whatIdo,
        initiativeNature: args.initiativeNature,
        initiativeIs: args.initiativeIs,
        legalFormat: args.legalFormat,
        ods: args.ods,
        areasExpertise: args.areasExpertise,
        cep: args.cep,
        address: args.address,
        number: args.number,
        complement: args.complement,
        burgh: args.burgh,
        district: args.district,
        city: args.city,
        state: args.state,
        phone: args.phone,
        cityActions: args.cityActions,
        districtLocale: args.districtLocale || null,
        email: args.email,
        coverageArea: args.coverageArea,
        hasCnpj: args.hasCnpj,
        initiativeName: args.initiativeName,
        logo: args.logo,
        initiativeDescription: args.initiativeDescription,
        initiativeNatureOther: args.initiativeNatureOther,
        legalFormatOther: args.legalFormatOther,
        areasExpertiseOther: args.areasExpertiseOther,
        name: args.name,
        permissaos_id: 3,
        youAre: args.youAre,
        tipo_organizacao_id: args.tipoOrganizacao,
        receiveAdvertisement: 1,
        cpf: args.cpf?.replaceAll('.', '').replace('-', '')
    }
}

export const getFirstInfo = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('first-information', args)
}

export const createFirstInfo = async (args: FirstInformationProps): Promise<unknown> => {
    return await api.post('first-information/create', convertFirstInfo(args), localStorage.getItem('usuario') ? getConfigApi() : getSimpleConfigApi())
}

export const updateFirstInfo = async (args: FirstInformationProps): Promise<unknown> => {
    return await api.patch('first-information/update', convertFirstInfo(args), getConfigApi())
}
