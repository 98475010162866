import { useContext, useEffect, useState } from "react"
import { Checkbox, Dropdown, Form, Popup } from "semantic-ui-react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import websiteIcon from "../../..//assets/img/icons/website.svg"
import facebookIcon from "../../..//assets/img/icons/facebook.svg"
import instagramIcon from "../../..//assets/img/icons/instagram.svg"
import youtubeIcon from "../../..//assets/img/icons/youtube.svg"
import linkedinIcon from "../../..//assets/img/icons/linkedin.svg"
import tiktokIcon from "../../..//assets/img/icons/tiktok.svg"
import emailIcon from "../../..//assets/img/icons/email.svg"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { AboutInitiativeProps } from "../../../models/AboutInitiativeProps"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { GlobalContext } from "../../../context/GlobalContext"
import { useNavigate } from "react-router"
import { withMask } from 'use-mask-input';
import { createAboutInitiative, updateAboutInitiative, getAboutInitiative } from "../../../api/about-initiative"
import { FirstInformationProps } from "../../../models/FirstInformationProps"
import { getFirstInfo } from "../../../api/first-info"
import axios from "axios"
import _ from "lodash"
import { approvedProjectLawsOptions, documentsOptions, hasTitleQualificationOptions, initiativeHasOptions, marketSegmentOptions, motivatingPublicOptions, percentWomenOptions, socialImpactDimensionOptions, whichArtOptions } from "../../../api/utils/fieldsOptions"

const defaultOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Sim', value: 1 },
    { key: 2, text: 'Não', value: 2 },
]

const serviceBuyerOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 1, value: 1, text: 'E-commerce' },
    { key: 2, value: 2, text: 'Empresas' },
    { key: 3, value: 3, text: 'Feira pública da cidade' },
    { key: 4, value: 4, text: 'Pessoas de outras regiões além da minha' },
    { key: 5, value: 5, text: 'Pessoas do meu bairro' },
    { key: 6, value: 6, text: 'Outros (especifique)' }
]

const serviceTypeOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 100, value: 100, text: 'Sou Artista' },
    { key: 200, value: 200, text: 'Audiovisual' },
    { key: 300, value: 300, text: 'Cabelereiro' },
    { key: 400, value: 400, text: 'Catering/Alimentação' },
    { key: 500, value: 500, text: 'Comunicação' },
    { key: 600, value: 600, text: 'Consultoria' },
    { key: 700, value: 700, text: 'Contabilidade' },
    { key: 800, value: 800, text: 'Jurídico' },
    { key: 900, value: 900, text: 'Logístico' },
    { key: 1010, value: 1010, text: 'Marceneiro' },
    { key: 1110, value: 1110, text: 'Produção de Eventos' },
    { key: 1210, value: 1210, text: 'Outros (especifique)' }
]

const buyerOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 1, value: 1, text: 'E-commerce' },
    { key: 2, value: 2, text: 'Empresas' },
    { key: 3, value: 3, text: 'Feira pública da cidade' },
    { key: 4, value: 4, text: 'Pessoas de outras regiões além da minha' },
    { key: 5, value: 5, text: 'Pessoas do meu bairro' },
    { key: 6, value: 6, text: 'Outros (especifique)' }
]

const productTypeOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 1, value: 1, text: 'Arte' },
    { key: 2, value: 2, text: 'Alimentos' },
    { key: 3, value: 3, text: 'Comunicação' },
    { key: 4, value: 4, text: 'Decoração' },
    { key: 5, value: 5, text: 'Produtos de Higiene' },
    { key: 6, value: 6, text: 'Vestuário' },
    { key: 7, value: 7, text: 'Outros (especifique)' }
]

const networksSelectOtions = [
    { alias: 'website', icon: websiteIcon, },
    { alias: 'facebook', icon: facebookIcon, },
    { alias: 'instagram', icon: instagramIcon, },
    { alias: 'youtube', icon: youtubeIcon, },
    { alias: 'linkedin', icon: linkedinIcon, },
    { alias: 'tiktok', icon: tiktokIcon, },
    { alias: 'email', icon: emailIcon, },
]

export const AboutInitiative = (): JSX.Element => {
    const [firstInfoData, setFirstInfoData] = useState<FirstInformationProps>({} as FirstInformationProps)

    const [serviceType, setServiceType] = useState<any>(undefined)
    const [networksSelected, setNetworksSelected] = useState<string[]>([])
    const [documents, setDocuments] = useState<number[]>([])
    const [approvedProjectLaws, setApprovedProjectLaws] = useState<number[]>([])
    const [initiativeHas, setInitiativeHas] = useState<number[]>([])
    const [hasTitleQualificationSelected, setHasTitleQualificationSelected] = useState<number[]>([])
    const [updateAction, setUpdateAction] = useState(false)

    const [whichArtOtherShow, setWhichArtOtherShow] = useState(false)
    const [serviceBuyerOtherShow, setServiceBuyerOtherShow] = useState(false)
    const [serviceTypeOtherShow, setServiceTypeOtherShow] = useState(false)
    const [buyerOtherShow, setBuyerOtherShow] = useState(false)
    const [productTypeOtherShow, setProductTypeOtherShow] = useState(false)
    const [hasTitleQualificationOtherShow, setHasTitleQualificationOtherShow] = useState(false)
    const [approvedProjectLawsOtherShow, setApprovedProjectLawsOtherShow] = useState(false)
    const [marketSegmentOtherShow, setMarketSegmentOtherShow] = useState(false)
    const [socialImpactDimensionOtherShow, setSocialImpactDimensionOtherShow] = useState(false)

    const { setCurrentPage, handleScroll } = useContext(RegisterPageContext)
    const { setIsLoading, setDataToast, changeLastStep, getErrorMessage, userData, vertifyAuthenticatedRedirect, userId, pathnameIsAdmin, showNextRegisterButton } = useContext(GlobalContext)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm<AboutInitiativeProps>(
        {
            defaultValues: {
                id: undefined,
                website: undefined,
                facebook: undefined,
                instagram: undefined,
                youtube: undefined,
                linkedin: undefined,
                tiktok: undefined,
                email: undefined,
                networks: [],
                yearFoundation: undefined,
                motivatingPublic: undefined,
                socialImpactDimension: undefined,
                hasTitleQualification: [],
                percentWomen: undefined,
                hasPoliticalParty: undefined,
                hasChurchAffiliation: undefined,
                marketSegment: undefined,
                productType: undefined,
                buyer: undefined,
                serviceType: undefined,
                whichArt: undefined,
                hasDrt: undefined,
                serviceBuyer: undefined,
                documents: [],
                approvedProjectLaws: [],
                initiativeHas: [],
                hasMission: undefined,
                hasVision: undefined,
                passAudit: undefined,
                whichArtOther: undefined,
                serviceBuyerOther: undefined,
                serviceTypeOther: undefined,
                buyerOther: undefined,
                productTypeOther: undefined,
                hasTitleQualificationOther: undefined,
                approvedProjectLawsOther: undefined,
                marketSegmentOther: undefined,
                socialImpactDimensionOther: undefined,
            }
        }
    );

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        axios.all([
            getAboutInitiative(pathnameIsAdmin() ? { user_id: userId } : null),
            getFirstInfo(pathnameIsAdmin() ? { user_id: userId } : null)
        ]).then((response: any) => {
            setIsLoading(false)

            if (response[0].data.data.length) {
                setUpdateAction(true)
                const responseData: AboutInitiativeProps = response[0].data.data[0]
                fillForm(responseData)
            }

            if (response[1].data.data.length) {
                setFirstInfoData(response[1].data.data[0])
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const fillForm = (data: AboutInitiativeProps): void => {
        setValue('id', data.id)
        setValue('website', data.website)
        setValue('facebook', data.facebook)
        setValue('instagram', data.instagram)
        setValue('youtube', data.youtube)
        setValue('linkedin', data.linkedin)
        setValue('tiktok', data.tiktok)
        setValue('email', data.email)

        setValue('networks', data.networks)
        setNetworksSelected(data.networks || [])

        setValue('yearFoundation', data.yearFoundation)
        setValue('motivatingPublic', data.motivatingPublic)
        setValue('socialImpactDimension', data.socialImpactDimension)

        setValue('hasTitleQualification', data.hasTitleQualification)
        setHasTitleQualificationSelected(data.hasTitleQualification as number[])

        setValue('percentWomen', data.percentWomen)
        setValue('hasPoliticalParty', data.hasPoliticalParty)
        setValue('hasChurchAffiliation', data.hasChurchAffiliation)
        setValue('marketSegment', data.marketSegment)
        setValue('productType', data.productType)
        setValue('buyer', data.buyer)
        setValue('serviceType', data.serviceType)
        setServiceType(data.serviceType || [])
        setValue('whichArt', data.whichArt)
        setValue('hasDrt', data.hasDrt)
        setValue('serviceBuyer', data.serviceBuyer)

        setValue('documents', data.documents)
        setDocuments(data.documents || [])

        setValue('approvedProjectLaws', data.approvedProjectLaws)
        setApprovedProjectLaws(data.approvedProjectLaws || [])

        setValue('initiativeHas', data.initiativeHas)
        setInitiativeHas(data.initiativeHas || [])

        setValue('hasMission', data.hasMission)
        setValue('hasVision', data.hasVision)
        setValue('passAudit', data.passAudit)

        setValue('whichArtOther', data.whichArtOther)
        setWhichArtOtherShow(data.whichArt === 4)

        setValue('serviceBuyerOther', data.serviceBuyerOther)
        setServiceBuyerOtherShow(data.serviceBuyer === 6)

        setValue('serviceTypeOther', data.serviceTypeOther)
        setServiceTypeOtherShow(data.serviceType === 1210)

        setValue('buyerOther', data.buyerOther)
        setBuyerOtherShow(data.buyer === 6)

        setValue('productTypeOther', data.productTypeOther)
        setProductTypeOtherShow(data.productType === 7)

        setValue('hasTitleQualificationOther', data.hasTitleQualificationOther)
        setHasTitleQualificationOtherShow((data.hasTitleQualification as number[])?.includes(9))

        setValue('approvedProjectLawsOther', data.approvedProjectLawsOther)
        setApprovedProjectLawsOtherShow(data.approvedProjectLaws?.includes(11) || false)

        setValue('marketSegmentOther', data.marketSegmentOther)
        setMarketSegmentOtherShow(data.marketSegment === 31)

        setValue('socialImpactDimensionOther', data.socialImpactDimensionOther)
        setSocialImpactDimensionOtherShow(data.socialImpactDimension === 13)
    }

    const onClickNetworkOption = (alias: string): void => {
        let result = networksSelected.includes(alias) ? [] : [alias]
        setNetworksSelected(result)
        setValue('networks', result)
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const onChangeDocuments = (checked: boolean | undefined, key: number): number[] => {
        let documentsList: number[] = [...documents];

        if (key === 17) {
            documentsList = []
        } else {
            documentsList = documentsList.filter(k => k !== 17)
        }

        if (checked) {
            documentsList.push(key)
        } else {
            documentsList = documentsList.filter(k => k !== key)
        }

        setDocuments(documentsList)
        return documentsList
    }

    const defaultCheckedDocuments = (value: number): boolean => {
        return documents?.length ? documents?.includes(value) : false
    }

    const onChangeApprovedProjectLaws = (checked: boolean | undefined, key: number): number[] => {
        let approvedProjectLawsList: number[] = [...approvedProjectLaws];

        if (checked) {
            approvedProjectLawsList.push(key)
        } else {
            approvedProjectLawsList = approvedProjectLawsList.filter(k => k !== key)
        }

        setApprovedProjectLaws(approvedProjectLawsList)
        setApprovedProjectLawsOtherShow(approvedProjectLawsList.includes(11))
        if (!approvedProjectLawsList.includes(11)) {
            setValue('approvedProjectLawsOther', '')
        }
        return approvedProjectLawsList
    }

    const defaultCheckedApprovedProjectLaws = (value: number): boolean => {
        return approvedProjectLaws?.length ? approvedProjectLaws?.includes(value) : false
    }

    const onChangeInitiativeHas = (checked: boolean | undefined, key: number): number[] => {
        let initiativeHasList: number[] = [...initiativeHas];

        if (key === 9) {
            initiativeHasList = []
        } else {
            initiativeHasList = initiativeHasList.filter(k => k !== 9)
        }

        if (checked) {
            initiativeHasList.push(key)
        } else {
            initiativeHasList = initiativeHasList.filter(k => k !== key)
        }

        setInitiativeHas(initiativeHasList)
        return initiativeHasList
    }

    const defaultCheckedInitiativeHas = (value: number): boolean => {
        return initiativeHas?.length ? initiativeHas?.includes(value) : false
    }

    const removeTitleQualificationItem = (value: number): void => {
        const result = hasTitleQualificationSelected.filter((d: number) => d !== value)
        setHasTitleQualificationSelected(result)
        setValue('hasTitleQualification', result)

        if (value === 9) {
            setValue('hasTitleQualificationOther', '')
            setHasTitleQualificationOtherShow(false)
        }
    }

    const create = (dataForm: AboutInitiativeProps): void => {
        setIsLoading(true)
        createAboutInitiative(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage([1, 4].includes(userData.tipo_organizacao_id as number) ? 'financial' : 'networks-partners')
            changeLastStep(3)
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const update = (dataForm: AboutInitiativeProps): void => {
        setIsLoading(true)
        updateAboutInitiative(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage([1, 4].includes(userData.tipo_organizacao_id as number) ? 'financial' : 'networks-partners')
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const onSubmit: SubmitHandler<AboutInitiativeProps> = data => {
        updateAction ? update(data) : create(data)
    }

    return (
        <Container
            count={hasTitleQualificationSelected.length}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="fw-600 mb-16 fc-black">
                        Insira os links das mídias sociais que a iniciativa possui:
                    </div>

                    <div className="col-md-4">
                        <Controller
                            name="website"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Website'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={websiteIcon} alt="website-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('website', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />

                        <Controller
                            name="facebook"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Facebook'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={facebookIcon} alt="facebook-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('facebook', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />

                        <Controller
                            name="instagram"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Instagram'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={instagramIcon} alt="instagram-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('instagram', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />
                    </div>

                    <div className="col-md-4">
                        <Controller
                            name="youtube"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Youtube'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={youtubeIcon} alt="youtube-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('youtube', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />

                        <Controller
                            name="linkedin"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Linkedin'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={linkedinIcon} alt="linkedin-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('linkedin', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />

                        <Controller
                            name="tiktok"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='tiktok'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={tiktokIcon} alt="tiktok-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('tiktok', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />
                    </div>

                    <div className="col-md-4">
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Outro e-mail da iniciativa que deseja cadastrar'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <img src={emailIcon} alt="email-icon" className="mr-24" />
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('email', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />

                        <div className="fw-600 mb-16 fc-black">
                            Selecione a rede social que você gostaria que ficasse em destaque no card de informações:
                        </div>
                        <div className="select-networks">
                            {networksSelectOtions.map((o, index) => (
                                <div
                                    key={index}
                                    className={networksSelected.includes(o.alias) ? 'item selected' : 'item'}
                                    onClick={() => onClickNetworkOption(o.alias)}
                                >
                                    <img src={o.icon} alt={o.alias} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 field-col-align">
                        <div className="label-outside">Há quanto tempo sua iniciativa existe? Insira o ano de fundação.</div>
                        <Controller
                            name="yearFoundation"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='AAAA'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Tempo</label>
                                        <input ref={withMask('9999')} />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('yearFoundation', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />
                    </div>

                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="motivatingPublic"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Qual é o público motivador da iniciativa? *'
                                        placeholder='Selecione ...'
                                        className={errors.motivatingPublic && 'field-error'}
                                        value={value}
                                        options={motivatingPublicOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.motivatingPublic && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>

                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="socialImpactDimension"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Qual a dimensão que melhor descreve o impacto social produzido pela sua iniciativa? *'
                                        placeholder='Selecione ...'
                                        className={errors.socialImpactDimension && 'field-error'}
                                        value={value}
                                        options={socialImpactDimensionOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            setSocialImpactDimensionOtherShow(value === 13)
                                            setValue('socialImpactDimensionOther', '')
                                        }}
                                    />
                                    {errors.socialImpactDimension && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>

                    {socialImpactDimensionOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="socialImpactDimensionOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('socialImpactDimensionOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }

                    {userData.tipo_organizacao_id === 1 &&
                        <div className="col-md-4 field-col-align">
                            <label className="label-form-default">Indique se sua iniciativa possui algum título ou qualificação e qual:</label>
                            <div>
                                <Dropdown
                                    placeholder='Selecione ...'
                                    fluid
                                    multiple
                                    search
                                    selection
                                    clearable
                                    options={hasTitleQualificationOptions}
                                    value={hasTitleQualificationSelected}
                                    onChange={function (e, { value }) {
                                        setHasTitleQualificationSelected(value as number[])
                                        setValue('hasTitleQualification', value as number[])
                                        setHasTitleQualificationOtherShow((value as number[]).includes(9))

                                        if (!(value as number[]).includes(9)) {
                                            setValue('hasTitleQualificationOther', '')
                                        }
                                    }}
                                >
                                    <Dropdown.Menu>
                                        {hasTitleQualificationOptions.slice(1).map(op => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    let resValues = []

                                                    if (hasTitleQualificationSelected.includes(op.key as number)) {
                                                        resValues = hasTitleQualificationSelected.filter((item: number) => item !== op.key)
                                                    } else {
                                                        resValues = [...hasTitleQualificationSelected, op.key]
                                                    }

                                                    setHasTitleQualificationSelected(resValues as number[])
                                                    setValue('hasTitleQualification', resValues as number[])
                                                    setHasTitleQualificationOtherShow((resValues as number[]).includes(9))

                                                    if (!(resValues as number[]).includes(9)) {
                                                        setValue('hasTitleQualificationOther', '')
                                                    }
                                                }}
                                            >
                                                <Checkbox
                                                    label={op.text}
                                                    checked={hasTitleQualificationSelected.includes(op.key as number)}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    }

                    {/* {userData.tipo_organizacao_id === 1 &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="hasTitleQualification"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Indique se sua iniciativa possui algum título ou qualificação e qual:'
                                            placeholder='Selecione ...'
                                            value={value}
                                            options={hasTitleQualificationOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setHasTitleQualificationOtherShow(value === 9)
                                                setValue('hasTitleQualificationOther', '')
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    } */}

                    {hasTitleQualificationOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="hasTitleQualificationOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('hasTitleQualificationOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }

                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="percentWomen"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Quantos % dos colaboradores da iniciativa são mulheres?'
                                        placeholder='Selecione ...'
                                        value={value}
                                        options={percentWomenOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>

                    {[1, 4].includes(userData?.tipo_organizacao_id as number) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="hasPoliticalParty"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='A iniciativa é ligada a algum partido político ou algum de seus representantes/funcionários exercem algum cargo político/público? *'
                                            placeholder='Selecione ...'
                                            className={errors.hasPoliticalParty && 'field-error'}
                                            value={value}
                                            options={defaultOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.hasPoliticalParty && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {[1, 4].includes(userData?.tipo_organizacao_id as number) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="hasChurchAffiliation"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='A iniciativa ou algum de seus representantes/funcionários tem afiliação com alguma igreja? *'
                                            placeholder='Selecione ...'
                                            className={errors.hasChurchAffiliation && 'field-error'}
                                            value={value}
                                            options={defaultOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.hasChurchAffiliation && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {[2, 4].includes(userData?.tipo_organizacao_id as number) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="marketSegment"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Qual é seu segmento de mercado? *'
                                            placeholder='Selecione ...'
                                            className={errors.marketSegment && 'field-error'}
                                            value={value}
                                            options={marketSegmentOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setMarketSegmentOtherShow(value === 31)
                                                setValue('marketSegmentOther', '')
                                            }}
                                        />
                                        {errors.marketSegment && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {marketSegmentOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="marketSegmentOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('marketSegmentOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }

                    {((userData?.tipo_organizacao_id === 4 && firstInfoData.whatIdo === 2) || (userData?.tipo_organizacao_id === 2 && firstInfoData.initiativeNature === 7)) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="productType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Qual o tipo do seu produto? *'
                                            placeholder='Selecione ...'
                                            className={errors.productType && 'field-error'}
                                            value={value}
                                            options={productTypeOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setProductTypeOtherShow(value === 7)
                                                setValue('productTypeOther', '')
                                            }}
                                        />
                                        {errors.productType && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {productTypeOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="productTypeOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('productTypeOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }

                    {((userData?.tipo_organizacao_id === 4 && firstInfoData.whatIdo === 2) || (userData?.tipo_organizacao_id === 2 && firstInfoData.initiativeNature === 7)) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="buyer"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Para quem você vende seus produtos?'
                                            placeholder='Selecione ...'
                                            value={value}
                                            options={buyerOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setBuyerOtherShow(value === 6)
                                                setValue('buyerOther', '')
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    }

                    {buyerOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="buyerOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('buyerOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }

                    {(userData?.tipo_organizacao_id === 4 && firstInfoData.whatIdo === 1) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="serviceType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Qual o tipo do seu serviço? *'
                                            placeholder='Selecione ...'
                                            className={errors.serviceType && 'field-error'}
                                            value={value}
                                            options={serviceTypeOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setServiceType(value)
                                                setServiceTypeOtherShow(value === 1210)
                                                setValue('serviceTypeOther', '')
                                            }}
                                        />
                                        {errors.serviceType && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {serviceTypeOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="serviceTypeOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('serviceTypeOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }


                    {(userData?.tipo_organizacao_id === 4 && serviceType === 100 && firstInfoData.whatIdo === 1) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="whichArt"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Qual é a sua arte?'
                                            placeholder='Selecione ...'
                                            value={value}
                                            options={whichArtOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setWhichArtOtherShow(value === 4)
                                                setValue('whichArtOther', '')
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    }

                    {whichArtOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="whichArtOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('whichArtOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }

                    {(userData?.tipo_organizacao_id === 4 && serviceType === 100 && firstInfoData.whatIdo === 1) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="hasDrt"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Possui DRT? *'
                                            placeholder='Selecione ...'
                                            className={errors.hasDrt && 'field-error'}
                                            value={value}
                                            options={defaultOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.hasDrt && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {(userData?.tipo_organizacao_id === 4 && firstInfoData.whatIdo === 1) &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="serviceBuyer"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Para quem você presta serviço?'
                                            placeholder='Selecione ...'
                                            value={value}
                                            options={serviceBuyerOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                                setServiceBuyerOtherShow(value === 6)
                                                setValue('serviceBuyerOther', '')
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    }

                    {serviceBuyerOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="serviceBuyerOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('serviceBuyerOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }
                </div>

                {[1, 4].includes(userData?.tipo_organizacao_id as number) &&
                    <div className="row">
                        <div className="fw-600 mb-16 fc-black">
                            Assinale os documentos que sua iniciativa possui: (marque todas que se aplicam)
                        </div>

                        <Controller
                            name="documents"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    {documentsOptions.map((o, index) => (
                                        <div className="col-md-12" key={index}>
                                            <Form.Checkbox
                                                key={o.key}
                                                className="mb-0"
                                                label={o.text}
                                                checked={defaultCheckedDocuments(o.key)}
                                                onChange={function (e, { checked }) {
                                                    onChange(onChangeDocuments(checked, o.key))
                                                }}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        />
                    </div>
                }

                {userData?.tipo_organizacao_id === 1 && documents.includes(13) &&
                    <div className="row">
                        <div className="fw-600 mb-16 fc-black">
                            Em quais leis de incentivo sua iniciativa tem projetos aprovados? *
                    </div>

                        <Controller
                            name="approvedProjectLaws"
                            control={control}
                            rules={{ required: !approvedProjectLaws.length }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    {approvedProjectLawsOptions.map((o, index) => (
                                        <div className="col-md-4" key={index}>
                                            <Form.Checkbox
                                                key={o.key}
                                                className="mb-0"
                                                label={o.text}
                                                checked={defaultCheckedApprovedProjectLaws(o.key)}
                                                onChange={function (e, { checked }) {
                                                    onChange(onChangeApprovedProjectLaws(checked, o.key))
                                                }}
                                            />
                                        </div>
                                    ))}

                                    {approvedProjectLawsOtherShow &&
                                        <div className="col-md-4">
                                            <Controller
                                                name="approvedProjectLawsOther"
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Form.Input
                                                            fluid
                                                            size="huge"
                                                            label='s'
                                                            className="text-white"
                                                            placeholder='Outro (especificar)'
                                                            value={value}
                                                            onChange={function (e, { value }) {
                                                                onChange(value)
                                                            }}
                                                        >
                                                            <input />
                                                            <div
                                                                className="img-icon flex-cc"
                                                                onClick={() => {
                                                                    onChange(undefined)
                                                                    setValue('approvedProjectLawsOther', '')
                                                                }}>
                                                                <img src={closeInputIcon} alt="close-input" />
                                                            </div>
                                                        </Form.Input>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    }

                                    {errors.approvedProjectLaws?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                }

                {[1, 4].includes(userData?.tipo_organizacao_id as number) &&
                    <div className="row">
                        <div className="fw-600 mb-16 fc-black">
                            Assinale se sua iniciativa possui: (marque todas que se aplicam) *
                        </div>

                        <Controller
                            name="initiativeHas"
                            control={control}
                            rules={{ required: !initiativeHas.length }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    {initiativeHasOptions.map((o, index) => (
                                        <div className="col-md-6" key={index}>
                                            <Form.Checkbox
                                                key={o.key}
                                                className="mb-0"
                                                label={o.text}
                                                checked={defaultCheckedInitiativeHas(o.key)}
                                                onChange={function (e, { checked }) {
                                                    onChange(onChangeInitiativeHas(checked, o.key))
                                                }}
                                            />
                                        </div>
                                    ))}
                                    {errors.initiativeHas?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                }

                <div className="row">
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="hasMission"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='A iniciativa tem uma missão definida? *'
                                        placeholder='Selecione ...'
                                        className={errors.hasMission && 'field-error'}
                                        value={value}
                                        options={defaultOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.hasMission && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="hasVision"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='A iniciativa tem uma visão definida? *'
                                        placeholder='Selecione ...'
                                        className={errors.hasVision && 'field-error'}
                                        value={value}
                                        options={defaultOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.hasVision && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="passAudit"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='A iniciativa passa por auditoria? *'
                                        placeholder='Selecione ...'
                                        className={errors.passAudit && 'field-error'}
                                        value={value}
                                        options={defaultOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.passAudit && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                </div>

                <div className="footer">
                    <hr />
                    <div className="btn-actions f-right">
                        <Button
                            text="Anterior"
                            className="radius-100 btn-outline-gray mr-32"
                            onClick={() => setCurrentPage('first-information')}
                        />
                        {showNextRegisterButton(updateAction) &&
                            <Button
                                type="submit"
                                text="Próxima"
                                className="radius-100"
                                onClick={() => Object.keys(errors).length && handleScroll()}
                            />
                        }
                    </div>
                </div>
            </Form>
        </Container>
    )
}