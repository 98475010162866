import addIcon from '../../../assets/img/icons/add-green.svg'
import trashIcon from '../../../assets/img/icons/trash-red.svg'
import { Container } from './style'

type Props = {
    targetModalName: string
    className?: string
    type: 'create' | 'delete'
    title: string,
    escClick?: boolean,
    outsideClick?: boolean,
    showTopBtnclone?: boolean,
    hideFooter?: boolean,
    btnFirstText?: string,
    btnSecondText?: string,
    children: any,
    elemRef?: React.MutableRefObject<any>,
    confirmAction?: () => void
}

export const Modal = ({
    targetModalName,
    escClick,
    outsideClick,
    className,
    type,
    title,
    btnFirstText,
    btnSecondText,
    children,
    confirmAction,
    elemRef,
    hideFooter,
    showTopBtnclone
}: Props): JSX.Element => {
    return (
        <Container>
            <div
                className={`modal fade${className ? ' ' + className : ''}`}
                id={targetModalName}
                data-bs-backdrop={outsideClick ? undefined : 'static'}
                data-bs-keyboard={escClick || false}
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                ref={elemRef}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="icon">
                                <img src={type === 'create' ? addIcon : trashIcon} alt="add-green" />
                            </div>
                            <h1 className="modal-title" id="staticBackdropLabel">{title}</h1>
                            {showTopBtnclone &&
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            }
                        </div>

                        <div className="modal-body">
                            {children}
                        </div>

                        {!hideFooter &&
                            <div className="modal-footer">
                                <button type="button" className="btn-footer btn-first" data-bs-dismiss="modal">{btnFirstText || 'Cancelar'}</button>
                                <button type="button" className={`btn-footer btn-${type}`} onClick={confirmAction}>{btnSecondText}</button>
                            </div>}
                    </div>
                </div>
            </div>
        </Container>
    )
}