import { Button } from "../Button"
import { Container } from "./style"
import addIcon from "../../../assets/img/icons/add.svg"
import boxArrowInRight from "../../../assets/img/icons/box-arrow-in-right.svg"
import houseDoorIcon from "../../../assets/img/icons/house-door-fill.svg"
import gearIcon from '../../../assets/img/icons/gear-fill.svg';
import geoAltIcon from '../../../assets/img/icons/geo-alt.svg';
import buildingIcon from '../../../assets/img/icons/building.svg';
import { useNavigate } from "react-router"
import { logout } from "../../../api/authentication"
import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalContext"

type Props = {
    logo?: string
    userImg?: string
    isAdminPage?: boolean
    showRegisterBtn?: boolean,
    setActionBooelan?: (value: boolean) => void
}

const URL = 'https://api.perifasul.org/public/'

export const Navbar = ({ logo, userImg, showRegisterBtn, setActionBooelan }: Props): JSX.Element => {
    const { setIsLoading, isAuthenticated, userData, setMenuItemActivated } = useContext(GlobalContext)
    const navigate = useNavigate()

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const doLogout = (): void => {
        setIsLoading(true)
        logout().then(result => {
            setIsLoading(false)
            localStorage.clear()
            goToPage('/')
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const startRegister = (): void => {
        if (!localStorage.getItem('first-info')) {
            localStorage.setItem('first-info', JSON.stringify({}))
        }

        goToPage('/mapa/inscricao')
    }

    return (
        <Container>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <img className="logo" src={logo} alt="logo" onClick={() => goToPage('/')} />
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <div className="me-auto"></div>
                        <div className="flex-cc">
                            {(showRegisterBtn && !isAuthenticated) &&
                                <div>
                                    <Button
                                        text={'Inscreva sua Iniciativa'}
                                        icon={addIcon}
                                        height="42px"
                                        hasShadow
                                        className="btn-white radius-8 float-left btn-register"
                                        onClick={() => startRegister()}
                                    />
                                </div>
                            }
                            <div className="d-flex float-right">
                                {isAuthenticated ?
                                    <>
                                        <span style={{ cursor: 'unset' }} className="login-text flex-cc">
                                            {userData.name}
                                        </span>

                                        <div className="dropdown dropdown-img">
                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={userData?.logo ? `${URL + userData.logo}` : userImg} alt="user-image" />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li
                                                    className="d-flex"
                                                    onClick={e => {
                                                        setMenuItemActivated('iniciativas')
                                                        goToPage('/')
                                                    }}
                                                >
                                                    <img src={houseDoorIcon} alt="user-image" />
                                                    <a className="dropdown-item" href="#">Home</a>
                                                </li>
                                                <li
                                                    className="d-flex"
                                                    onClick={e => {
                                                        setMenuItemActivated('iniciativas')
                                                        setActionBooelan?.(false)
                                                        goToPage('/admin')
                                                    }}
                                                >
                                                    <img src={buildingIcon} alt="user-image" />
                                                    <a className="dropdown-item" href="#">Portal</a>
                                                </li>
                                                <li className="d-flex" onClick={e => goToPage('/mapa')}>
                                                    <img src={geoAltIcon} alt="user-image" />
                                                    <a className="dropdown-item" href="#">Mapa</a>
                                                </li>
                                                <li
                                                    className="d-flex"
                                                    onClick={() => {
                                                        setMenuItemActivated('config')
                                                        goToPage('/admin')
                                                    }}
                                                >
                                                    <img src={gearIcon} alt="user-image" />
                                                    <a className="dropdown-item" href="#">Configurações</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </> :
                                    <>
                                        <span className="login-text flex-cc" onClick={e => goToPage('/login')}>
                                            Login
                                    </span>
                                    </>
                                }

                                {(isAuthenticated) &&
                                    <span className="leave-text flex-cc" onClick={() => doLogout()}>
                                        <img className="icon" src={boxArrowInRight} alt="box-arrow-in-right" /> Sair
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </Container >
    )
}