import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext";
import { Container } from "../Toast/style"

type Props = {
    text?: string
}

export const Toast = ({ text }: Props): JSX.Element => {
    const [show, setShow] = useState(false);
    const [progressShow, setProgressShow] = useState(false);
    const { setDataToast } = useContext(GlobalContext)

    useEffect(() => {
        setShow(true)

        setTimeout(() => {
            setShow(false)
            setDataToast({ show: false, text: '' })
        }, 8000);

        setTimeout(() => {
            setProgressShow(true)
        }, 1000);
    }, [])
    return (
        <Container>
            <div
                className={`toast ${show ? 'show' : ''} align-items-center border-0`}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
            >
                <div className={`progress ${progressShow ? 'show' : ''}`}></div>

                <div className="d-flex">
                    <div className="toast-body">
                        {text || 'Hello, world! This is a toast message.'}
                    </div>
                    <button
                        type="button"
                        className="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    ></button>
                </div>
            </div>
        </Container>
    )
}