import { BrowserRouter as Router, Routes, Route, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Navbar } from '../../../designSystems/components/Navbar';
import logo from '../../../assets/img/logo.svg';
import userImg from '../../../assets/img/user-imag.svg';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';

export const MapRegisterPage = (): JSX.Element => {
    const location = useLocation()
    const { setUserData, setUserId } = useContext(GlobalContext)

    const showRegisterBtn = (): boolean => {
        return location.pathname === '/mapa'
    }

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = (): void => {
        let user: any = JSON.parse(localStorage.getItem('usuario') || localStorage.getItem('authentication-admin') || '{}') || null;

        if (Object.keys(user).length) {
            setUserData(user.user)
            setUserId(user.user.id)
        }
    }

    return (
        <>
            <Navbar
                logo={logo}
                userImg={userImg}
                showRegisterBtn={showRegisterBtn()}
            />

            <div style={{ paddingTop: 56 }}>
                <Outlet />
            </div>
        </>
    )
}