import { Variables } from './../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
position: fixed;
top: 24px;
right: 24px;
z-index: 1111111;

.toast {
    background: ${Variables.gray};
    padding-top: 5px;

    &.show {
        .progress {
            width: 0%;
            transition: all 8s ease;

            &.show {
                width: 100%;
            }
        }
    }

    .progress {
        height: 7px;
        background: ${Variables.yellow};
        position: absolute;
        top: 0px;
    }

    .toast-body {
        color: #FFF;
    }
}

@media (max-width: 575.98px) {
    left: 10px;
    right: 10px;

    .toast {
        width: 100%;
    }
}
`