import { api, getConfigApi } from './../../services/api'

export const URL_PUBLIC = 'https://api.perifasul.org/public/'

export const getFrontHome = async (): Promise<unknown> => {
    return await api.get(`portal/front-cover`)
}

export const getAddress = async (cep: string): Promise<unknown> => {
    return await api.get(`get-address/${cep}`)
}

export const getStepGranted = async (params?: any): Promise<any> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get(`step-granted`, args)
}

export const updateStepGranted = async (step: number, userId: number): Promise<unknown> => {
    return await api.post(`step-granted`, { step: step, user_id: userId, status: 0 }, getConfigApi())
}