import { AdProps } from './../../models/AdProps'
import { OtherAccountProps } from './../../models/OtherAccountProps'
import { api, getConfigApi, getConfigApiNoAccept } from './../../services/api'

export interface passwordDataProps {
    current_password: string
    password: string
    password_confirmation: string
}

// Initiatives

export const updateInitiativeStatus = async (data: any): Promise<unknown> => {
    return await api.patch('portal/status', data, getConfigApi())
}

export const getAdminInitiatives = async (): Promise<unknown> => {
    return await api.get('portal/initiatives', getConfigApi())
}

export const deleteInitiative = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApiNoAccept() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.post('delete', {}, args)
}

// Config

export const getAdminConfig = async (): Promise<unknown> => {
    return await api.get('portal/config', getConfigApi())
}

export const updateAdminConfig = async (data: any): Promise<unknown> => {
    return await api.patch('portal/update-config', data, getConfigApi())
}

export const updatePassword = async (data: passwordDataProps): Promise<unknown> => {
    return await api.post('portal/change-password', data, getConfigApi())
}

export const addUserConfigPortal = async (data: OtherAccountProps): Promise<unknown> => {
    return await api.post('portal/add-user', data, getConfigApi())
}

export const deleteUserConfigPortal = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.post(`delete`, {}, args)
}

export const getUseTerm = async (): Promise<unknown> => {
    return await api.get('portal/use-term', getConfigApi())
}

export const updateUseTerm = async (data: any): Promise<unknown> => {
    return await api.post('portal/use-term', data, getConfigApi())
}

// Ads

export const listAds = async (): Promise<unknown> => {
    return await api.get('portal/list-advertisement', getConfigApi())
}

export const addAdPortal = async (data: AdProps): Promise<unknown> => {
    return await api.post('portal/add-advertisement', data, getConfigApi())
}