import { useContext, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import * as L from "leaflet";
import { Dropdown, Form, Menu } from 'semantic-ui-react'
import { Container } from './style'
import thematicIcon1 from "../../..//assets/img/icons/thematic/thematic-01.svg"
import thematicIcon2 from "../../..//assets/img/icons/thematic/thematic-02.svg"
import thematicIcon3 from "../../..//assets/img/icons/thematic/thematic-03.svg"
import thematicIcon4 from "../../..//assets/img/icons/thematic/thematic-04.svg"
import thematicIcon5 from "../../..//assets/img/icons/thematic/thematic-05.svg"
import thematicIcon6 from "../../..//assets/img/icons/thematic/thematic-06.svg"
import thematicIcon7 from "../../..//assets/img/icons/thematic/thematic-07.svg"
import thematicIcon8 from "../../..//assets/img/icons/thematic/thematic-08.svg"
import thematicIcon9 from "../../..//assets/img/icons/thematic/thematic-09.svg"
import thematicIcon10 from "../../..//assets/img/icons/thematic/thematic-10.svg"
import thematicIcon11 from "../../..//assets/img/icons/thematic/thematic-11.svg"
import thematicIcon12 from "../../..//assets/img/icons/thematic/thematic-12.svg"
import thematicIcon13 from "../../..//assets/img/icons/thematic/thematic-13.svg"
import thematicIcon14 from "../../..//assets/img/icons/thematic/thematic-14.svg"
import thematicIcon15 from "../../..//assets/img/icons/thematic/thematic-15.svg"
import thematicIcon16 from "../../..//assets/img/icons/thematic/thematic-16.svg"
import thematicIcon17 from "../../..//assets/img/icons/thematic/thematic-17.svg"
import thematicIcon18 from "../../..//assets/img/icons/thematic/thematic-18.svg"
import thematicIcon19 from "../../..//assets/img/icons/thematic/thematic-19.svg"
import thematicIcon20 from "../../..//assets/img/icons/thematic/thematic-20.svg"
import thematicIcon21 from "../../..//assets/img/icons/thematic/thematic-21.svg"
import thematicIcon22 from "../../..//assets/img/icons/thematic/thematic-22.svg"
import thematicIcon23 from "../../..//assets/img/icons/thematic/thematic-23.svg"
import thematicIcon24 from "../../..//assets/img/icons/thematic/thematic-24.svg"
import thematicIcon25 from "../../..//assets/img/icons/thematic/thematic-25.svg"
import thematicIcon26 from "../../..//assets/img/icons/thematic/thematic-26.svg"
import thematicIcon27 from "../../..//assets/img/icons/thematic/thematic-27.svg"
import thematicIcon28 from "../../..//assets/img/icons/thematic/thematic-28.svg"
import thematicIcon29 from "../../..//assets/img/icons/thematic/thematic-29.svg"
import pinOng1 from "../../..//assets/img/icons/pin-ong-1.svg"
import pinOng2 from "../../..//assets/img/icons/pin-ong-2.svg"
import pinOng3 from "../../..//assets/img/icons/pin-ong-3.svg"
import pinOng4 from "../../..//assets/img/icons/pin-ong-4.svg"
import pinOng5 from "../../..//assets/img/icons/pin-ong-5.svg"
import pinOng6 from "../../..//assets/img/icons/pin-ong-6.svg"
import pinOng7 from "../../..//assets/img/icons/pin-ong-7.svg"
import pinOng8 from "../../..//assets/img/icons/pin-ong-8.svg"
import pinOng9 from "../../..//assets/img/icons/pin-ong-9.svg"
import pinOng10 from "../../..//assets/img/icons/pin-ong-10.svg"
import pinOng11 from "../../..//assets/img/icons/pin-ong-11.svg"
import pinOng12 from "../../..//assets/img/icons/pin-ong-12.svg"
import pinOng13 from "../../..//assets/img/icons/pin-ong-13.svg"
import pinOng14 from "../../..//assets/img/icons/pin-ong-14.svg"
import pinOng15 from "../../..//assets/img/icons/pin-ong-15.svg"
import pinOng16 from "../../..//assets/img/icons/pin-ong-16.svg"
import pinOng17 from "../../..//assets/img/icons/pin-ong-17.svg"
import pinOng18 from "../../..//assets/img/icons/pin-ong-18.svg"
import pinOng19 from "../../..//assets/img/icons/pin-ong-19.svg"
import pinOng20 from "../../..//assets/img/icons/pin-ong-20.svg"
import pinOng21 from "../../..//assets/img/icons/pin-ong-21.svg"
import pinOng22 from "../../..//assets/img/icons/pin-ong-22.svg"
import pinOng23 from "../../..//assets/img/icons/pin-ong-23.svg"
import pinOng24 from "../../..//assets/img/icons/pin-ong-24.svg"
import pinOng25 from "../../..//assets/img/icons/pin-ong-25.svg"
import pinOng26 from "../../..//assets/img/icons/pin-ong-26.svg"
import pinOng27 from "../../..//assets/img/icons/pin-ong-27.svg"
import pinOng28 from "../../..//assets/img/icons/pin-ong-28.svg"
import pinOng29 from "../../..//assets/img/icons/pin-ong-29.svg"
import pinEmp1 from "../../../assets/img/icons/pin-emp-1.svg"
import pinEmp2 from "../../..//assets/img/icons/pin-emp-2.svg"
import pinEmp3 from "../../..//assets/img/icons/pin-emp-3.svg"
import pinEmp4 from "../../..//assets/img/icons/pin-emp-4.svg"
import pinEmp5 from "../../..//assets/img/icons/pin-emp-5.svg"
import pinEmp6 from "../../..//assets/img/icons/pin-emp-6.svg"
import pinEmp7 from "../../..//assets/img/icons/pin-emp-7.svg"
import pinEmp8 from "../../..//assets/img/icons/pin-emp-8.svg"
import pinEmp9 from "../../..//assets/img/icons/pin-emp-9.svg"
import pinEmp10 from "../../..//assets/img/icons/pin-emp-10.svg"
import pinEmp11 from "../../..//assets/img/icons/pin-emp-11.svg"
import pinEmp12 from "../../..//assets/img/icons/pin-emp-12.svg"
import pinEmp13 from "../../..//assets/img/icons/pin-emp-13.svg"
import pinEmp14 from "../../..//assets/img/icons/pin-emp-14.svg"
import pinEmp15 from "../../..//assets/img/icons/pin-emp-15.svg"
import pinEmp16 from "../../..//assets/img/icons/pin-emp-16.svg"
import pinEmp17 from "../../..//assets/img/icons/pin-emp-17.svg"
import pinEmp18 from "../../..//assets/img/icons/pin-emp-18.svg"
import pinEmp19 from "../../..//assets/img/icons/pin-emp-19.svg"
import pinEmp20 from "../../..//assets/img/icons/pin-emp-20.svg"
import pinEmp21 from "../../..//assets/img/icons/pin-emp-21.svg"
import pinEmp22 from "../../..//assets/img/icons/pin-emp-22.svg"
import pinEmp23 from "../../..//assets/img/icons/pin-emp-23.svg"
import pinEmp24 from "../../..//assets/img/icons/pin-emp-24.svg"
import pinEmp25 from "../../..//assets/img/icons/pin-emp-25.svg"
import pinEmp26 from "../../..//assets/img/icons/pin-emp-26.svg"
import pinEmp27 from "../../..//assets/img/icons/pin-emp-27.svg"
import pinEmp28 from "../../..//assets/img/icons/pin-emp-28.svg"
import pinEmp29 from "../../..//assets/img/icons/pin-emp-29.svg"
import pinEq1 from "../../..//assets/img/icons/pin-eq-1.svg"
import pinEq2 from "../../..//assets/img/icons/pin-eq-2.svg"
import pinEq3 from "../../..//assets/img/icons/pin-eq-3.svg"
import pinEq4 from "../../..//assets/img/icons/pin-eq-4.svg"
import pinEq5 from "../../..//assets/img/icons/pin-eq-5.svg"
import pinEq6 from "../../..//assets/img/icons/pin-eq-6.svg"
import pinEq7 from "../../..//assets/img/icons/pin-eq-7.svg"
import pinEq8 from "../../..//assets/img/icons/pin-eq-8.svg"
import pinEq9 from "../../..//assets/img/icons/pin-eq-9.svg"
import pinEq10 from "../../..//assets/img/icons/pin-eq-10.svg"
import pinEq11 from "../../..//assets/img/icons/pin-eq-11.svg"
import pinEq12 from "../../..//assets/img/icons/pin-eq-12.svg"
import pinEq13 from "../../..//assets/img/icons/pin-eq-13.svg"
import pinEq14 from "../../..//assets/img/icons/pin-eq-14.svg"
import pinEq15 from "../../..//assets/img/icons/pin-eq-15.svg"
import pinEq16 from "../../..//assets/img/icons/pin-eq-16.svg"
import pinEq17 from "../../..//assets/img/icons/pin-eq-17.svg"
import pinEq18 from "../../..//assets/img/icons/pin-eq-18.svg"
import pinEq19 from "../../..//assets/img/icons/pin-eq-19.svg"
import pinEq20 from "../../..//assets/img/icons/pin-eq-20.svg"
import pinEq21 from "../../..//assets/img/icons/pin-eq-21.svg"
import pinEq22 from "../../..//assets/img/icons/pin-eq-22.svg"
import pinEq23 from "../../..//assets/img/icons/pin-eq-23.svg"
import pinEq24 from "../../..//assets/img/icons/pin-eq-24.svg"
import pinEq25 from "../../..//assets/img/icons/pin-eq-25.svg"
import pinEq26 from "../../..//assets/img/icons/pin-eq-26.svg"
import pinEq27 from "../../..//assets/img/icons/pin-eq-27.svg"
import pinEq28 from "../../..//assets/img/icons/pin-eq-28.svg"
import pinEq29 from "../../..//assets/img/icons/pin-eq-29.svg"
import pinPre1 from "../../..//assets/img/icons/pin-pre-1.svg"
import pinPre2 from "../../..//assets/img/icons/pin-pre-2.svg"
import pinPre3 from "../../..//assets/img/icons/pin-pre-3.svg"
import pinPre4 from "../../..//assets/img/icons/pin-pre-4.svg"
import pinPre5 from "../../..//assets/img/icons/pin-pre-5.svg"
import pinPre6 from "../../..//assets/img/icons/pin-pre-6.svg"
import pinPre7 from "../../..//assets/img/icons/pin-pre-7.svg"
import pinPre8 from "../../..//assets/img/icons/pin-pre-8.svg"
import pinPre9 from "../../..//assets/img/icons/pin-pre-9.svg"
import pinPre10 from "../../..//assets/img/icons/pin-pre-10.svg"
import pinPre11 from "../../..//assets/img/icons/pin-pre-11.svg"
import pinPre12 from "../../..//assets/img/icons/pin-pre-12.svg"
import pinPre13 from "../../..//assets/img/icons/pin-pre-13.svg"
import pinPre14 from "../../..//assets/img/icons/pin-pre-14.svg"
import pinPre15 from "../../..//assets/img/icons/pin-pre-15.svg"
import pinPre16 from "../../..//assets/img/icons/pin-pre-16.svg"
import pinPre17 from "../../..//assets/img/icons/pin-pre-17.svg"
import pinPre18 from "../../..//assets/img/icons/pin-pre-18.svg"
import pinPre19 from "../../..//assets/img/icons/pin-pre-19.svg"
import pinPre20 from "../../..//assets/img/icons/pin-pre-20.svg"
import pinPre21 from "../../..//assets/img/icons/pin-pre-21.svg"
import pinPre22 from "../../..//assets/img/icons/pin-pre-22.svg"
import pinPre23 from "../../..//assets/img/icons/pin-pre-23.svg"
import pinPre24 from "../../..//assets/img/icons/pin-pre-24.svg"
import pinPre25 from "../../..//assets/img/icons/pin-pre-25.svg"
import pinPre26 from "../../..//assets/img/icons/pin-pre-26.svg"
import pinPre27 from "../../..//assets/img/icons/pin-pre-27.svg"
import pinPre28 from "../../..//assets/img/icons/pin-pre-28.svg"
import pinPre29 from "../../..//assets/img/icons/pin-pre-29.svg"
import buildingIcon from '../../../assets/img/icons/building.svg';
import { Modal } from '../../../designSystems/components/Modal'
import { ModalFilterContent } from '../../../designSystems/components/ModalFilterContent'
import { Button } from '../../../designSystems/components/Button'
import { ModalFilter } from '../../../designSystems/components/ModalFilter'
import { getMapData, searchInitiatives } from '../../../api/map'
import { GlobalContext } from '../../../context/GlobalContext'
import { InitiativeSummaryCard } from '../../components/InitiativeSummaryCard'
import { cityActionsOptions, districtLocaleOptions, providersAvailableOptions, offerTerritoryAvailableOptions, youAreOptions, odsOptions, publicOptions } from '../../../api/utils/fieldsOptions'
import _ from 'lodash'
import { CLIENT_RENEG_LIMIT } from 'tls';

const thematicOptions = [
    { key: 100, value: 100, text: 'Ações Afirmativas: raça, gênero e protagonismo jovem', icon: thematicIcon1 },
    { key: 200, value: 200, text: 'Advocacy & Incidêcia Politica', icon: thematicIcon2 },
    { key: 300, value: 300, text: 'Arte & Cultura', icon: thematicIcon3 },
    { key: 400, value: 400, text: 'Assistência Social', icon: thematicIcon4 },
    { key: 500, value: 500, text: 'Bem Viver & Qualidade de Vida', icon: thematicIcon5 },
    { key: 600, value: 600, text: 'Comunicação & Marketing', icon: thematicIcon6 },
    { key: 700, value: 700, text: 'Defesa de direitos da população LGBTQIAP+', icon: thematicIcon7 },
    { key: 800, value: 800, text: 'Defesa de direitos da população negra', icon: thematicIcon8 },
    { key: 900, value: 900, text: 'Defesa de direitos das Mulheres', icon: thematicIcon9 },
    { key: 1010, value: 1010, text: 'Defesa de direito dos povos indígenas', icon: thematicIcon10 },
    { key: 1110, value: 1110, text: 'Desenvolvimento Comunitário', icon: thematicIcon11 },
    { key: 1210, value: 1210, text: 'Direito dos Animais', icon: thematicIcon12 },
    { key: 1310, value: 1310, text: 'Direitos humanos, justiça social e cidadania', icon: thematicIcon13 },
    { key: 1410, value: 1410, text: 'Economia criativa e solidária', icon: thematicIcon14 },
    { key: 1510, value: 1510, text: 'Educação', icon: thematicIcon15 },
    { key: 1610, value: 1610, text: 'Esporte & Saúde', icon: thematicIcon16 },
    { key: 1710, value: 1710, text: 'Habitação', icon: thematicIcon17 },
    { key: 1810, value: 1810, text: 'Inclusão Digital', icon: thematicIcon18 },
    { key: 1910, value: 1910, text: 'Inclusão Produtiva (empreendedorismo, empregabilidade e capacitação para o mercado de trabalho, geração de trabalho e renda)', icon: thematicIcon19 },
    { key: 2010, value: 2010, text: 'Justiça Racial', icon: thematicIcon20 },
    { key: 2110, value: 2110, text: 'Meio ambiente / Sustentabilidade', icon: thematicIcon21 },
    { key: 2210, value: 2210, text: 'Mobilidade Urbana', icon: thematicIcon22 },
    { key: 2310, value: 2310, text: 'Paz & Justiça', icon: thematicIcon23 },
    { key: 2410, value: 2410, text: 'PCD - Pessoas com Deficiência', icon: thematicIcon24 },
    { key: 2510, value: 2510, text: 'Pesquisa / Produção de Conhecimento', icon: thematicIcon25 },
    { key: 2610, value: 2610, text: 'Refugiados', icon: thematicIcon26 },
    { key: 2710, value: 2710, text: 'Saúde', icon: thematicIcon27 },
    { key: 2810, value: 2810, text: 'Tecnologias Sociais', icon: thematicIcon28 },
    { key: 2910, value: 2910, text: 'Outro (especifique)', icon: thematicIcon29 },
]

export const MapPage = (): JSX.Element => {
    const [shouldClear, setShouldClear] = useState(false)
    const [initiativeMapaData, setInitiativeMapaData] = useState<any>({})
    const [dataPage, setDataPage] = useState<any[]>([]);
    const [friendOptions, setFriendOptions] = useState<any[]>([]);
    const [offerTerritoryAvailable, setOfferTerritoryAvailable] = useState<number[]>([])
    const [providersAvailable, setProvidersAvailable] = useState<number[]>([])
    const [city, setCity] = useState<number[]>([])
    const [publicValues, setPublicValues] = useState<number[]>([])
    const [youAre, setYouAre] = useState<number[]>([])
    const [whatIdo, setWhatIdo] = useState<number[]>([])
    const [odsValues, setOdsValues] = useState<number[]>([])
    const [initiativeNature, setInitiativeNature] = useState<number[]>([])
    const [initiativeIs, setInitiativeIs] = useState<number[]>([])
    const [legalFormat, setLegalFormat] = useState<number[]>([])
    const [coverageArea, setCoverageArea] = useState<number[]>([])
    const [motivatingPublic, setMotivatingPublic] = useState<number[]>([])
    const [socialImpactDimension, setSocialImpactDimension] = useState<number[]>([])
    const [hasTitleQualification, setHasTitleQualification] = useState<number[]>([])
    const [percentWomen, setPercentWomen] = useState<number[]>([])
    const [marketSegment, setMarketSegment] = useState<number[]>([])
    const [whichArt, setWhichArt] = useState<number[]>([])
    const [documents, setDocuments] = useState<number[]>([])
    const [approvedProjectLaws, setApprovedProjectLaws] = useState<number[]>([])
    const [initiativeHas, setInitiativeHas] = useState<number[]>([])
    const [annualBudget, setAnnualBudget] = useState<number[]>([])
    const [monthlyBilling, setMonthlyBilling] = useState<number[]>([])
    const [areasExpertise, setAreasExpertise] = useState<number[]>([])
    const [initiativeName, setInitiativeName] = useState<any>({})
    const [district, setDistrict] = useState<number[]>([])
    const [hasCnpj, setHasCnpj] = useState<number | undefined>(undefined)
    const [productType, setProductType] = useState<number[]>([])
    const [serviceType, setServiceType] = useState<number[]>([])
    const [hasProvider, setHasProvider] = useState<number | undefined>(undefined)
    const [hasPoliticalParty, setHasPoliticalParty] = useState<number | undefined>(undefined)
    const [hasChurchAffiliation, setHasChurchAffiliation] = useState<number | undefined>(undefined)
    const [hasDrt, setHasDrt] = useState<number | undefined>(undefined)
    const [yearFoundation, setYearFoundation] = useState<string>('')
    const [hasMission, setHasMission] = useState<number | undefined>(undefined)
    const [hasVision, setHasVision] = useState<number | undefined>(undefined)
    const [passAudit, setPassAudit] = useState<number | undefined>(undefined)
    const [hasCounter, setHasCounter] = useState<number | undefined>(undefined)
    const [hasEquip, setHasEquip] = useState<number | undefined>(undefined)
    const [partnerForumNetwork, setPartnerForumNetwork] = useState<number | undefined>(undefined)
    const [isSouthPeripherySP, setIsSouthPeripherySP] = useState<number | undefined>(undefined)

    const [company, setCompany] = useState<any>({})

    const allFilterModalRef = useRef<HTMLElement>()

    const { setIsLoading, setDataToast, getErrorMessage, isMobile } = useContext(GlobalContext)

    const pinIcons: any = {
        1: {
            100: pinOng1, 200: pinOng2, 300: pinOng3, 400: pinOng4, 500: pinOng5, 600: pinOng6, 700: pinOng7, 800: pinOng8, 900: pinOng9, 1010: pinOng10, 1110: pinOng11, 1210: pinOng12, 1310: pinOng13, 1410: pinOng14, 1510: pinOng15, 1610: pinOng16, 1710: pinOng17, 1810: pinOng18, 1910: pinOng19, 2010: pinOng20, 2110: pinOng21, 2210: pinOng22, 2310: pinOng23, 2410: pinOng24, 2510: pinOng25, 2610: pinOng26, 2710: pinOng27, 2810: pinOng28, 2910: pinOng29
        },
        2: {
            100: pinEmp1, 200: pinEmp2, 300: pinEmp3, 400: pinEmp4, 500: pinEmp5, 600: pinEmp6, 700: pinEmp7, 800: pinEmp8, 900: pinEmp9, 1010: pinEmp10, 1110: pinEmp11, 1210: pinEmp12, 1310: pinEmp13, 1410: pinEmp14, 1510: pinEmp15, 1610: pinEmp16, 1710: pinEmp17, 1810: pinEmp18, 1910: pinEmp19, 2010: pinEmp20, 2110: pinEmp21, 2210: pinEmp22, 2310: pinEmp23, 2410: pinEmp24, 2510: pinEmp25, 2610: pinEmp26, 2710: pinEmp27, 2810: pinEmp28, 2910: pinEmp29
        },
        3: {
            100: pinEq1, 200: pinEq2, 300: pinEq3, 400: pinEq4, 500: pinEq5, 600: pinEq6, 700: pinEq7, 800: pinEq8, 900: pinEq9, 1010: pinEq10, 1110: pinEq11, 1210: pinEq12, 1310: pinEq13, 1410: pinEq14, 1510: pinEq15, 1610: pinEq16, 1710: pinEq17, 1810: pinEq18, 1910: pinEq19, 2010: pinEq20, 2110: pinEq21, 2210: pinEq22, 2310: pinEq23, 2410: pinEq24, 2510: pinEq25, 2610: pinEq26, 2710: pinEq27, 2810: pinEq28, 2910: pinEq29
        },
        4: {
            100: pinPre1, 200: pinPre2, 300: pinPre3, 400: pinPre4, 500: pinPre5, 600: pinPre6, 700: pinPre7, 800: pinPre8, 900: pinPre9, 1010: pinPre10, 1110: pinPre11, 1210: pinPre12, 1310: pinPre13, 1410: pinPre14, 1510: pinPre15, 1610: pinPre16, 1710: pinPre17, 1810: pinPre18, 1910: pinPre19, 2010: pinPre20, 2110: pinPre21, 2210: pinPre22, 2310: pinPre23, 2410: pinPre24, 2510: pinPre25, 2610: pinPre26, 2710: pinPre27, 2810: pinPre28, 2910: pinPre29
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        getMapData().then((response: any) => {
            setIsLoading(false)
            const resultData: any[] = response?.data?.data

            if (resultData.length) {
                setDataPage(resultData)
                setFriendOptions(resultData.map((item, index) => ({
                    key: index,
                    value: index,
                    text: item.initiativeName,
                    image: { avatar: true, src: buildingIcon },
                })))
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
        })
    }

    const getPin = (tipoOrganizacao: number, areasExpertiseValues: any) => {
        const areasList = JSON.parse(areasExpertiseValues)
        
        return L.icon({
            iconUrl: pinIcons[tipoOrganizacao][areasList.length ? areasList[0] : 1],
            iconSize: [43, 95], // size of the icon
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
    }
    const onChangeOfferTerritoryAvailable = (checked: boolean | undefined, key: number, filter?: boolean): void => {
        let offerTerritoryAvailableList: number[] = [...offerTerritoryAvailable];

        if (checked) {
            offerTerritoryAvailableList.push(key)
        } else {
            offerTerritoryAvailableList = offerTerritoryAvailableList.filter(k => k !== key)
        }

        setOfferTerritoryAvailable(offerTerritoryAvailableList)

        if (filter) {
            filtrar({ offerTerritoryAvailable: offerTerritoryAvailableList })
        }
    }

    const onChangeProvidersAvailable = (checked: boolean | undefined, key: number, filter?: boolean): void => {
        let providersAvailableList: number[] = [...providersAvailable];

        if (checked) {
            providersAvailableList.push(key)
        } else {
            providersAvailableList = providersAvailableList.filter(k => k !== key)
        }

        setProvidersAvailable(providersAvailableList)

        if (filter) {
            filtrar({ providersAvailable: providersAvailableList })
        }
    }

    const showInitiativeSummaryModal = (): void => {
        const cityName = cityActionsOptions.filter(item => item.value === company.cityActions)[0]?.text || ''
        const districtName = districtLocaleOptions.filter(item => (JSON.parse(company.districtLocale)).includes(item.value)).map(d => d.text || '').join(', ')

        setInitiativeMapaData({
            title: company.initiativeName,
            ods: company.ods ? JSON.parse(company.ods) : '',
            thematics: company.areasExpertise ? JSON.parse(company.areasExpertise) : '',
            description: company.initiativeDescription,
            offerTerritory: company.offerTerritoryAvailable ? JSON.parse(company.offerTerritoryAvailable) : '',
            offerTerritoryOthers: company.offerTerritoryOthers ? JSON.parse(company.offerTerritoryOthers) : '',
            providers: [1],
            localization: company.cityActions === 1 ? `${cityName} - ${districtName}` : `${cityName}`,
            networks: company.networks ? JSON.parse(company.networks) : '',
            email: company.email,
            facebook: company.facebook,
            instagram: company.instagram,
            linkedin: company.linkedin,
            tiktok: company.tiktok,
            website: company.website,
            youtube: company.youtube,
            logo: company.logo,
        })
    }

    const clearFilter = (): any => {
        let btnCloseElem: HTMLElement = allFilterModalRef?.current?.querySelector('.btn-first') as HTMLElement

        const dataForm = {
            initiativeName: '',
            tipo_organizacao_id: [],
            ods: [],
            whatIdo: [],
            initiativeNature: [],
            initiativeIs: [],
            legalFormat: [],
            coverageArea: [],
            motivatingPublic: [],
            socialImpactDimension: [],
            hasTitleQualification: [],
            percentWomen: [],
            marketSegment: [],
            hasPoliticalParty: undefined,
            hasChurchAffiliation: undefined,
            areasExpertise: [],
            initiativeHas: [],
            whichArt: [],
            monthlyBilling: [],
            cityActions: [],
            districtLocale: [],
            offerTerritoryAvailable: [],
            providersAvailable: [],
            hasCnpj: undefined,
            hasDrt: undefined,
            yearFoundation: '',
            hasMission: undefined,
            hasVision: undefined,
            passAudit: undefined,
            hasCounter: undefined,
            hasEquip: undefined,
            partnerForumNetwork: undefined,
            productType: [],
            documents: [],
            approvedProjectLaws: [],
            annualBudget: [],
            serviceType: [],
            hasProvider: undefined,
            isSouthPeripherySP: undefined
        }

        setInitiativeName({})
        setYouAre([])
        setOdsValues([])
        setWhatIdo([])
        setInitiativeNature([])
        setInitiativeIs([])
        setLegalFormat([])
        setCoverageArea([])
        setMotivatingPublic([])
        setSocialImpactDimension([])
        setHasTitleQualification([])
        setPercentWomen([])
        setHasPoliticalParty(undefined)
        setHasChurchAffiliation(undefined)
        setMarketSegment([])
        setWhichArt([])
        setDocuments([])
        setApprovedProjectLaws([])
        setInitiativeHas([])
        setMonthlyBilling([])
        setAreasExpertise([])
        setCity([])
        setPublicValues([])
        setOfferTerritoryAvailable([])
        setProvidersAvailable([])
        setDistrict([])
        setHasCnpj(undefined)
        setHasDrt(undefined)
        setYearFoundation('')
        setHasMission(undefined)
        setHasVision(undefined)
        setPassAudit(undefined)
        setHasCounter(undefined)
        setHasEquip(undefined)
        setPartnerForumNetwork(undefined)
        setAnnualBudget([])
        setProductType([])
        setServiceType([])
        setHasProvider(undefined)
        setIsSouthPeripherySP(undefined)

        setIsLoading(true)
        searchInitiatives(dataForm).then((response: any) => {
            setIsLoading(false)

            const resultData: any[] = response?.data?.data

            if (resultData.length) {
                setDataPage(resultData)
            } else {
                setDataPage([])
            }

            btnCloseElem.click()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
        })
    }

    const filtrar = (data: any = {}): void => {
        let btnCloseElem: HTMLElement = document.querySelector('.modal.all-filter-modal .btn-first') as HTMLElement
        const dataForm = {
            initiativeName: data?.initiativeName || initiativeName.text,
            tipo_organizacao_id: data?.youAre || youAre,
            ods: data?.ods || odsValues,
            whatIdo: whatIdo,
            initiativeNature: initiativeNature,
            initiativeIs: initiativeIs,
            legalFormat: legalFormat,
            coverageArea: coverageArea,
            socialImpactDimension: socialImpactDimension,
            hasTitleQualification: hasTitleQualification,
            percentWomen: percentWomen,
            hasPoliticalParty,
            hasChurchAffiliation,
            marketSegment,
            whichArt,
            hasDrt,
            yearFoundation,
            documents,
            approvedProjectLaws,
            initiativeHas,
            hasMission,
            hasVision,
            passAudit,
            annualBudget,
            monthlyBilling,
            hasCounter,
            hasEquip,
            partnerForumNetwork,
            areasExpertise: data?.areasExpertise || areasExpertise,
            cityActions: data?.city || city,
            districtLocale: data?.district || district,
            motivatingPublic: data?.public || publicValues,
            offerTerritoryAvailable: data?.offerTerritoryAvailable || offerTerritoryAvailable,
            providersAvailable: data?.providersAvailable || providersAvailable,
            hasCnpj,
            productType: productType,
            serviceType: serviceType,
            hasProvider,
            isSouthPeripherySP
        }

        setIsLoading(true)
        searchInitiatives(dataForm).then((response: any) => {
            setIsLoading(false)

            const resultData: any[] = response?.data?.data

            if (resultData.length) {
                setDataPage(resultData)
            } else {
                setDataPage([])
            }

            btnCloseElem.click()
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
        })
    }

    return (
        <Container>
            <div className="filter">
                {!isMobile &&
                    <div className="filter-search">
                        <Dropdown
                            placeholder='Nome da iniciativa'
                            fluid
                            search
                            clearable
                            selection
                            options={friendOptions}
                            onChange={(e, { value }) => {
                                setInitiativeName(friendOptions[value as number])
                                filtrar({ initiativeName: friendOptions[value as number].text })
                            }}
                        />
                    </div>
                }

                <Menu>
                    <div className="link item">
                        <Button className="btn-all" text="Todos os filtros" isBtnModal targetModalName="allFilters" />
                    </div>

                    {!isMobile &&
                        <>
                            <Dropdown text='Tipo' pointing className='link item'>
                                <Dropdown.Menu>{
                                    youAreOptions.map((item, index) =>
                                        <Dropdown.Item
                                            key={index}
                                            className={`${youAre.includes(item.value as number) ? 'active' : ''}`}
                                            onClick={() => {
                                                if (youAre.includes(item.value)) {
                                                    setYouAre(youAre.filter(y => y !== item.value))
                                                    filtrar({ youAre: youAre.filter(y => y !== item.value) })
                                                } else {
                                                    setYouAre([item.value])
                                                }
                                                filtrar({ youAre: [item.value] })
                                            }}
                                        >
                                            <img src={item.icon} alt="" />{item.text}
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown text='ODS' pointing className='link item'>
                                <Dropdown.Menu>{
                                    odsOptions.map((item, index) =>
                                        <Dropdown.Item
                                            key={index}
                                            className={`${odsValues.includes(item.value as number) ? 'active' : ''}`}
                                            onClick={() => {
                                                if (odsValues.includes(item.value)) {
                                                    setOdsValues(odsValues.filter(y => y !== item.value))
                                                    filtrar({ ods: odsValues.filter(y => y !== item.value) })
                                                } else {
                                                    setOdsValues([...odsValues, item.value])
                                                    filtrar({ ods: [...odsValues, item.value] })
                                                }
                                            }}
                                        >
                                            <img src={item.icon} alt="" />{item.text}
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown text='Temática' pointing className='link item'>
                                <Dropdown.Menu className="thematic">{
                                    thematicOptions.map((item, index) =>
                                        <Dropdown.Item
                                            key={index}
                                            className={`${areasExpertise.includes(item.value as number) ? 'active' : ''}`}
                                            onClick={() => {
                                                if (areasExpertise.includes(item.value)) {
                                                    setAreasExpertise(areasExpertise.filter(y => y !== item.value))
                                                    filtrar({ areasExpertise: areasExpertise.filter(y => y !== item.value) })
                                                } else {
                                                    setAreasExpertise([...areasExpertise, item.value])
                                                    filtrar({ areasExpertise: [...areasExpertise, item.value] })
                                                }
                                            }}
                                        >
                                            <div className="content">
                                                <img src={item.icon} alt="" />
                                                <span className="text">{item.text}</span>
                                            </div>
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown text='Cidade' pointing className='link item'>
                                <Dropdown.Menu className="city">
                                    {cityActionsOptions.slice(1).map(item =>
                                        item.key === 1 ?
                                            <Dropdown.Item key={item.key} className={`${city.includes(item.value as number) ? 'active' : ''} first`}>
                                                <Dropdown text={item.text}>
                                                    <Dropdown.Menu>
                                                        {districtLocaleOptions.slice(1).map((districtItem, index) =>
                                                            <Dropdown.Item
                                                                text={districtItem.text}
                                                                key={index}
                                                                className={`${district.includes(districtItem.value as number) ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    if (district.includes(districtItem.value as number)) {
                                                                        setDistrict(district.filter(y => y !== districtItem.value))
                                                                        filtrar({ district: district.filter(y => y !== districtItem.value) })

                                                                        if (!(district.filter(y => y !== districtItem.value).length)) {
                                                                            setCity(city.filter(y => y !== 1))
                                                                        } else if (!city.includes(1)) {
                                                                            setCity([...city, 1])
                                                                        }
                                                                    } else {
                                                                        setDistrict([...district, districtItem.value as number])
                                                                        filtrar({ district: [...district, districtItem.value as number] })

                                                                        if (!city.includes(1)) {
                                                                            setCity([...city, 1])
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Dropdown.Item>
                                            :
                                            <Dropdown.Item
                                                key={item.key}
                                                text={item.text}
                                                className={`${city.includes(item.value as number) ? 'active' : ''}`}
                                                onClick={() => {
                                                    if (city.includes(item.value as number)) {
                                                        setCity(city.filter(y => y !== item.value))
                                                        filtrar({ city: city.filter(y => y !== item.value) })
                                                    } else {
                                                        setCity([...city, item.value as number])
                                                        filtrar({ city: [...city, item.value as number] })
                                                    }
                                                }}
                                            />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown text='Público' pointing className='link item'>
                                <Dropdown.Menu>
                                    {publicOptions.map((item, index) =>
                                        <Dropdown.Item
                                            key={item.key}
                                            text={item.text}
                                            className={`${publicValues.includes(item.value as number) ? 'active' : ''}`}
                                            onClick={() => {
                                                if (publicValues.includes(item.value)) {
                                                    setPublicValues(publicValues.filter(y => y !== item.value))
                                                    filtrar({ public: publicValues.filter(y => y !== item.value) })
                                                } else {
                                                    setPublicValues([item.value])
                                                    // filtrar({ public: [...publicValues, item.value] })
                                                }
                                                //
                                                // if (youAre.includes(item.value)) {
                                                //     setYouAre(youAre.filter(y => y !== item.value))
                                                //     filtrar({ youAre: youAre.filter(y => y !== item.value) })
                                                // } else {
                                                //     setYouAre([item.value])
                                                // }
                                                filtrar({ public: [item.value] })
                                            }}
                                        />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown simple text='Banco da Solidariedade' className='link item'>
                                <Dropdown.Menu>
                                    {offerTerritoryAvailableOptions.map((o, index) => (
                                        <div key={o.key}>
                                            <Dropdown.Item simple>
                                                <Form.Checkbox
                                                    key={o.key}
                                                    label={o.text}
                                                    checked={offerTerritoryAvailable.length ? offerTerritoryAvailable?.includes(o.key) : false}
                                                    onChange={(e, { checked }) => {
                                                        onChangeOfferTerritoryAvailable(checked, o.key, true)
                                                    }}
                                                />
                                            </Dropdown.Item>
                                        </div>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }
                </Menu>
            </div>

            <MapContainer center={[-23.6446689, -46.6776087]} zoom={12} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Button
                    className="btn-submit btn-more radius-100"
                    isBtnModal
                    targetModalName="initiativeSummaryModal"
                    type="button"
                    text="Mostrar mais"
                    onClick={() => { showInitiativeSummaryModal() }}
                />
                {dataPage.map((item, index) => (
                    <Marker
                        key={index}
                        position={[item.latitude, item.longitude]}
                        icon={getPin(item.tipo_organizacao_id, item.areasExpertise)}
                        riseOnHover
                        eventHandlers={{
                            click: (e) => {
                                let btnOpenModalElem: HTMLElement = document.querySelector('.btn-submit') as HTMLElement
                                setCompany(item)
                                setTimeout(() => {
                                    btnOpenModalElem.click()
                                }, 300);
                            },
                        }}
                    ></Marker>
                ))}
            </MapContainer>

            <ModalFilter
                title="Todos os Filtros"
                targetModalName="allFilters"
                className="all-filter-modal"
                btnSecondText="Enviar"
                elemRef={allFilterModalRef}
                confirmAction={() => filtrar()}
                clearAction={() => clearFilter()}
            >
                <div className="modal-description modal-filters">
                    <ModalFilterContent
                        youAre={youAre}
                        setYouAre={setYouAre}
                        whatIdo={whatIdo}
                        setWhatIdo={setWhatIdo}
                        initiativeNature={initiativeNature}
                        setInitiativeNature={setInitiativeNature}
                        initiativeIs={initiativeIs}
                        setInitiativeIs={setInitiativeIs}
                        legalFormat={legalFormat}
                        setLegalFormat={setLegalFormat}
                        coverageArea={coverageArea}
                        setCoverageArea={setCoverageArea}
                        motivatingPublic={motivatingPublic}
                        setMotivatingPublic={setMotivatingPublic}
                        socialImpactDimension={socialImpactDimension}
                        setSocialImpactDimension={setSocialImpactDimension}
                        hasTitleQualification={hasTitleQualification}
                        setHasTitleQualification={setHasTitleQualification}
                        percentWomen={percentWomen}
                        setPercentWomen={setPercentWomen}
                        marketSegment={marketSegment}
                        setMarketSegment={setMarketSegment}
                        whichArt={whichArt}
                        setWhichArt={setWhichArt}
                        documents={documents}
                        setDocuments={setDocuments}
                        approvedProjectLaws={approvedProjectLaws}
                        setApprovedProjectLaws={setApprovedProjectLaws}
                        initiativeHas={initiativeHas}
                        setInitiativeHas={setInitiativeHas}
                        hasMission={hasMission}
                        setHasMission={setHasMission}
                        hasVision={hasVision}
                        setHasVision={setHasVision}
                        passAudit={passAudit}
                        setPassAudit={setPassAudit}
                        annualBudget={annualBudget}
                        setAnnualBudget={setAnnualBudget}
                        monthlyBilling={monthlyBilling}
                        setMonthlyBilling={setMonthlyBilling}
                        odsValues={odsValues}
                        setOdsValues={setOdsValues}
                        areasExpertise={areasExpertise}
                        setAreasExpertise={setAreasExpertise}
                        city={city}
                        setCity={setCity}
                        district={district}
                        setDistrict={setDistrict}
                        hasCnpj={hasCnpj}
                        setHasCnpj={setHasCnpj}
                        publicValues={publicValues}
                        setPublicValues={setPublicValues}
                        productType={productType}
                        setProductType={setProductType}
                        serviceType={serviceType}
                        setServiceType={setServiceType}
                        isSouthPeripherySP={isSouthPeripherySP}
                        setIsSouthPeripherySP={setIsSouthPeripherySP}
                        hasPoliticalParty={hasPoliticalParty}
                        setHasPoliticalParty={setHasPoliticalParty}
                        hasChurchAffiliation={hasChurchAffiliation}
                        setHasChurchAffiliation={setHasChurchAffiliation}
                        hasDrt={hasDrt}
                        setHasDrt={setHasDrt}
                        yearFoundation={yearFoundation}
                        setYearFoundation={setYearFoundation}
                        hasCounter={hasCounter}
                        setHasCounter={setHasCounter}
                        hasEquip={hasEquip}
                        setHasEquip={setHasEquip}
                        partnerForumNetwork={partnerForumNetwork}
                        setPartnerForumNetwork={setPartnerForumNetwork}
                        offerTerritoryAvailable={offerTerritoryAvailable}
                        setOfferTerritoryAvailable={setOfferTerritoryAvailable}
                        providersAvailable={providersAvailable}
                        setProvidersAvailable={setProvidersAvailable}
                    />
                </div>
            </ModalFilter>

            <Modal
                title="Excluir iniciativa"
                type="create"
                escClick
                outsideClick
                showTopBtnclone
                className="show-initiative-summary-modal"
                targetModalName="initiativeSummaryModal"
            >
                <div className="modal-description">
                    <InitiativeSummaryCard dataPage={initiativeMapaData} />
                </div>
            </Modal>
        </Container>
    )
}