import styled from "styled-components";

export const Container = styled.div`
    .col-md-6:first-of-type {
        background: #516274;
    }

    .left-content {
        background: #516274;
        position: relative;
        height: 100vh;

        .login-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translatex(-50%) translatey(-50%);

            .title-content {
                top: 0px;
                position: absolute;
                color: #fff;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
            }
            
            .login {
                margin-top: 89px;
                padding: 16px 56px;
                width: 370px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 16px;

                .title {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                }
    
                .type-users {
                    position: relative;
                    margin-top: 16px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px;
                    width: 250px;
                    height: 40px;
                    border: 1px solid #516274;
                    border-radius: 50px;
    
                    .type {
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        padding: 10px 24px;
                        z-index: 11;
                    }
    
                    .bg-type {
                        position: absolute;
    
                        &.active {
                            background: #F4C600;
                            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                            border-radius: 50px;
                            height: 40px;
                        }
                        
                        &.user {
                            &.active {
                                left: -3px;
                                min-width: 102px;
                                transition: all 0.7s ease-in-out;
                            }
                        }
    
                        &.admin {
                            
                            &.active {
                                left: 103px;
                                min-width: 148px;
                                transition: all 0.7s ease-in-out;
                            }
                        }
                    }
                }
    
                .form {
                    .field {
                        margin-top: 16px;
    
                        label {
                            text-align: left;
                        }
                    }
            
                    .btn-submit {
                        width: 100%;
                        margin: 16px 0px;
                    }
    
                    .btn-forget {
                        width: 100%;
                    }
                }
            }
            
        }
    }

    .right-content {
        height: 90vh;
        padding: 20px 0px;

        img {
            width: 80%;
            height: 80%;
        }
    }

    @media (max-width: 575.98px) {
        .left-content {
            .login-container {
                .login {
                    width: 100%;
                    padding: 16px 40px;
                }
            }
        }
    }
`