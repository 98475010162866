import { createGlobalStyle } from 'styled-components'
import { Variables } from './variables'

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        color: #516274;
    }

    .modal-backdrop {
        display: none;
    }

    .loading {
        position: fixed;
        z-index: 111111;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.95;
        background: ${Variables.gray};
        height: 100vh;
        width: 100vw;

        .text-warning {
            color: ${Variables.yellow};
            width: 80px;
            height: 80px;
        }
    }

    .ulink {
        cursor: pointer;
        color: #000;
        text-decoration: underline;
    }

    .text-black {
        color: #000;
    }

    .text-white {
        color: #fff;
        
        label {
            color: #fff !important;
        }
    }
    
    h1 {
        font-family: 'Open Sans', sans-serif;
    }

    .btn-back {
        position: absolute;
        top: 24px;
        left: 24px;

        button {
            color: #FFF;
        }
    }

    .padding-0 {
        padding: 0px !important;
    }

    .pr-0 {
        padding-right: 0px !important;
    }

    .pl-0 {
        padding-left: 0px !important;
    }

    .pb-0 {
        padding-bottom: 0px !important;
    }

    .pt-0 {
        padding-top: 0px !important;
    }

    .flex-cc {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flex-sc {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .flex-ce {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .radius-8 {
        border-radius: 8px;
    }

    .radius-100 {
        border-radius: 100px;
    }

    .float-left {
        float: left;
    }

    .float-right {
        float: right;
    }

    .w-100 {
        width: 100%;
    }

    .w-auto {
        width: auto;
    }

    .f-right {
        float: right;
    }

    .f-left {
        float: left;
    }

    .margin-0 {
        margin: 0px;
    }

    .mr-8 {
        margin-right: 8px;
    }

    .mr-16 {
        margin-right: 16px;
    }

    .mr-24 {
        margin-right: 24px;
    }

    .mr-32 {
        margin-right: 32px;
    }

    .mb-0 {
        margin-bottom: 0px;
    }

    .mb-8 {
        margin-bottom: 8px;
    }

    .mb-16 {
        margin-bottom: 16px;
    }

    .mb-24 {
        margin-bottom: 24px;
    }

    .mb-32 {
        margin-bottom: 32px;
    }

    .pos-relative {
        position: relative;
    }

    .pos-absolute {
        position: absolute;
    }

    .fw-600 {
        font-weight: 600;
    }

    .fw-bold {
        font-weight: bold;
    }

    .fc-black {
        color: #000;
    }

    .ellipsis {
        white-space: nowrap;
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .label-default {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #000000;
    }

    .field {
        position: relative;

        .label-outside {
            position: absolute;
            top: -7.6px;
            left: 16px;
            padding: 0 4px;
            background: #FFF;
            color: ${Variables.gray};
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
        }
    }

    .field-col-align {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &.white-label {
            .field {
                label:first {
                    color: #FFF !important;
                }
            }
        }
    }

    .modal {
        .field-error-msg {
            margin: 8px 0px 20px !important;
        }

        .modal-dialog-centered {
            justify-content: center;
        }
    }

    .field-error-msg {
        text-align: left;
        margin: 0px 0px 14px !important;
        color: ${Variables.danger};
    }

    // SEMANTIC-UI

    .popup {
        background: rgb(81,98,116) !important;

        &:before {
            background: rgb(81,98,116) !important;
        }

        .text {
            cursor: pointer;
            span {
                color: #fff;

                &.close {
                    font-size: 16px;
                    margin-right: 8px;
                }
            }

            &:hover {
                span {
                    font-weight: 900;
                }
            }
        }
    }

    .ui.form textarea,
    textarea {
        width: 100%;
        color: #1C1B1F;
        min-height: 140px;
        font-size: 16px;
        padding: 16px;
        padding-bottom: 16px;
        border: 1px solid #79747E;
        border-radius: 4px;

        &.field-error {
            border: 1px solid ${Variables.danger} !important;
        }
    }

    .label-form-default,
    .label-textarea {
        margin-bottom: 12px;
        color: #000;
    }

    .ui {
        &.form {
            .row {
                margin-bottom: 32px;
            }

            .field {
                label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000;
                    margin-bottom: 12px;
                }

                &.label-hide {
                    > label {
                        visibility: collapse;
                    }
                }

                &.field-error {
                    .ui.input {
                        input {
                            border: 1px solid ${Variables.danger} !important;
                        }
                    }

                    .selection {
                        &.dropdown {
                            border: 1px solid ${Variables.danger} !important;
                        }
                    }
                }
                
            }
        }

        &.checkbox {
            input {
                width: 18px !important;
                height: 18px !important;

                &:checked~label:after {
                    opacity: 1;
                    color: #fff;
                    border: 1px solid #516274;
                    width: 18px;
                    height: 18px;
                    background: #516274;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                }

                &:focus~label:before {
                    border: 3px solid #516274;
                }
            }

            label {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #000;

                &:before {
                    top: 2px;
                    border: 3px solid #516274;
                }
            }
        }

        &.form {
            .disabled {
                &.field {
                    opacity: 0.8;
                }
            }
        }

        &.form .field .ui.input,
        &.input {
            width: 100%;

            .label-inside {
                position: absolute;
                top: -7.6px;
                left: 16px;
                padding: 0 4px;
                background: #FFF;
                color: ${Variables.gray};
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
            }

            input {
                color: #1C1B1F;
                font-size: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                border: 1px solid #79747E;
                border-radius: 4px;

                &.error {
                    border-color: ${Variables.danger};
                }
            }

            .img-icon {
                cursor: pointer;
                position: absolute;
                right: 14px;
                height: 100%;
            }
        }

        .tooltip-box {
            cursor: pointer;
            width: 30px;
            height: 30px;
            position: absolute;
            right: -12px;
            top: -12px;
            z-index: 1;
        }

        &.multiple {
            &.dropdown {
                padding-top: 13px !important;
                padding-bottom: 5px !important;

                &> .label {
                    font-size: 14px;

                    .delete.icon {
                        display: none;
                    }
                }
            }
        }

        &.selection {
            &.dropdown {
                color: #1C1B1F;
                font-size: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                border: 1px solid #79747E;
                border-radius: 4px;

                > .dropdown {
                    &.icon {
                        padding-top: 20px;
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }

    .field {
        &.simple-field-error {
            .ui.input {
                input {
                    border: 1px solid ${Variables.danger} !important;
                }
            }
    
            .selection {
                &.dropdown {
                    border: 1px solid ${Variables.danger} !important;
                }
            }
        }
    }
`

export default GlobalStyle