import { Checkbox, Form } from "semantic-ui-react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import downloadIcon from "../../../assets/img/icons/download-gray.svg"
import { FirstInformationProps } from "../../../models/FirstInformationProps"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useContext, useEffect, useState } from "react"
import { withMask } from 'use-mask-input';
import { GlobalContext } from "../../../context/GlobalContext"
import { useNavigate } from "react-router"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { Modal } from "../../../designSystems/components/Modal"
import { URL_PUBLIC } from "../../../api/utils"
import { getUseTerm } from "../../../api/admin"

const areYouOtions = [
    { key: 's', text: 'Selecione ...', value: undefined },
    { key: 'ong', text: 'Um OSCs/Coletivos (formais e informais)', value: 1 },
    { key: 'empresa', text: 'Uma empresa', value: 2 },
    { key: 'equipamento', text: 'Um equipamento público', value: 3 },
    { key: 'prestador', text: 'Um MEI/Empreendedores/Prestadores de Serviço', value: 4 }
]

type Props = {
    name?: string
    tipoOrganizacao?: number
    cpf?: string
}

export const InitialRegisterContent = (): JSX.Element => {
    const [useTerms, setUseTerms] = useState(false)
    const [useTermsPdf, setUseTermsPdf] = useState(undefined)
    const [privacyPolitic, setPrivacyPolitic] = useState(false)
    const [privacyPoliticPdf, setPrivacyPoliticPdf] = useState(undefined)
    const [provideInformation, setProvideInformation] = useState(false)

    const { setIsLoading, userData, setDataToast, getErrorMessage } = useContext(GlobalContext)
    const { setCurrentPage } = useContext(RegisterPageContext)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm<Props>(
        {
            defaultValues: {
                name: undefined,
                tipoOrganizacao: undefined,
                cpf: undefined,
            }
        }
    );

    const [nameElem, setNameElem] = useState<any>(undefined)
    const [cpfElem, setCpfElem] = useState<any>(undefined)

    useEffect(() => {
        const aliasInfo = userData.permissaos_id === 3 && !userData.cpf ? 'usuario-temp' : 'first-info'
        const localStorageInfo: Props = JSON.parse(localStorage.getItem(aliasInfo) || '{}')

        if (aliasInfo === 'usuario-temp' && !localStorageInfo.cpf) {
            const localUserInfo: any = JSON.parse(localStorage.getItem('usuario') || '{}')
            setValue('tipoOrganizacao', localUserInfo.user.tipo_organizacao_id)
        }

        if (localStorageInfo && localStorageInfo.cpf) {
            setValue('name', localStorageInfo.name || undefined)
            setValue('tipoOrganizacao', localStorageInfo.tipoOrganizacao || undefined)
            setValue('cpf', localStorageInfo.cpf || undefined)
        }

        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        getUseTerm().then((response: any) => {
            setIsLoading(false)
            setUseTermsPdf(response.data?.data?.useTerm || undefined)
            setPrivacyPoliticPdf(response.data?.data?.privacyPolicy || undefined)
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
        })
    }

    const openUseTermModal = (): void => {
        let btnOpenModalElem: HTMLElement = document.querySelector('.use-term-btn') as HTMLElement
        btnOpenModalElem.click()
    }

    const openPrivacyPolicyModal = (): void => {
        let btnOpenModalElem: HTMLElement = document.querySelector('.privacy-policy-btn') as HTMLElement
        btnOpenModalElem.click()
    }

    const onSubmit: SubmitHandler<FirstInformationProps> = data => {
        const aliasInfo = userData.permissaos_id === 3 && !userData.cpf ? 'usuario-temp' : 'first-info'

        if (useTerms && privacyPolitic && provideInformation) {
            setIsLoading(true)
            localStorage.setItem(aliasInfo, JSON.stringify(data))
            setCurrentPage('first-information')
            setIsLoading(false)
        }
    }

    return (
        <Container>
            <div className="text-01">Cadastro da nova iniciativa</div>

            <div className="text-02">
                Sabemos que uma pessoa pode ser responsável por mais de uma iniciativa, como por exemplo, ser responsável por uma ONG e uma MEI, por isso pedimos aqui o CPF, pois será através dele que poderemos exibir todas as iniciativas sob responsabilidade dessa pessoa no mesmo painel de controle!
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        label='Inscrição da iniciativa'
                                        placeholder='Escreva o seu nome completo'
                                        className={errors.name && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            setNameElem(e)
                                        }}
                                    >
                                        <label className="label-inside">Nome *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                nameElem.target.value = ''
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.name && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>

                    <div className="col-md-4 field-col-align white-label">
                        <Controller
                            name="cpf"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        label="&nbsp;"
                                        placeholder='999.999.999-99'
                                        className={errors.cpf && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            setCpfElem(e)
                                        }}
                                    >
                                        <label className="label-inside">CPF *</label>
                                        <input ref={withMask('999.999.999-99')} />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                cpfElem.target.value = ''
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.cpf && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="tipoOrganizacao"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Você é: *'
                                        placeholder='Selecione ...'
                                        className={errors.tipoOrganizacao && 'field-error'}
                                        value={value}
                                        options={areYouOtions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.tipoOrganizacao && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                </div>

                <hr className="line" />

                <div className="text-03">Ao efetuar o cadastro no Mapa PerifaSul 2050 estou de ciente e acordo com:</div>
                <div>
                    <div
                        style={{
                            cursor: 'pointer',
                            width: '103px',
                            height: '32px',
                            left: '47px',
                            position: 'absolute',
                            zIndex: 1
                        }}
                        onClick={() => openUseTermModal()}
                    ></div>
                    <Form.Checkbox
                        label={<label>Os <span className="ulink">Termos de Uso</span> do Mapa PerifaSul 2050</label>}
                        checked={useTerms}
                        onChange={function (e, { checked }) {
                            setUseTerms(checked || false)
                        }}
                    />
                </div>
                <div>
                    <div
                        style={{
                            cursor: 'pointer',
                            width: '150px',
                            height: '32px',
                            left: '38px',
                            position: 'absolute',
                            zIndex: 1
                        }}
                        onClick={() => openPrivacyPolicyModal()}
                    ></div>
                    <Form.Checkbox
                        label={<label>A <span className="ulink">Política de Privacidade</span> do Mapa PerifaSul 2050</label>}
                        checked={privacyPolitic}
                        onChange={function (e, { checked }) {
                            setPrivacyPolitic(checked || false)
                        }}
                    />
                </div>
                <Form.Checkbox
                    label='Disponibilizar as informações que aparecerá do card da iniciativa cadastrada com os usuários do Mapa PerifaSul 2050 '
                    checked={provideInformation}
                    onChange={function (e, { checked }) {
                        setProvideInformation(checked || false)
                    }}
                />

                {(!useTerms || !privacyPolitic || !provideInformation) && <p className="field-error-msg">Você deve aceitar todas as opções</p>}

                <div className="pdfs-btns">
                    <Button
                        text="Termo de Uso"
                        className="use-term-btn radius-100 f-left mr-24 btn-outline-gray"
                        type="button"
                        icon={downloadIcon}
                        isBtnModal
                        targetModalName="useTermModal"
                    />

                    <Button
                        text="Política de Privacidade"
                        className="privacy-policy-btn radius-100 f-left btn-outline-gray"
                        type="button"
                        icon={downloadIcon}
                        isBtnModal
                        targetModalName="privacyPolicyModal"
                    />
                </div>

                <div className="footer">
                    <hr />
                    <Button type="submit" text="Iniciar" className="radius-100 f-right" disabled={!useTerms || !privacyPolitic || !provideInformation} />
                </div>
            </Form>

            <Modal
                title="Excluir conta"
                type="delete"
                className="use-term-modal"
                targetModalName="useTermModal"
                btnFirstText="Fechar"
                showTopBtnclone
                hideFooter
            >
                <div className="modal-description">
                    {useTermsPdf ?
                        <iframe
                            src={`${URL_PUBLIC + useTermsPdf}`}
                            style={{ width: '100%', height: 'calc(100vh - 120px)' }}
                            frameBorder="0"
                        ></iframe> :
                        <div style={{ textAlign: 'center' }}><h4>Pdf não disponível.</h4></div>
                    }
                </div>
            </Modal>

            <Modal
                title="Excluir conta"
                type="delete"
                className="privacy-policy-modal"
                targetModalName="privacyPolicyModal"
                btnFirstText="Fechar"
                showTopBtnclone
                hideFooter
            >
                <div className="modal-description">
                    {privacyPoliticPdf ?
                        <iframe
                            src={`${URL_PUBLIC + privacyPoliticPdf}`}
                            style={{ width: '100%', height: 'calc(100vh - 120px)' }}
                            frameBorder="0"
                        ></iframe> :
                        <div style={{ textAlign: 'center' }}><h4>Pdf não disponível.</h4></div>
                    }
                </div>
            </Modal>
        </Container >
    )
}