import { userProps } from './../models/userProps'
import { createContext } from "react";

export type dataToastProps = {
    show: boolean
    text: string
}

interface GlobalContextData {
    isAuthenticated: boolean,
    isLoading: boolean,
    setIsLoading: (value: boolean) => void,
    menuItemActivated: string,
    setMenuItemActivated: (value: string) => void,
    isMobile: boolean,
    userId: number,
    setUserId: (value: number) => void,
    userData: userProps,
    setUserData: (userData: userProps) => void,
    dataToast: dataToastProps,
    setDataToast: (value: dataToastProps) => void,
    getErrorMessage: (errorMessage: string) => string,
    lastStep: number,
    setLastStep: (value: number) => void,
    changeLastStep: (newStep: number) => void,
    vertifyAuthenticatedRedirect: (errorMessage: string) => boolean,
    pathnameIsAdmin: () => boolean,
    showNextRegisterButton: (value: boolean) => boolean,
}

export const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData)