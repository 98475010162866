import { Variables } from './../../../styles/variables'
import styled from "styled-components";

interface Props extends React.ComponentPropsWithoutRef<'div'> {
    count: number
}

export const Container = styled.div<Props>`
position: relative;
min-height: calc(100vh - 60px);
padding-bottom: 141px;

.ui {
    &.form {
        position: unset;

        .field {
            margin-bottom: 14px;

            &.dropdown-counter {
                .multiple {
                    &.dropdown {
                        &:not(.default){
                            &::after {
                                content: '${props => props.count}';
                                display: ${props => props.count > 0 ? 'block' : 'none'};
                                width: 30px;
                                text-align: center;
                                position: absolute;
                                background: ${Variables.yellow};
                                color: ${Variables.gray};
                                padding: 5px;
                                border-radius: 4px;
                                font-size: 14px;
                                font-weight: 600;
                                right: -12px;
                                top: -12px;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            width: 100%;
            position: absolute;
            bottom: 28px;

            .btn-actions {
                display: flex;
            }
        }

        .ods-options {
            .ui.checkbox input:checked~label:after {
                top: 0;
                transform: translateY(50%);
            }

            label {
                display: flex;
                align-items: center;

                &:before {
                    top: 0;
                    transform: translateY(50%);
                }
                
                img {
                    width: 36px;
                    margin-right: 8px;
                }

                .text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    color: #000000;
                }
            }
        }
    }
}
`