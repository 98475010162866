import { Children } from "react"
import { Container } from "./style"

type Props = {
    text?: string
    children?: any
    background?: string
    className?: string
    height?: string
    disabled?: boolean
    hasShadow?: boolean
    isBtnModal?: boolean
    targetModalName?: string
    type?: 'button' | 'submit' | 'reset' | undefined
    icon?: string
    onClick?: (e: any) => void
}

export const Button = ({
    text,
    type,
    background,
    icon,
    height,
    disabled,
    hasShadow,
    isBtnModal,
    targetModalName,
    className,
    children,
    onClick }: Props): JSX.Element => {
    return (
        children ? <div className={className}>{children}</div> :
            <Container
                type={type}
                hasShadow={hasShadow}
                background={background}
                className={className}
                disabled={disabled}
                height={height}
                onClick={onClick}
                data-bs-toggle={isBtnModal ? "modal" : ""}
                data-bs-target={`#${targetModalName}`}
            >
                {icon && <img src={icon} alt="icon" />}
                {text}
            </Container>
    )
}