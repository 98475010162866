import { Variables as obj } from './../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
    @import "variables";

    main {
        padding: 32px 0px;
        height: calc(100vh - 60px);

        .bg-admin {
            position: fixed;
            bottom: 12px;

            img {
                width: 100vw;
            }
        }

        .left-sidebar {
            .menu {
                .menu-item {
                    cursor: pointer;
                    flex-direction: column;
                    margin-bottom: 8px;

                    .circle {
                        width: 56px;
                        height: 32px;
                        background: transparent;
                        border-radius: 100px;
                        margin-bottom: 4px;

                        .icon {
                            img {
                                width: 21px;
                            }
                        }

                        .circle-inside {
                            height: 12px;
                            width: 12px;
                            border: 2px solid #49454F;
                            background: #FFF;
                            border-radius: 50%;
                            display: inline-block;
                        }
                    }

                    &.active {
                        .circle {
                            background: #F4C600;

                            .circle-inside {
                                background-color: #516274;
                            }
                        }
                    }

                    .text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }
    
    .bar-footer {
        height: 14px;
        width: 100%;
        background: ${obj.yellow};
        position: fixed;
        bottom: 0px;
    }

    @media (max-width: 575.98px) {
        main {
            padding: 16px 0px;

            .left-sidebar {
                .menu {
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-around;
                }
            }
        }       
    }
`