import { Variables } from '../../../styles/variables'
import styled from "styled-components";

interface Props extends React.ComponentPropsWithoutRef<'div'> {
    countInitiatives: number
}

export const Container = styled.div<Props>`
position: relative;
min-height: calc(100vh - 171px);
padding-bottom: 83px;

.ui {
    &.form {
        position: unset;

        .field {
            margin-bottom: 14px;

            &.dropdown-initiatives {
                .multiple {
                    &.dropdown {
                        &::after {
                            content: '${props => props.countInitiatives}';
                            display: ${props => props.countInitiatives > 0 ? 'block' : 'none'};
                            width: 30px;
                            text-align: center;
                            position: absolute;
                            background: ${Variables.yellow};
                            color: ${Variables.gray};
                            padding: 5px;
                            border-radius: 4px;
                            font-size: 14px;
                            font-weight: 600;
                            right: 42px;
                        }
                    }
                }
            }
        }

        .footer {
            width: 100%;
            position: absolute;
            bottom: 28px;

            .btn-actions {
                display: flex;
            }
        }

        .new-initiatives {
            position: relative;
            top: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            width: 100%;
            height: auto;
            background: #F1F2F4;
            border-radius: 20px;

            .text {
                letter-spacing: 0.1px;
                color: #49454F;
                margin-bottom: 21px;
            }

            .initiatives-list {
                width: 100%;
                margin-bottom: 21px;
            }

            .initiatives-others {
                width: 100%;
                margin-bottom: 21px;
            }
        }
    }
}

.modal {
    &.new-account-modal {
        .modal-dialog {
            width: 412px;
            .modal-content {
                padding: 30px;
            }
        }
    }
}

@media (max-width: 575.99px) {
    .ui {
        &.form {
            .new-initiatives {
                position: relative;
            }
        }
    }

    .modal {
        &.new-account-modal {
            .modal-dialog {
                width: 100%;
                margin: 0px;
                
                .modal-content {
                    padding: 20px;
                }
            }
        }
    }
}
`