import styled from "styled-components";
import { Variables } from "../../../styles/variables";

export const Container = styled.div`
    .modal {
        &.fade {
            z-index: 1111;
            background: rgba(0,0,0,.5);
        }
        
        .modal-dialog {
            .modal-content {
                border-radius: 28px;
                padding: 30px;

                .modal-header {
                    display: block;
                    text-align: center;
                    padding: 0px;
                    border: unset;

                    .icon {
                        img {
                            width: 16px;
                            height: 18px;
                        }
                    }
                    
                    .modal-title {
                        margin-top: 16px;
                        margin-bottom: 16px;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 32px;
                        color: #1C1B1F;
                    }
                }

                .modal-body {
                    padding: 0px;

                    .fields {
                        margin-bottom: 36px;

                        .field {
                            margin-bottom: 14px;
                        }
                    }

                    .modal-description {
                        margin-bottom: 36px;
                    }
                    
                    .add-image {
                        margin-bottom: 32px;

                        .register_profile_image{
                            margin-top: 15px;
            
                            .btn {
                                background: ${Variables.gray};
                                color: #FFF;
                                padding: 8px 20px;
                                display: inline-flex;
                                align-items: center;
                                transition: background .15s ease-in-out;
                                
                                span {
                                    color: #FFF;
                                }
            
                                :hover {
                                    background: #66788a;
                                }
            
                                .download-icon {
                                    margin-right: 11px;
                                }
                            }
                        }
                    }
        
                    .previewProfilePic {
                        position: relative;
                        display: flex;
                        margin-top: 15px;
        
                        .trash {
                            cursor: pointer;
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            width: 16px;
                            height: 18px;
                        }
        
                        .text {
                            margin-left: 24px;
                            margin-right: 24px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.4px;
                            color: #49454F;
                            word-break: break-all;
                        }
        
                        img {
                            height: 86px;
                        }
                    }
                }
                
                .modal-footer {
                    padding: 0px;
                    border: unset;

                    .btn-footer {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.1px;
                        background: transparent;
                        border: unset;

                        .btn-first {
                            color: #516274;
                        }

                        &.btn-create {
                            color: #006768;
                        }

                        &.btn-delete {
                            color: #B3261E;
                        }
                    }
                }
            }
        }
    }
`