import addIcon from '../../../assets/img/icons/add-green.svg'
import trashIcon from '../../../assets/img/icons/trash-red.svg'
import { Button } from '../Button'
import { Container } from './style'

type Props = {
    targetModalName: string
    className?: string
    title: string,
    btnSecondText: string,
    elemRef?: React.MutableRefObject<any>,
    children: any,
    confirmAction?: () => void
    clearAction?: () => void
}

export const ModalFilter = ({ targetModalName, className, title, btnSecondText, children, confirmAction, clearAction, elemRef }: Props): JSX.Element => {
    return (
        <Container>
            <div
                className={`modal fade${className ? ' ' + className : ''}`}
                id={targetModalName}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                ref={elemRef}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="staticBackdropLabel">{title}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {children}
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn-footer"
                                onClick={clearAction}
                            >
                                Remover Filtros
                                </button>

                            <button type="button" className="btn-footer btn-first" data-bs-dismiss="modal">Cancelar</button>

                            <Button
                                type="submit"
                                text="Filtrar"
                                className="radius-100"
                                data-bs-dismiss="modal"
                                onClick={confirmAction}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}