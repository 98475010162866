import { AboutInitiativeProps } from './../../models/AboutInitiativeProps'
import { api, getConfigApi } from './../../services/api'

export const getAboutInitiative = async (params?: any): Promise<unknown> => {
    const args = { ...getConfigApi() }
    if (params) {
        args['headers'] = { ...args['headers'], ...params }
    }

    return await api.get('about-initiative', args)
}

export const createAboutInitiative = async (data: AboutInitiativeProps): Promise<unknown> => {
    return await api.post('about-initiative/create', data, getConfigApi())
}

export const updateAboutInitiative = async (data: AboutInitiativeProps): Promise<unknown> => {
    return await api.patch('about-initiative/update', data, getConfigApi())
}
