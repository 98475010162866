import styled from "styled-components";

interface Props extends React.ComponentPropsWithoutRef<'button'> {
    background: string | undefined
    height: string | undefined
    hasShadow: boolean | undefined
}

export const Container = styled.button<Props>`
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    border: unset;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 16px 20px;
    gap: 10px;
    letter-spacing: 0.1px;
    height: ${props => props.height || '40px'};
    background: ${props => props.background || '#F4C600'};
    box-shadow: ${({ hasShadow }) => hasShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'unset'};
    flex: none;
    order: 1;
    flex-grow: 0;
    
    &.btn-white {
        background: #FFF;
        color: #516274;
        transition: background .15s ease-in-out;

        :hover {
            background: #f1f1f1;
        }
    }
    
    &.btn-gray {
        background: #516274;
        color: #FFF;
        transition: background .15s ease-in-out;

        :hover {
            background: #66788a;
        }
    }
    
    &.btn-outline-red {
        color: #B3261E;
        background: transparent;
        border: 1px solid #B3261E;
        transition: background .15s ease-in-out;

        :hover {
            color: #516274;
            background: #ffd3d0;
        }
    }
    
    &.btn-outline-gray {
        color: #516274;
        background: transparent;
        border: 1px solid #516274;
        transition: all .15s ease-in-out;

        :hover {
            color: #FFF;
            background: #516274;
        }
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        color: #1C1B1F;
        background: rgba(31, 31, 31, 0.12);
    }
`