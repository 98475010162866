import { Button } from "../../../designSystems/components/Button"
import leftArrow from "../../../assets/img/icons/left-arrow-white.svg"
import illustraMap from '../../../assets/img/illustra-map.svg'
import fundacaoAbh from '../../../assets/img/fundacao-abh.svg'
import fundacaoMboi from '../../../assets/img/fundacao-mboi.svg'
import { useNavigate } from "react-router"
import { Container } from "./style"
import { useContext, useEffect, useState } from "react"
import { Form } from "semantic-ui-react"
import { login } from "../../../api/authentication"
import { useForm, SubmitHandler } from "react-hook-form";
import { GlobalContext } from "../../../context/GlobalContext"

type Props = {
    email: string
    password: string
}

export const LoginPage = (): JSX.Element => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Props>(
        { defaultValues: { email: '', password: '' } }
    );
    const [currentUser, setCurrentUser] = useState('user')
    const { setIsLoading, setDataToast, setUserId } = useContext(GlobalContext)

    const navigate = useNavigate()

    useEffect(() => { }, [])

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const onSubmit: SubmitHandler<Props> = data => {
        const result = { ...data, is_admin: currentUser === 'admin' }

        setIsLoading(true)
        login(result).then((data: any) => {
            setIsLoading(false)
            localStorage.removeItem('first-info')
            localStorage.setItem(data.data.user.is_admin ? 'authentication-admin' : 'usuario', JSON.stringify(data.data))
            setUserId(data.data.user.id)
            goToPage('/admin')
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao realizar o login' : err.response.data.message
            setDataToast({ show: true, text: textError })
        })
    }

    return (
        <Container>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="left-content">
                            <div className="btn-back">
                                <Button
                                    text={'Voltar ao mapa'}
                                    icon={leftArrow}
                                    height="42px"
                                    background="transparent"
                                    className="radius-8 float-left btn-register"
                                    onClick={e => goToPage('/mapa')}
                                />
                            </div>

                            <div className="login-container flex-cc">
                                <div className="title-content">Faça o seu Login</div>

                                <div className="login">
                                    <div className="d-block text-center">
                                        <div className="title">Mapa PerifaSul</div>
                                        <div className="type-users">
                                            <div className={`bg-type ${currentUser === 'user' ? 'user active' : 'admin active'}`}></div>
                                            <div className="type" onClick={() => setCurrentUser('user')}>
                                                Usuário
                                            </div>
                                            <div className="type" onClick={() => setCurrentUser('admin')}>
                                                Administrador
                                            </div>
                                        </div>
                                        <div>
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <Form.Input label='Login *' placeholder='exemplo@exemplo.com'>
                                                    <input className={errors.email && 'error'} {...register("email", { required: true })} />
                                                </Form.Input>
                                                {errors.email && <p className="field-error-msg">Campo obrigatório</p>}

                                                <Form.Input label='Senha *' type="password" placeholder='Senha' {...register("password", { required: true })}>
                                                    <input className={errors.password && 'error'} {...register("password")} />
                                                </Form.Input>
                                                {errors.password && <p className="field-error-msg">Campo obrigatório</p>}

                                                <Button className="btn-submit radius-100" type="submit" text="Entrar" />
                                                <Button
                                                    hasShadow={false}
                                                    className="btn-forget"
                                                    background="transparent"
                                                    text="Esqueci minha senha"
                                                    onClick={e => goToPage('/solicitar-nova-senha')}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="right-content flex-cc">
                            <img src={illustraMap} alt="home-map" />
                        </div>
                        <div className='row margin-top-20'>
                            <div className="col-md-12">
                                <div className="display: flex; justify-content: center text-center">
                                    <img src={fundacaoAbh} alt="bg-img-home" />
                                    <img src={fundacaoMboi} alt="bg-img-home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}