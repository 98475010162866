import { useContext, useEffect, useState } from "react"
import { Checkbox, Dropdown, Form, Label } from "semantic-ui-react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import addIcon from "../../../assets/img/icons/add-green.svg"
import { SolidarityBankProps } from "../../../models/SolidarityBankProps"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { GlobalContext } from "../../../context/GlobalContext"
import { useNavigate } from "react-router"
import { createSolidarityBank, getSolidarityBank, updateSolidarityBank } from "../../../api/solidarity-bank"
import { withMask } from 'use-mask-input';

const defaultOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Sim', value: 1 },
    { key: 2, text: 'Não', value: 2 },
]

const offerDefaultOptions = [...defaultOptions]
const providerDefaultOptions = [...defaultOptions]

const offerTerritoryAvailableOptions = [
    { key: 1, text: 'Biblioteca', value: 1 },
    { key: 2, text: 'Espaço para eventos', value: 2 },
    { key: 3, text: 'Equipamentos', value: 3 },
    { key: 4, text: 'Materiais', value: 4 },
    { key: 5, text: 'Sala de informática', value: 5 },
    { key: 6, text: 'Espaço de coworking', value: 6 },
    { key: 7, text: 'Estúdio de gravação', value: 7 },
    { key: 8, text: 'Quadras', value: 8 }
]

const providersAvailableOptions = [
    { key: 1, text: 'Professor', value: 1 },
    { key: 2, text: 'Carreto', value: 2 },
    { key: 3, text: 'Lanches', value: 3 },
    { key: 4, text: 'Costureira', value: 4 },
    { key: 5, text: 'Estamparia', value: 5 },
    { key: 6, text: 'Contador', value: 6 },
    { key: 7, text: 'Fotógrafo', value: 7 }
]

export const SolidarityBankContent = (): JSX.Element => {
    const [currentStage, setCurrentStage] = useState<string>('parte-1')
    const [nameOther, setNameOther] = useState<string>('')
    const [nameOther2, setNameOther2] = useState<string>('')
    const [offerTerritory, setOfferTerritory] = useState<any>(undefined)
    const [offerTerritoryOthers, setOfferTerritoryOthers] = useState<string[]>([])
    const [addOtherTerritory, setAddOtherTerritory] = useState<any>(undefined)
    const [offerTerritoryAvailable, setOfferTerritoryAvailable] = useState<any>([])
    const [providersAvailableOthers, setProvidersAvailableOthers] = useState<string[]>([])
    const [addOtherProviders, setAddOtherProviders] = useState<any>(undefined)
    const [providersAvailable, setProvidersAvailable] = useState<any>([])

    const [addOtherTerritoryElem, setAddOtherTerritoryElem] = useState<any>(undefined)
    const [addOtherProvidersElem, setAddOtherProvidersElem] = useState<any>(undefined)

    const [allowSubmit, setAllowSubmit] = useState(false)
    const [updateAction, setUpdateAction] = useState(false)

    const { setCurrentPage, handleScroll } = useContext(RegisterPageContext)
    const { setIsLoading, setDataToast, changeLastStep, getErrorMessage, vertifyAuthenticatedRedirect, userId, userData, pathnameIsAdmin, showNextRegisterButton } = useContext(GlobalContext)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm<SolidarityBankProps>(
        {
            defaultValues: {
                id: undefined,
                offerTerritory: undefined,
                offerTerritoryAvailable: [],
                offerTerritoryOthers: [],
                isSouthPeripherySP: undefined,
                hasProvider: undefined,
                providersAvailable: [],
                providersAvailableOthers: [],
                name: undefined,
                phone: undefined,
                email: undefined,
            }
        }
    );

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        getSolidarityBank(pathnameIsAdmin() ? { user_id: userId } : null).then((response: any) => {
            setIsLoading(false)

            if (response.data.data.length) {
                setUpdateAction(true)
                const responseData: SolidarityBankProps = response.data.data[0]
                fillForm(responseData)
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const fillForm = (data: SolidarityBankProps): void => {
        setValue('id', data.id)
        setValue('offerTerritory', data.offerTerritory)
        setOfferTerritory(data.offerTerritory)

        setValue('offerTerritoryAvailable', data.offerTerritoryAvailable)
        setOfferTerritoryAvailable(data.offerTerritoryAvailable)

        setValue('offerTerritoryOthers', data.offerTerritoryOthers)
        setOfferTerritoryOthers(data.offerTerritoryOthers || [])

        setValue('isSouthPeripherySP', data.isSouthPeripherySP)
        setValue('hasProvider', data.hasProvider)

        setValue('providersAvailable', data.providersAvailable)
        setProvidersAvailable(data.providersAvailable)

        setValue('providersAvailableOthers', data.providersAvailableOthers)
        setProvidersAvailableOthers(data.providersAvailableOthers || [])

        setValue('name', data.name)
        setValue('phone', data.phone)
        setValue('email', data.email)
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const onChangeOfferTerritoryAvailable = (checked: boolean | undefined, key: number): void => {
        let offerTerritoryAvailableList: number[] = [...offerTerritoryAvailable];

        if (checked) {
            offerTerritoryAvailableList.push(key)
        } else {
            offerTerritoryAvailableList = offerTerritoryAvailableList.filter(k => k !== key)
        }

        setOfferTerritoryAvailable(offerTerritoryAvailableList)
        setValue('offerTerritoryAvailable', offerTerritoryAvailableList)
    }

    const isOfferTerritoryAvailableChecked = (key: number, dataList: number[]): boolean => {
        return dataList.length ? dataList?.includes(key) : false
    }

    const onChangeOfferTerritoryOthers = (checked: boolean | undefined, info: string): void => {
        let offerTerritoryOthersList: string[] = [...offerTerritoryOthers];

        if (!checked) {
            offerTerritoryOthersList = offerTerritoryOthersList.filter(o => o !== info)
        }

        setOfferTerritoryOthers(offerTerritoryOthersList)
        setValue('offerTerritoryOthers', offerTerritoryOthersList)
    }

    const isOfferTerritoryOthersChecked = (data: string, dataList: string[]): boolean => {
        return dataList.length ? dataList?.includes(data) : false
    }

    const updateOfferTerritoryOthersEnter = (event: any, elem: any): void => {
        event.preventDefault()

        const offerTerritoryOthersList = [...offerTerritoryOthers];
        offerTerritoryOthersList.push(event.target.value)
        setOfferTerritoryOthers(offerTerritoryOthersList)
        setValue('offerTerritoryOthers', offerTerritoryOthersList)
        elem.target.value = ''
    }

    const updateOfferTerritoryOthersAdd = (): void => {
        const offerTerritoryOthersList = [...offerTerritoryOthers];
        offerTerritoryOthersList.push(nameOther)
        setOfferTerritoryOthers(offerTerritoryOthersList)
        setValue('offerTerritoryOthers', offerTerritoryOthersList)
        setNameOther('')
        addOtherTerritoryElem.target.value = ''
    }

    const onChangeProvidersAvailable = (checked: boolean | undefined, key: number): void => {
        let providersAvailableList: number[] = [...providersAvailable];

        if (checked) {
            providersAvailableList.push(key)
        } else {
            providersAvailableList = providersAvailableList.filter(k => k !== key)
        }

        setProvidersAvailable(providersAvailableList)
        setValue('providersAvailable', providersAvailableList)
    }

    const isProvidersAvailableChecked = (key: number, dataList: number[]): boolean => {
        return dataList.length ? dataList?.includes(key) : false
    }

    const onChangeProvidersAvailableOthers = (checked: boolean | undefined, info: string): void => {
        let providersAvailableOthersList: string[] = [...providersAvailableOthers];

        if (!checked) {
            providersAvailableOthersList = providersAvailableOthersList.filter(o => o !== info)
        }

        setProvidersAvailableOthers(providersAvailableOthersList)
        setValue('providersAvailableOthers', providersAvailableOthersList)
    }

    const isProvidersAvailableOthersChecked = (data: string, dataList: string[]): boolean => {
        return dataList.length ? dataList?.includes(data) : false
    }

    const updateProvidersAvailableOthersEnter = (event: any, elem: any): void => {
        event.preventDefault()

        const providersAvailableOthersList = [...providersAvailableOthers];
        providersAvailableOthersList.push(event.target.value)
        setProvidersAvailableOthers(providersAvailableOthersList)
        setValue('providersAvailableOthers', providersAvailableOthersList)
        elem.target.value = ''
    }

    const updateProvidersAvailableOthersAdd = (): void => {
        const providersAvailableOthersList = [...providersAvailableOthers];
        providersAvailableOthersList.push(nameOther2)
        setProvidersAvailableOthers(providersAvailableOthersList)
        setValue('providersAvailableOthers', providersAvailableOthersList)
        setNameOther('')
        addOtherProvidersElem.target.value = ''
    }

    const create = (dataForm: SolidarityBankProps): void => {
        setIsLoading(true)
        createSolidarityBank(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('congrats')
            changeLastStep(6)
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const update = (dataForm: SolidarityBankProps): void => {
        setIsLoading(true)
        updateSolidarityBank(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('congrats')
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const onSubmit: SubmitHandler<SolidarityBankProps> = data => {
        if (allowSubmit) {
            updateAction ? update(data) : create(data)
        }
    }

    return (
        <Container
            countInitiatives={currentStage === 'parte-1' ? offerTerritoryAvailable.length : providersAvailable.length}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                {currentStage === 'parte-1' ?
                    <div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="mb-24 text-black">
                                    Queremos fortalecer as trocas entre atores e iniciativas do território, dito isso, gostaríamos de entender se:
                                </div>

                                <div className="row">
                                    <div className="col-md-6 field-col-align">
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Sua iniciativa tem algo a oferecer para o território?'
                                            placeholder='Selecione ...'
                                            value={offerTerritory}
                                            options={offerDefaultOptions}
                                            onChange={function (e, { value }) {
                                                setOfferTerritory(value)
                                                setValue('offerTerritory', value as number)
                                            }}
                                        />
                                        <div className="obs">
                                            (ex. espaço para eventos, equipamentos, materiais, sala de informática, espaço de coworking, estúdio de gravação, biblioteca, quadras, etc.)
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="label-form-default">Selecione as opções disponíveis:</label>
                                        <div className="field">
                                            <Dropdown
                                                placeholder='Selecione ...'
                                                fluid
                                                multiple
                                                search
                                                selection
                                                clearable
                                                options={offerTerritoryAvailableOptions}
                                                value={offerTerritoryAvailable}
                                                onChange={function (e, { value }) {
                                                    setOfferTerritoryAvailable(value)
                                                    setValue('offerTerritoryAvailable', value as number[])
                                                }}
                                            >
                                                <Dropdown.Menu>
                                                    {offerTerritoryAvailableOptions.map(op => (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                let resValues = []

                                                                if (offerTerritoryAvailable.includes(op.key)) {
                                                                    resValues = offerTerritoryAvailable.filter((item: number) => item !== op.key)
                                                                } else {
                                                                    resValues = [...offerTerritoryAvailable, op.key]
                                                                }

                                                                setOfferTerritoryAvailable(resValues)
                                                                setValue('offerTerritoryAvailable', resValues as number[])
                                                            }}
                                                        >
                                                            <Checkbox
                                                                label={op.text}
                                                                checked={offerTerritoryAvailable.includes(op.key)}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {userData.permissaos_id === 3 &&
                                <div className="col-md-4 pos-relative">
                                    <div className="new-initiatives">
                                        <div className="text">
                                            Selecione as opções já cadastradas ao lado ou adicione novas opções ainda não cadastradas:
                                </div>

                                        <div className="initiatives-list-others">
                                            {offerTerritoryAvailable.length > 0 &&
                                                <div className="initiatives-list">
                                                    {offerTerritoryAvailableOptions.map((o, index) => {
                                                        return isOfferTerritoryAvailableChecked(o.key, offerTerritoryAvailable) && (
                                                            <div className="col-md-12" key={o.key}>
                                                                <Checkbox
                                                                    label={o.text}
                                                                    checked={isOfferTerritoryAvailableChecked(o.key, offerTerritoryAvailable)}
                                                                    onChange={(e, { checked }) => {
                                                                        onChangeOfferTerritoryAvailable(checked, o.key)
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {offerTerritoryOthers.length > 0 &&
                                                <div className="initiatives-others">
                                                    <hr />
                                                    {offerTerritoryOthers.map((info, index) => (
                                                        <div className="col-md-12" key={index}>
                                                            <Checkbox
                                                                label={info}
                                                                checked={isOfferTerritoryOthersChecked(info, offerTerritoryOthers)}
                                                                onChange={(e, { checked }) => {
                                                                    onChangeOfferTerritoryOthers(checked, info)
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>

                                        <div className="other-input">
                                            <Form.Input
                                                fluid
                                                labelPosition='right'
                                                defaultValue={addOtherTerritory}
                                            >
                                                <Label basic><div className="prefixo">Outro:</div></Label>
                                                <input
                                                    onChange={(event: any) => {
                                                        setNameOther(event.target.value)
                                                    }}
                                                    onKeyDown={(event: any) => {
                                                        setAddOtherTerritoryElem(event)

                                                        if (event.key === 'Enter') {
                                                            updateOfferTerritoryOthersEnter(event, addOtherTerritoryElem)
                                                        }
                                                    }}
                                                />
                                                <Label
                                                    style={{ cursor: 'pointer' }}
                                                    className="add-icon"
                                                    onClick={() => {
                                                        updateOfferTerritoryOthersAdd()
                                                    }}
                                                >
                                                    <img src={addIcon} alt="add-icon" />
                                                </Label>
                                            </Form.Input>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="footer">
                            <hr />
                            <div className="btn-actions f-right">
                                <Button
                                    type="button"
                                    text="Anterior"
                                    className="radius-100 btn-outline-gray mr-32"
                                    onClick={() => setCurrentPage('networks-partners')}
                                />

                                {showNextRegisterButton(updateAction) &&
                                    <Button
                                        type="submit"
                                        text="Próxima"
                                        className="radius-100"
                                        onClick={() => setAllowSubmit(true)}
                                    />
                                }
                            </div>
                        </div>
                    </div> :
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-24 text-black">
                                    Queremos fortalecer as trocas entre atores e iniciativas do território, dito isso, gostaríamos de entender se:
                            </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Controller
                                            name="hasProvider"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <Form.Select
                                                        fluid
                                                        size="huge"
                                                        label='Sua iniciativa tem algum prestador de serviços/produtos, seja pro bono ou não, com diferenciais como preço e qualidade para recomendar outros do território?'
                                                        placeholder='Selecione ...'
                                                        value={value}
                                                        options={providerDefaultOptions}
                                                        onChange={function (e, { value }) {
                                                            onChange(value)
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                        <div className="obs">
                                            (ex. professor, carreto, lanches, costureira, estamparia, contador, fotógrafo, etc.)
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="label-form-default">
                                                    Ele é da periferia sul de São Paulo?
                                                </label>
                                                <div className="field">
                                                    <Controller
                                                        name="isSouthPeripherySP"
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <Form.Select
                                                                    fluid
                                                                    size="huge"
                                                                    // label='Sua iniciativa tem algum prestador de serviços/produtos, seja pro bono ou não, com diferenciais como preço e qualidade para recomendar outros do território?'
                                                                    placeholder='Selecione ...'
                                                                    value={value}
                                                                    options={defaultOptions}
                                                                    onChange={function (e, { value }) {
                                                                        onChange(value)
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            {false &&
                                                <div className="col-md-12">
                                                    <label className="label-form-default">
                                                        Com o que ele trabalha?
                                                </label>
                                                    <div className="field">
                                                        <Dropdown
                                                            placeholder='Selecione ...'
                                                            fluid
                                                            multiple
                                                            search
                                                            selection
                                                            clearable
                                                            options={providersAvailableOptions}
                                                            value={providersAvailable}
                                                            onChange={function (e, { value }) {
                                                                setProvidersAvailable(value)
                                                                setValue('providersAvailable', value as number[])
                                                            }}
                                                        >
                                                            <Dropdown.Menu>
                                                                {providersAvailableOptions.map(op => (
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            let resValues = []

                                                                            if (providersAvailable.includes(op.key)) {
                                                                                resValues = providersAvailable.filter((item: number) => item !== op.key)
                                                                            } else {
                                                                                resValues = [...providersAvailable, op.key]
                                                                            }

                                                                            setProvidersAvailable(resValues as number[])
                                                                            setValue('providersAvailable', resValues as number[])
                                                                        }}
                                                                    >
                                                                        <Checkbox
                                                                            label={op.text}
                                                                            checked={providersAvailable.includes(op.key)}
                                                                        />
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            }

                                            {(false && (providersAvailable.length || providersAvailableOthers.length)) ?
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Controller
                                                                name="name"
                                                                control={control}
                                                                rules={{ required: providersAvailable.length || providersAvailableOthers.length }}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <>
                                                                        <Form.Input
                                                                            fluid
                                                                            size="huge"
                                                                            className={errors.name && 'field-error'}
                                                                            placeholder='Escreva o nome do responsável'
                                                                            value={value}
                                                                            onChange={function (e, { value }) {
                                                                                onChange(value)
                                                                            }}
                                                                        >
                                                                            <label className="label-inside">Nome *</label>
                                                                            <input />
                                                                            <div
                                                                                className="img-icon flex-cc"
                                                                                onClick={() => {
                                                                                    onChange(undefined)
                                                                                    setValue('name', '')
                                                                                }}>
                                                                                <img src={closeInputIcon} alt="close-input" />
                                                                            </div>
                                                                        </Form.Input>

                                                                        {errors.name && <p className="field-error-msg">Campo obrigatório</p>}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <Controller
                                                                name="phone"
                                                                control={control}
                                                                rules={{ required: providersAvailable.length || providersAvailableOthers.length }}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <>
                                                                        <Form.Input
                                                                            fluid
                                                                            size="huge"
                                                                            className={errors.phone && 'field-error'}
                                                                            placeholder='(99) 99999-9999'
                                                                            value={value}
                                                                            onChange={function (e, { value }) {
                                                                                onChange(value)
                                                                            }}
                                                                        >
                                                                            <label className="label-inside">Telefone *</label>
                                                                            <input ref={withMask('(99) 99999-9999')} />
                                                                            <div
                                                                                className="img-icon flex-cc"
                                                                                onClick={() => {
                                                                                    onChange(undefined)
                                                                                    setValue('phone', '')
                                                                                }}>
                                                                                <img src={closeInputIcon} alt="close-input" />
                                                                            </div>
                                                                        </Form.Input>

                                                                        {errors.phone && <p className="field-error-msg">Campo obrigatório</p>}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <Controller
                                                                name="email"
                                                                control={control}
                                                                rules={{ required: providersAvailable.length || providersAvailableOthers.length }}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <>
                                                                        <Form.Input
                                                                            fluid
                                                                            size="huge"
                                                                            className={errors.email && 'field-error'}
                                                                            placeholder='Escreva o e-mail'
                                                                            value={value}
                                                                            onChange={function (e, { value }) {
                                                                                onChange(value)
                                                                            }}
                                                                        >
                                                                            <label className="label-inside">E-mail *</label>
                                                                            <input />
                                                                            <div
                                                                                className="img-icon flex-cc"
                                                                                onClick={() => {
                                                                                    onChange(undefined)
                                                                                    setValue('email', '')
                                                                                }}>
                                                                                <img src={closeInputIcon} alt="close-input" />
                                                                            </div>
                                                                        </Form.Input>

                                                                        {errors.email && <p className="field-error-msg">Campo obrigatório</p>}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {false &&
                                <div className="col-md-4 pos-relative">
                                    <div className="new-initiatives">
                                        <div className="text">
                                            Não encontrou com o que ele trabalha ao lado? Então adicione novas opções aqui.
                                        </div>

                                        <div className="initiatives-list-others">
                                            {providersAvailable.length > 0 &&
                                                <div className="initiatives-list">
                                                    {providersAvailableOptions.map((o, index) => {
                                                        return isProvidersAvailableChecked(o.key, providersAvailable) && (
                                                            <div className="col-md-12" key={o.key}>
                                                                <Checkbox
                                                                    label={o.text}
                                                                    checked={isProvidersAvailableChecked(o.key, providersAvailable)}
                                                                    onChange={(e, { checked }) => {
                                                                        onChangeProvidersAvailable(checked, o.key)
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {providersAvailableOthers.length > 0 &&
                                                <div className="initiatives-others">
                                                    <hr />
                                                    {providersAvailableOthers.map((info, index) => (
                                                        <div className="col-md-12" key={index}>
                                                            <Checkbox
                                                                label={info}
                                                                checked={isProvidersAvailableOthersChecked(info, providersAvailableOthers)}
                                                                onChange={(e, { checked }) => {
                                                                    onChangeProvidersAvailableOthers(checked, info)
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>

                                        <div className="other-input">
                                            <Form.Input
                                                fluid
                                                labelPosition='right'
                                                defaultValue={addOtherProviders}
                                            >
                                                <Label basic><div className="prefixo">Outro:</div></Label>
                                                <input
                                                    onChange={(event: any) => {
                                                        setNameOther2(event.target.value)
                                                    }}
                                                    onKeyDown={(event: any) => {
                                                        setAddOtherProvidersElem(event)

                                                        if (event.key === 'Enter') {
                                                            updateProvidersAvailableOthersEnter(event, addOtherProvidersElem)
                                                        }
                                                    }} />
                                                <Label
                                                    style={{ cursor: 'pointer' }}
                                                    className="add-icon"
                                                    onClick={() => {
                                                        updateProvidersAvailableOthersAdd()
                                                    }}
                                                >
                                                    <img src={addIcon} alt="add-icon" />
                                                </Label>
                                            </Form.Input>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="footer">
                            <hr />
                            <div className="btn-actions f-right">
                                <Button
                                    type="button"
                                    text="Anterior"
                                    className="radius-100 btn-outline-gray mr-32"
                                    onClick={() => {
                                        setCurrentStage('parte-1')
                                        setAllowSubmit(false)
                                    }}
                                />
                                {showNextRegisterButton(updateAction) &&
                                    <Button
                                        type="submit"
                                        text="Próxima"
                                        className="radius-100"
                                        onClick={() => setAllowSubmit(true)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </Form>
        </Container>
    )
}