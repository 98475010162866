import { api, getConfigApi } from "../../services/api"

type LoginProps = {
    email: string,
    password: string
    is_admin: boolean
}

export const login = async (args: LoginProps): Promise<unknown> => {
    return await api.post('login', { ...args })
}

export const logout = async (): Promise<unknown> => {
    try {
        const response = await api.post('logout', {}, getConfigApi())
        const { data } = response

        if (!data) {
            console.error('Erro ao realizar logout')
        }

        return data
    } catch (error) {
        console.error(error)
    }

    return null
}

export const requestNewPassword = async (args: any): Promise<unknown> => {
    return await api.post('security/esquecer-senha', { ...args })
}

export const newPasswordSecurity = async (args: any): Promise<unknown> => {
    return await api.post('security/nova-senha', { ...args })
}
