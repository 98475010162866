import { Button } from "../../../designSystems/components/Button"
import leftArrow from "../../../assets/img/icons/left-arrow-white.svg"
import illustraMap from '../../../assets/img/illustra-map.svg'
import fundacaoAbh from '../../../assets/img/fundacao-abh.svg'
import fundacaoMboi from '../../../assets/img/fundacao-mboi.svg'
import { useNavigate } from "react-router"
import { Container } from "./style"
import { useContext, useState } from "react"
import { Form } from "semantic-ui-react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { GlobalContext } from "../../../context/GlobalContext"
import { requestNewPassword } from "../../../api/authentication"

type Props = {
    email: string
}

export const RequestNewPasswordPage = (): JSX.Element => {
    const { control, handleSubmit, formState: { errors } } = useForm<Props>(
        { defaultValues: { email: '' } }
    );
    const { setIsLoading, setDataToast } = useContext(GlobalContext)

    const navigate = useNavigate()

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const onSubmit: SubmitHandler<Props> = data => {
        const result = { ...data }

        setIsLoading(true)
        requestNewPassword(result).then((response: any) => {
            setIsLoading(false)
            setDataToast({ show: true, text: response.data.message })
            goToPage('/login')
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao solicitar nova senha' : err.response.data.message
            setDataToast({ show: true, text: textError })
        })
    }

    return (
        <Container>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="left-content">
                            <div className="btn-back">
                                <Button
                                    text={'Voltar ao mapa'}
                                    icon={leftArrow}
                                    height="42px"
                                    background="transparent"
                                    className="radius-8 float-left btn-register"
                                    onClick={e => goToPage('/mapa')}
                                />
                            </div>

                            <div className="box-container flex-cc">
                                <div className="title-content">
                                    Não se preocupe, você irá recuperar sua senha
                                </div>

                                <div className="box flex-cc">
                                    <div className="d-block text-center">
                                        <div className="title">Solicitar nova senha</div>
                                        <div>Escreva o seu email para solicitar uma nova senha</div>

                                        <div className="flex-cc">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "invalid email address"
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <Form.Input
                                                                fluid
                                                                size="huge"
                                                                type="email"
                                                                placeholder='exemplo@exemplo.com'
                                                                className={errors.email && 'field-error'}
                                                                value={value}
                                                                onChange={function (e, { value }) {
                                                                    onChange(value)
                                                                }}
                                                            >
                                                                <input />
                                                            </Form.Input>
                                                            {errors.email?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                                            {errors.email?.type === 'pattern' && <p className="field-error-msg">E-mail inválido</p>}
                                                        </>
                                                    )}
                                                />

                                                <Button className="btn-submit radius-100" type="submit" text="Enviar Solicitação" onClick={(e) => e.prevent} />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="right-content flex-cc">
                            <img src={illustraMap} alt="home-map" />
                        </div>
                        <div className='row margin-top-20'>
                            <div className="col-md-12">
                                <div className="display: flex; justify-content: center text-center">
                                    <img src={fundacaoAbh} alt="bg-img-home" />
                                    <img src={fundacaoMboi} alt="bg-img-home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Container>
    )
}