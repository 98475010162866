import { useContext, useEffect, useState } from "react";
import { Container } from "./style"
import { Navbar } from '../../../designSystems/components/Navbar';
import logo from '../../../assets/img/logo.svg';
import userImg from '../../../assets/img/user-imag.svg';
import gearIcon from '../../../assets/img/icons/gear-fill.svg';
import buildingIcon from '../../../assets/img/icons/building.svg';
import bullhornIcon from '../../../assets/img/icons/bullhorn-solid.svg';
import bgAdmin from '../../../assets/img/bg-admin.svg';
import { CompanyProps } from "../../../models/CompanyProps";
import { InitiativesContent } from "../../components/InitiativesContent";
import { ConfigContent } from "../../components/ConfigContent";
import { AdsContent } from "../../components/AdsContent";
import { RegisterPage } from "../Register";
import { AdminPageContext } from "../../../context/AdminPageContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { getAdminInitiatives } from "../../../api/admin";
import { useNavigate } from "react-router";

export const AdminPage = (): JSX.Element => {
    const [isEdition, setIsEdition] = useState(false)
    const [isInitialRegister, setIsInitialRegister] = useState(false)
    const [currentCompany, setCurrentCompany] = useState<CompanyProps>({} as CompanyProps)

    const { setUserData, setUserId, setIsLoading, setDataToast, getErrorMessage, vertifyAuthenticatedRedirect, userData, menuItemActivated, setMenuItemActivated } = useContext(GlobalContext)

    const [companies, setCompanies] = useState<CompanyProps[]>([] as CompanyProps[])

    const navigate = useNavigate()

    const leftMenu = [
        {
            show: true,
            text: 'Iniciativas',
            alias: 'iniciativas',
            icon: buildingIcon
        },
        {
            show: true,
            text: 'Configurações',
            alias: 'config',
            icon: gearIcon
        },
        {
            show: [1, 2, 4, 200].includes(userData?.permissaos_id || 0),
            text: 'Anúncios',
            alias: 'anuncios',
            icon: bullhornIcon
        }
    ]

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = (): void => {
        let user: any = JSON.parse(localStorage.getItem('usuario') || localStorage.getItem('authentication-admin') || '{}') || null;

        if (Object.keys(user).length) {
            setUserData(user.user)
            setUserId(user.user.id)
        }
    }

    const fetchData = (): void => {
        setIsLoading(true)

        getAdminInitiatives().then((response: any) => {
            setIsLoading(false)
            const resultData: CompanyProps[] = response?.data?.data

            if (resultData.length) {
                setCompanies(resultData)
            }
            // fillForm(responseData)
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    return (
        <Container>
            <Navbar
                logo={logo}
                userImg={userImg}
                isAdminPage
                setActionBooelan={setIsEdition}
            />

            <AdminPageContext.Provider
                value={{
                    isEdition, setIsEdition, currentCompany, setCurrentCompany, isInitialRegister, setIsInitialRegister
                }}
            >
                <div style={{ paddingTop: 56 }}>
                    {isEdition && menuItemActivated === 'iniciativas' ?
                        <RegisterPage isAdminPage isInitialRegister={isInitialRegister} fetchData={() => fetchData()} /> :
                        <main>
                            {['iniciativas', 'anuncios'].includes(menuItemActivated) &&
                                <div className="bg-admin">
                                    <img src={bgAdmin} alt="" />
                                </div>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-1">
                                        <div className="left-sidebar">
                                            <div className="menu">
                                                {leftMenu.map((lm: any, index: number) => (
                                                    <div
                                                        key={index}
                                                        className={`menu-item ${menuItemActivated === lm.alias ? 'active' : ''} flex-cc ${!lm.show && 'd-none'}`}
                                                        onClick={() => {
                                                            setIsEdition(false)
                                                            setMenuItemActivated(lm.alias)
                                                        }}
                                                    >
                                                        <div className="circle flex-cc">
                                                            <div className="icon">
                                                                <img src={lm.icon} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="text">{lm.text}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-10">
                                        {menuItemActivated === 'iniciativas' ?
                                            <InitiativesContent companies={companies} fetchData={() => fetchData()} /> :
                                            menuItemActivated === 'config' ? <ConfigContent /> :
                                                <AdsContent />
                                        }
                                    </div>

                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                        </main>
                    }
                </div>
            </AdminPageContext.Provider>
            <div className="bar-footer"></div>
        </Container>
    )
}