import { Variables } from './../../../styles/variables'
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    padding-bottom: 50px;
    min-height: calc(100vh - 90px);

    hr {
        border: 0.5px solid rgba(81, 98, 116, 0.5);
    }

    .ui {
        &.form {
            position: unset;
        }
    }

    .files-content {
        margin-bottom: 80px;
    }

    .files-content,
    .config-content {
        .title {
            display: flex;
            margin-bottom: 32px;

            .text {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                min-width: 166px;
            }

            hr {
                border: 0.5px solid #516274;
                width: 100%;
                margin-left: 21px;
            }
        }

        .files-section {
            display: inline-block;

            .files-btn {
                width: auto;
            }
        }

        .files-section,
        .config-section {
            .field {
                margin-bottom: 14px;
            }
            
            .register_profile_image{
                margin-top: 15px;

                .btn {
                    background: ${Variables.gray};
                    color: #FFF;
                    padding: 8px 20px;
                    display: inline-flex;
                    align-items: center;
                    transition: background .15s ease-in-out;

                    span {
                        color: #FFF;
                    }

                    :hover {
                        background: #66788a;
                    }

                    .download-icon {
                        margin-right: 11px;
                    }
                }
            }

            .previewProfilePic {
                position: relative;
                display: flex;
                margin-top: 15px;

                .trash {
                    cursor: pointer;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 16px;
                    height: 18px;
                }

                .text {
                    margin-left: 24px;
                    margin-right: 24px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #49454F;
                    word-break: break-all;
                }

                img {
                    height: 86px;
                }
            }

            .receive-ad {
                margin-top: 50px;
            }
        }
    }

    .accounts-content {
        margin: 44px 0px;

        .title {
            display: flex;
            margin-bottom: 32px;

            .text {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                min-width: 163px;
            }

            hr {
                border: 0.5px solid #516274;
                width: 100%;
                margin-left: 21px;
                margin-right: 21px;
                margin-top: 19px;
            }
        }

        .account {
            margin-top: 36px;
            margin-bottom: 36px;
            position: relative;

            .trash-icon {
                padding: 0px;
                cursor: pointer;
                position: absolute;
                bottom: 0px;
                right: 14px;
                z-index: 1;

                img {
                    width: 16px;
                    height: 18px;
                }
            }

            .field {
                margin-top: 21px;

                .ui {
                    &.checkbox {
                        margin-right: 25px;
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        position: absolute;
        bottom: 46px;
    }

    .modal {
        &.delete-modal {
            .modal-dialog {
                width: 312px;
                .modal-content {
                    padding: 24px;
                }
            }
        }
        
        &.new-account-modal {
            .modal-dialog {
                width: 412px;
                .modal-content {
                    padding: 24px;

                    .modal-body {
                        .fields {
                            .field {
                                .ui {
                                    &.checkbox {
                                        width: 100%;
                                        margin-right: 25px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        &.change-password-modal {
            .modal-dialog {
                width: 412px;
                .modal-content {
                    padding: 24px;
                }
            }
        }
    }

    @media (max-width: 575.98px) {
        .accounts-content {
            .account {
                margin: 24px 0px;

                .row {
                    margin-bottom: 0px;
                }

                .field {
                    &.type {
                        padding-bottom: 30px;
                    }
                }

                .col-md-4 {
                    margin-bottom: 14px;
                }

                .col-md-12 {
                    .field {
                        margin: 0px;
                    }
                }
            }
        }
    }
`