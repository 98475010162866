import styled from 'styled-components'
import { Variables } from './../../../styles/variables'

export const Container = styled.div`
.initiative-resume {
    width: 360px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #79747E;
    border-radius: 12px;

    .head {
        height: auto;
        max-height: 72px;
        padding: 16px;

        .info {
            display: flex;
            
            img {
                height: 36px;
                margin-right: 16px;
            }
    
            .title {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #1C1B1F;
                text-transform: uppercase;
            }
        }
    }

    .body {
        .first-info {
            padding: 16px;

            .ods {
                display: flex;
                justify-content: center;

                .image {
                    img {
                        width: 80px;
                    }
                    
                    &:not(:last-of-type) {
                        margin-right: 8px;
                    }
                }
            }

            .thematics {
                margin-top: 16px;

                .thematic {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    text-align: left;
                    font-weight: 600;
                    margin-bottom: 8px;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    text-transform: uppercase;
                    color: #1C1B1F;

                    img {
                        margin-right: 16px;
                    }
                }
            }

            .description {
                margin-top: 16px;
                text-align: justify;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #516274;
            }
        }

        .solidarity {
            padding: 16px;
            border-bottom: 1px solid #79747E;

            .title {
                text-align: left;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
                letter-spacing: 0.1px;
                text-transform: uppercase;
                color: #1C1B1F;
            }

            .items {
                .item {
                    text-align: left;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: baseline;

                    img {
                        margin-right: 10px;
                        width: 13px;
                        height: 10px;
                    }

                    .text {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }

        .about-initiative {
            padding: 16px;
            border-bottom: 1px solid #79747E;

            .title {
                text-align: left;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
                letter-spacing: 0.1px;
                text-transform: uppercase;
                color: #1C1B1F;
            }

            .item {
                text-align: left;
                margin-bottom: 6px;
                display: flex;
                align-items: start;

                img {
                    margin-right: 10px;
                    height: 24px;
                }

                .text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }

                span {
                    word-break: break-all;
                }
            }
        }

        .final {
            padding: 16px;
            text-align: left;
            border-bottom: 1px solid #79747E;

            .title {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
                letter-spacing: 0.1px;
                text-transform: uppercase;
                color: #1C1B1F;
            }
        }
    }

    .footer {
        padding: 16px;

        .select-networks {
            display: flex;
            align-items: center;
            justify-content: space-between;
        
            .item {
                border-radius: 100px;
        
                &.selected {
                    background: ${Variables.yellow};
                }
            }
        }
    }
}`