import { useContext, useEffect, useState } from "react"
import { Form } from "semantic-ui-react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { FinancialProps } from "../../../models/FinancialProps"
import { GlobalContext } from "../../../context/GlobalContext"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { createFinancialContent, getFinancialContent, updateFinancialContent } from "../../../api/financial"
import { annualBudgetOptions, monthlyBillingOptions } from "../../../api/utils/fieldsOptions"

const defaultOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Sim', value: 1 },
    { key: 2, text: 'Não', value: 2 },
]

export const FinancialContent = (): JSX.Element => {
    const [updateAction, setUpdateAction] = useState(false)

    const { setCurrentPage, handleScroll } = useContext(RegisterPageContext)
    const { setIsLoading, setDataToast, changeLastStep, getErrorMessage, vertifyAuthenticatedRedirect, userId, userData, pathnameIsAdmin, showNextRegisterButton } = useContext(GlobalContext)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm<FinancialProps>(
        {
            defaultValues: {
                id: undefined,
                annualBudget: undefined,
                monthlyBilling: undefined,
                hasCounter: undefined,
                hasEquip: undefined,
            }
        }
    );

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        getFinancialContent(pathnameIsAdmin() ? { user_id: userId } : null).then((response: any) => {
            setIsLoading(false)

            if (response.data.data.length) {

                setUpdateAction(true)
                const responseData: FinancialProps = response.data.data[0]
                fillForm(responseData)
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const fillForm = (data: FinancialProps): void => {
        setValue('id', data.id)
        setValue('annualBudget', data.annualBudget)
        setValue('monthlyBilling', data.monthlyBilling)
        setValue('hasCounter', data.hasCounter)
        setValue('hasEquip', data.hasEquip)
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const create = (dataForm: FinancialProps): void => {
        setIsLoading(true)
        createFinancialContent(pathnameIsAdmin() ? { user_id: userId } : null, dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('networks-partners')
            changeLastStep(4)
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const update = (dataForm: FinancialProps): void => {
        setIsLoading(true)
        updateFinancialContent(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('networks-partners')
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const onSubmit: SubmitHandler<FinancialProps> = data => {
        updateAction ? update(data) : create(data)
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    {userData.tipo_organizacao_id === 1 &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="annualBudget"
                                control={control}
                                rules={{ required: userData.tipo_organizacao_id === 1 }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Qual é o orçamento anual da sua iniciativa? *'
                                            placeholder='Selecione ...'
                                            className={errors.annualBudget && 'field-error'}
                                            value={value}
                                            options={annualBudgetOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.annualBudget && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {userData?.tipo_organizacao_id === 4 &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="monthlyBilling"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='Qual é o faturamento mensal da sua iniciativa? *'
                                            placeholder='Selecione ...'
                                            className={errors.monthlyBilling && 'field-error'}
                                            value={value}
                                            options={monthlyBillingOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.monthlyBilling && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {[1, 4].includes(userData?.tipo_organizacao_id as number)  &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="hasCounter"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='A iniciativa possui um contador dedicado? *'
                                            placeholder='Selecione ...'
                                            className={errors.hasCounter && 'field-error'}
                                            value={value}
                                            options={defaultOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.hasCounter && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }

                    {[1, 4].includes(userData?.tipo_organizacao_id as number)  &&
                        <div className="col-md-4 field-col-align">
                            <Controller
                                name="hasEquip"
                                control={control}
                                rules={{ required: userData.tipo_organizacao_id === 1 }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='A iniciativa tem equipe dedicada para captação de recursos? *'
                                            placeholder='Selecione ...'
                                            className={errors.hasEquip && 'field-error'}
                                            value={value}
                                            options={defaultOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.hasEquip && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }
                </div>

                <div className="footer">
                    <hr />
                    <div className="btn-actions f-right">
                        <Button
                            text="Anterior"
                            className="radius-100 btn-outline-gray mr-32"
                            onClick={() => setCurrentPage('about-initiative')}
                        />
                        {showNextRegisterButton(updateAction) &&
                            <Button
                                type="submit"
                                text="Próxima"
                                className="radius-100"
                                onClick={() => Object.keys(errors).length && handleScroll()}
                            />
                        }
                    </div>
                </div>
            </Form>
        </Container>
    )
}