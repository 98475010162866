import imgTipo1 from '../../assets/img/icons/tipos/tipo-01.svg'
import imgTipo2 from '../../assets/img/icons/tipos/tipo-02.svg'
import imgTipo3 from '../../assets/img/icons/tipos/tipo-03.svg'
import imgTipo4 from '../../assets/img/icons/tipos/tipo-04.svg'
import ods1 from "../../assets/img/icons/ods/E-WEB-Goal-01.svg"
import ods2 from "../../assets/img/icons/ods/E-WEB-Goal-02.svg"
import ods3 from "../../assets/img/icons/ods/E-WEB-Goal-03.svg"
import ods4 from "../../assets/img/icons/ods/E-WEB-Goal-04.svg"
import ods5 from "../../assets/img/icons/ods/E-WEB-Goal-05.svg"
import ods6 from "../../assets/img/icons/ods/E-WEB-Goal-06.svg"
import ods7 from "../../assets/img/icons/ods/E-WEB-Goal-07.svg"
import ods8 from "../../assets/img/icons/ods/E-WEB-Goal-08.svg"
import ods9 from "../../assets/img/icons/ods/E-WEB-Goal-09.svg"
import ods10 from "../../assets/img/icons/ods/E-WEB-Goal-10.svg"
import ods11 from "../../assets/img/icons/ods/E-WEB-Goal-11.svg"
import ods12 from "../../assets/img/icons/ods/E-WEB-Goal-12.svg"
import ods13 from "../../assets/img/icons/ods/E-WEB-Goal-13.svg"
import ods14 from "../../assets/img/icons/ods/E-WEB-Goal-14.svg"
import ods15 from "../../assets/img/icons/ods/E-WEB-Goal-15.svg"
import ods16 from "../../assets/img/icons/ods/E-WEB-Goal-16.svg"
import ods17 from "../../assets/img/icons/ods/E-WEB-Goal-17.svg"

export const defaultOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Sim', value: 1 },
    { key: 2, text: 'Não', value: 2 },
]

export const cityActionsOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'São Paulo', value: 1 },
    { key: 2, text: 'Taboão da Serra', value: 2 },
    { key: 3, text: 'Embu das Artes', value: 3 },
    { key: 4, text: 'Embu Guaçu', value: 4 },
    { key: 5, text: 'São Bernardo do Campo', value: 5 },
    { key: 6, text: 'Diadema', value: 6 },
    { key: 7, text: 'São Caetano do Sul', value: 7 },
    { key: 8, text: 'Itapecerica da Serra', value: 8 },
    { key: 9, text: 'Todas', value: 9 },
]

export const districtLocaleOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 0, text: 'Todo São Paulo', value: 0 },
    { key: 100, text: 'Cambuci', value: 100 },
    { key: 200, text: 'Campo Belo', value: 200 },
    { key: 300, text: 'Campo Grande', value: 300 },
    { key: 400, text: 'Campo Limpo', value: 400 },
    { key: 500, text: 'Capão Redondo', value: 500 },
    { key: 600, text: 'Cidade Ademar', value: 600 },
    { key: 700, text: 'Cidade Dutra', value: 700 },
    { key: 800, text: 'Cursino', value: 800 },
    { key: 900, text: 'Grajaú', value: 900 },
    { key: 1010, text: 'Ipiranga', value: 1010 },
    { key: 1110, text: 'Itaim Bibi', value: 1110 },
    { key: 1210, text: 'Itaim Paulista', value: 1210 },
    { key: 1310, text: 'Jabaquara', value: 1310 },
    { key: 1410, text: 'Jardim Ângela', value: 1410 },
    { key: 1510, text: 'Jardim Paulista', value: 1510 },
    { key: 1610, text: 'Jardim São Luís', value: 1610 },
    { key: 1710, text: 'Marsilac', value: 1710 },
    { key: 1810, text: 'Moema', value: 1810 },
    { key: 1910, text: 'Morumbi', value: 1910 },
    { key: 2010, text: 'Parelheiros', value: 2010 },
    { key: 2110, text: 'Pedreira', value: 2110 },
    { key: 2210, text: 'Sacomã', value: 2210 },
    { key: 2310, text: 'Santo Amaro', value: 2310 },
    { key: 2410, text: 'Saúde', value: 2410 },
    { key: 2510, text: 'Socorro', value: 2510 },
    { key: 2610, text: 'Vila Andrade', value: 2610 },
    { key: 2710, text: 'Vila Mariana', value: 2710 },
    { key: 2810, text: 'Vila Sônia', value: 2810 },
]

export const productTypeOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 1, value: 1, text: 'Alimentos' },
    { key: 2, value: 2, text: 'Comunicação' },
    { key: 3, value: 3, text: 'Produtos de Higiene' },
    { key: 4, value: 4, text: 'Vestuário' },
    { key: 5, value: 5, text: 'Outros (especifique)' }
]

export const serviceTypeOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 100, value: 100, text: 'Sou Artista' },
    { key: 200, value: 200, text: 'Audiovisual' },
    { key: 300, value: 300, text: 'Cabelereiro' },
    { key: 400, value: 400, text: 'Catering/Alimentação' },
    { key: 500, value: 500, text: 'Comunicação' },
    { key: 600, value: 600, text: 'Consultoria' },
    { key: 700, value: 700, text: 'Contabilidade' },
    { key: 800, value: 800, text: 'Jurídico' },
    { key: 900, value: 900, text: 'Logístico' },
    { key: 1010, value: 1010, text: 'Marceneiro' },
    { key: 1110, value: 1110, text: 'Produção de Eventos' },
    { key: 1210, value: 1210, text: 'Outros (especifique)' }
]

export const offerTerritoryAvailableOptions = [
    { key: 1, text: 'Biblioteca', value: 1 },
    { key: 2, text: 'Espaço para eventos', value: 2 },
    { key: 3, text: 'Equipamentos', value: 3 },
    { key: 4, text: 'Materiais', value: 4 },
    { key: 5, text: 'Sala de informática', value: 5 },
    { key: 6, text: 'Espaço de coworking', value: 6 },
    { key: 7, text: 'Estúdio de gravação', value: 7 },
    { key: 8, text: 'Quadras', value: 8 }
]

export const providersAvailableOptions = [
    { key: 1, text: 'Professor', value: 1 },
    { key: 2, text: 'Carreto', value: 2 },
    { key: 3, text: 'Lanches', value: 3 },
    { key: 4, text: 'Costureira', value: 4 },
    { key: 5, text: 'Estamparia', value: 5 },
    { key: 6, text: 'Contador', value: 6 },
    { key: 7, text: 'Fotógrafo', value: 7 }
]

export const youAreOptions = [
    { key: 1, text: 'Um OSCs/Coletivos (formais e informais)', value: 1, icon: imgTipo1 },
    { key: 2, text: 'Uma empresa', value: 2, icon: imgTipo2 },
    { key: 3, text: 'Um equipamento público', value: 3, icon: imgTipo3 },
    { key: 4, text: 'Um MEI/Empreendedores/Prestadores de Serviço', value: 4, icon: imgTipo4 },
]

export const whatIdoOptions = [
    { key: 1, text: 'Presto serviços', value: 1 },
    { key: 2, text: 'Vendo produtos', value: 2 },
]

export const initiativeNatureOtions = [
    { key: 1, text: 'Advocacy', value: 1 },
    { key: 2, text: 'Apoio', value: 2 },
    { key: 3, text: 'Assistencial', value: 3 },
    { key: 4, text: 'Equipamento público', value: 4 },
    { key: 5, text: 'Formação', value: 5 },
    { key: 6, text: 'Pesquisa', value: 6 },
    { key: 7, text: 'Venda de Produtos', value: 7 },
    { key: 8, text: 'Prestação de Serviços', value: 8 },
    { key: 9, text: 'Outro (especificar)', value: 9 },
]

export const odsOptions = [
    { key: 100, value: 100, text: 'Erradicação da Pobreza', icon: ods1 },
    { key: 200, value: 200, text: 'Fome Zero e Agricultura Sustentável', icon: ods2 },
    { key: 300, value: 300, text: 'Saúde e Bem-Estar', icon: ods3 },
    { key: 400, value: 400, text: 'Educação de Qualidade', icon: ods4 },
    { key: 500, value: 500, text: 'Igualdade de Gênero', icon: ods5 },
    { key: 600, value: 600, text: 'Água Potável e Saneamento', icon: ods6 },
    { key: 700, value: 700, text: 'Energia Limpa e Acessível', icon: ods7 },
    { key: 800, value: 800, text: 'Trabalho Decente e Crescimento Econômico', icon: ods8 },
    { key: 900, value: 900, text: 'Indústria, Inovação e Infraestrutura', icon: ods9 },
    { key: 1010, value: 1010, text: 'Redução das Desigualdades', icon: ods10 },
    { key: 1110, value: 1110, text: 'Cidades e Comunidades Sustentáveis', icon: ods11 },
    { key: 1210, value: 1210, text: 'Consumo e Produção Responsáveis', icon: ods12 },
    { key: 1310, value: 1310, text: 'Ação Contra a Mudança Global do Clima', icon: ods13 },
    { key: 1410, value: 1410, text: 'Vida na Água', icon: ods14 },
    { key: 1510, value: 1510, text: 'Vida Terrestre', icon: ods15 },
    { key: 1610, value: 1610, text: 'Paz, Justiça e Instituições Eficazes', icon: ods16 },
    { key: 1710, value: 1710, text: 'Parcerias e Meios de Implementação', icon: ods17 },
]

export const areasExpertiseOptions = [
    { key: 100, text: 'Ações Afirmativas: raça, gênero e protagonismo jovem' },
    { key: 200, text: 'Advocacy & Incidência Política' },
    { key: 300, text: 'Arte & Cultura' },
    { key: 400, text: 'Assistência Social' },
    { key: 500, text: 'Bem Viver & Qualidade de Vida' },
    { key: 600, text: 'Comunicação & Marketing' },
    { key: 700, text: 'Defesa de direitos da população LGBTQIAP+' },
    { key: 800, text: 'Defesa de direitos da população negra' },
    { key: 900, text: 'Defesa de direitos das mulheres' },
    { key: 1010, text: 'Defesa de direitos dos povos indígenas' },
    { key: 1110, text: 'Desenvolvimento Comunitário' },
    { key: 1210, text: 'Direito dos Animais' },
    { key: 1310, text: 'Direitos humanos, justiça social e cidadania' },
    { key: 1410, text: 'Economia criativa e solidária' },
    { key: 1510, text: 'Educação' },
    { key: 1610, text: 'Esporte & Saúde' },
    { key: 1710, text: 'Habitação' },
    { key: 1810, text: 'Inclusão Digital' },
    { key: 1910, text: 'Inclusão Produtiva (empreendedorismo, empregabilidade e capacitação para o mercado de trabalho, geração de trabalho e renda)' },
    { key: 2010, text: 'Justiça Racial' },
    { key: 2110, text: 'Meio ambiente / Sustentabilidade' },
    { key: 2210, text: 'Mobilidade Urbana' },
    { key: 2310, text: 'Paz & Justiça' },
    { key: 2410, text: 'PCD - Pessoas com Deficiência' },
    { key: 2510, text: 'Pesquisa / Produção de Conhecimento' },
    { key: 2610, text: 'Refugiados' },
    { key: 2710, text: 'Saúde' },
    { key: 2810, text: 'Tecnologias Sociais' },
    { key: 2910, text: 'Outro (especifique)' },
]

export const publicOptions = [
    { key: 100, value: 100, text: 'Primeira Infância (0 a 6 anos)' },
    { key: 200, value: 200, text: 'Crianças (6 a 13 anos)' },
    { key: 300, value: 300, text: 'Adolecentes (13 a 18 anos)' },
    { key: 400, value: 400, text: 'Jovens (19 a 29 anos)' },
    { key: 500, value: 500, text: 'Adutos (30 a 59 anos)' },
    { key: 600, value: 600, text: 'Idosos (Acima de 60 anos)' },
    { key: 700, value: 700, text: 'Famílias' },
    { key: 800, value: 800, text: 'PCD (Pessoas com Deficiência)' },
    { key: 900, value: 900, text: 'Negros, LGBTQIAP+, Refugiados, Índios, Quilombolas' },
    { key: 1010, value: 1010, text: 'Mulheres' },
    { key: 1110, value: 1110, text: 'Comunidades' },
]

export const initiativeIsOtions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 100, text: 'Coletivo (sem CNPJ)', value: 100 },
    { key: 200, text: 'Empreendedor (MEI)', value: 200 },
    { key: 300, text: 'Empresa', value: 300 },
    { key: 400, text: 'Equipamento público', value: 400 },
    { key: 500, text: 'Escola particular', value: 500 },
    { key: 600, text: 'Fundação ou Instituto Comunitário (FIC)', value: 600 },
    { key: 700, text: 'Hospitais / Centro Clínico ou Terapêutico', value: 700 },
    { key: 800, text: 'Instituição do Sistema “S” (Senai, Senac, Sesi, Sebrae, Sesc)', value: 800 },
    { key: 900, text: 'Instituição religiosa', value: 900 },
    { key: 1010, text: 'Movimento', value: 1010 },
    { key: 1110, text: 'Negócio Social / Impacto / Startup', value: 1110 },
    { key: 1210, text: 'OSCs / ONGs / Entidades / Fundações / Institutos / Associações / Fundos (com CNPJ)', value: 1210 },
    { key: 1310, text: 'Rede', value: 1310 },
    { key: 1410, text: 'Universidade ou autarquia', value: 1410 },
]

export const legalFormatOtions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Associação', value: 1 },
    { key: 2, text: 'Cooperativa', value: 2 },
    { key: 3, text: 'Eireli / S.A. / LTDA.', value: 3 },
    { key: 4, text: 'Fundação', value: 4 },
    { key: 5, text: 'MEI', value: 5 },
    { key: 6, text: 'Sou informal', value: 6 },
    { key: 7, text: 'Outro (especifique)', value: 7 },
]

export const coverageAreaOtions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Bairro', value: 1 },
    { key: 2, text: 'Distrito', value: 2 },
    { key: 3, text: 'Município', value: 3 },
    { key: 4, text: 'Estado', value: 4 },
    { key: 5, text: 'Federação', value: 5 },
    { key: 6, text: 'Internacional', value: 6 },
]

export const motivatingPublicOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 100, text: 'Primeira infância (0 a 6 anos)', value: 100 },
    { key: 200, text: 'Crianças (6 a 13 anos)', value: 200 },
    { key: 300, text: 'Adolescentes (13 a 18 anos)', value: 300 },
    { key: 400, text: 'Jovens (19 a 29 anos)', value: 400 },
    { key: 500, text: 'Adultos (30 a 59 anos)', value: 500 },
    { key: 600, text: 'Idosos (Acima de 60 anos)', value: 600 },
    { key: 700, text: 'Famílias', value: 700 },
    { key: 800, text: 'PCD (Pessoa com Deficiência)', value: 800 },
    { key: 900, text: 'Negros, LGBTQIAP+, Refugiados, Índios, Quilombolas', value: 900 },
    { key: 1010, text: 'Mulheres', value: 1010 },
    { key: 1110, text: 'Comunidades', value: 1110 },
]

export const socialImpactDimensionOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 100, text: 'Melhora as condições de saúde', value: 100 },
    { key: 200, text: 'Melhora a educação', value: 200 },
    { key: 300, text: 'Melhora as condições de habitação', value: 300 },
    { key: 400, text: 'Melhora as condições ambientais urbanas', value: 400 },
    { key: 500, text: 'Melhora o meio ambiente', value: 500 },
    { key: 600, text: 'Melhora o saneamento e/ou água', value: 600 },
    { key: 700, text: 'Reduz os custos de transação (crédito, compras, empreendimentos)', value: 700 },
    { key: 800, text: 'Protege, socioambientalmente, áreas rurais e comunidades tradicionais', value: 800 },
    { key: 900, text: 'Diminui o risco de uma família cair em situações de pobreza extrema', value: 900 },
    { key: 1010, text: 'Melhora o protagonismo local', value: 1010 },
    { key: 1110, text: 'Gera emprego/renda', value: 1110 },
    { key: 1210, text: 'Desenvolve a comunidade', value: 1210 },
    { key: 1310, text: 'Outro (explicar)', value: 1310 },
]

export const hasTitleQualificationOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'OSCIP - Organização da Sociedade Civil de Interesse Público', value: 1 },
    { key: 2, text: 'UPF - Utilidade Pública Federal', value: 2 },
    { key: 3, text: 'UPE - Utilidade Pública Estadual', value: 3 },
    { key: 4, text: 'UPM - Utilidade Pública Municipal', value: 4 },
    { key: 5, text: 'CEBAS – Certificado de Entidade Beneficente de Assistência Social', value: 5 },
    { key: 6, text: 'Inscrição no CMAS – Conselho Municipal de Assistência Social', value: 6 },
    { key: 7, text: 'Inscrição no CNAS – Conselho Nacional de Assistência Social', value: 7 },
    { key: 8, text: 'Inscrição nos Conselhos de Direito Específico: Direitos da Criança e do Adolescente; de Saúde, do Idoso, da Pessoa com Deficiência, outros', value: 8 },
    { key: 9, text: 'Outros', value: 9 }
]

export const percentWomenOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: '100% da equipe são mulheres', value: 1 },
    { key: 2, text: '75% da equipe são mulheres', value: 2 },
    { key: 3, text: '50% da equipe são mulheres', value: 3 },
    { key: 4, text: '25% da equipe são mulheres', value: 4 },
    { key: 5, text: '0% da equipe são mulheres', value: 5 }
]

export const marketSegmentOptions = [
    { key: 'none', value: undefined, text: 'Selecione ...' },
    { key: 100, value: 100, text: 'Acessibilidade e Inclusão' },
    { key: 200, value: 200, text: 'Agricultura' },
    { key: 300, value: 300, text: 'Água e Saneamento' },
    { key: 400, value: 400, text: 'Alimentação e Nutrição' },
    { key: 500, value: 500, text: 'Artesanato' },
    { key: 600, value: 600, text: 'Audiovisual' },
    { key: 700, value: 700, text: 'Cidades: Mobilidade, Habitação e Transporte' },
    { key: 800, value: 800, text: 'Cidadania (Cultura, Esporte e Advocacy)' },
    { key: 900, value: 900, text: 'Ciência' },
    { key: 1010, value: 1010, text: 'Combate a Desigualdade' },
    { key: 1110, value: 1110, text: 'Comunicação e Jornalismo' },
    { key: 1210, value: 1210, text: 'Direitos Humanos' },
    { key: 1310, value: 1310, text: 'Educação e Formação' },
    { key: 1410, value: 1410, text: 'Empregabilidade e Geração de Renda' },
    { key: 1510, value: 1510, text: 'Energia' },
    { key: 1610, value: 1610, text: 'Governo' },
    { key: 1710, value: 1710, text: 'Literatura' },
    { key: 1810, value: 1810, text: 'Loja (varejo)' },
    { key: 1910, value: 1910, text: 'Longevidade' },
    { key: 2010, value: 2010, text: 'Meio Ambiente e Sustentabilidade' },
    { key: 2110, value: 2110, text: 'Moda' },
    { key: 2210, value: 2210, text: 'Prestação de serviço' },
    { key: 2310, value: 2310, text: 'Produtos e Serviços Financeiros' },
    { key: 2410, value: 2410, text: 'Reforma' },
    { key: 2510, value: 2510, text: 'Resíduos e Permacultura' },
    { key: 2610, value: 2610, text: 'Salão de Beleza' },
    { key: 2710, value: 2710, text: 'Saúde e Bem-estar' },
    { key: 2810, value: 2810, text: 'Suporte ao Empreendedorismo' },
    { key: 2910, value: 2910, text: 'Tecnologia' },
    { key: 3010, value: 3010, text: 'Turismo e Ecoturismo' },
    { key: 3110, value: 3110, text: 'Outro (especifique)' }
]

export const whichArtOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Circo', value: 1 },
    { key: 2, text: 'Poeta', value: 2 },
    { key: 3, text: 'Slam', value: 3 },
    { key: 4, text: 'Outros (especifique)', value: 4 },
]

export const documentsOptions = [
    { key: 100, text: 'Ata de Eleição da Atual Diretoria' },
    { key: 200, text: 'Ata de Fundação da organização e última atualização' },
    { key: 300, text: 'Auditoria externa' },
    { key: 400, text: 'Balanço Patrimonial do último exercício social' },
    { key: 500, text: 'Certidão Conjunta de Débitos relativos aos Tributos Federais, Certidões negativa de débito trabalhista (CNDT) e regularidade com o FGTS' },
    { key: 600, text: 'Certidão de isenção do Imposto de Transmissão Causa Mortis e Doação (ITCMD)' },
    { key: 700, text: 'Certidões negativas de débitos fiscais (CND) nos âmbitos federal, estadual e municipal' },
    { key: 800, text: 'Comprovante de inscrição no CNPJ' },
    { key: 900, text: 'Cópia do CPF/RG dos atuais representantes legais/sócios' },
    { key: 1010, text: 'Demonstração do Resultado do Exercício – DRE' },
    { key: 1110, text: 'Documentação sobre parcerias (quando couber), tais como cartas de intenção, termos e/ou contratos de parceria. Essa documentação deve demonstrar a natureza da parceria (financeira, técnica, de recursos humanos, materiais e outros) e ser assinada pelos responsáveis legais de cada uma das entidades envolvidas' },
    { key: 1210, text: 'Estatuto' },
    { key: 1310, text: 'Projetos aprovados em leis de incentivo' },
    { key: 1410, text: 'Orçamento Físico-Financeiro' },
    { key: 1510, text: 'Registro no respectivo Conselho Municipal dos Direitos da Criança e do Adolescente (exceto para aquelas organizações de Educação para o trabalho que atuem exclusivamente com jovens acima de 18 anos)' },
    { key: 1610, text: 'Resumo com o currículo (histórico de atuação) do responsável legal' },
    { key: 1710, text: 'Nenhum' },
]

export const approvedProjectLawsOptions = [
    { key: 100, text: 'Lei Rouanet' },
    { key: 200, text: 'Lei do Audiovisual' },
    { key: 300, text: 'Programa de Ação Cultural' },
    { key: 400, text: 'Lei de Incentivo ao Esporte' },
    { key: 500, text: 'PRONON - Programa Nacional de Oncologia' },
    { key: 600, text: 'PRONAS - Programa Nacional de Acessibilidade' },
    { key: 700, text: 'FIA - Fundo dos Direitos da Criança e do Adolescente' },
    { key: 800, text: 'Fundo Municipal do Idoso' },
    { key: 900, text: 'PROAC' },
    { key: 1010, text: 'PROMAC' },
    { key: 1110, text: 'Outro (especifique)' },
]

export const initiativeHasOptions = [
    { key: 1, text: 'Avaliação de impacto com equipe interna' },
    { key: 2, text: 'Avaliação de impacto com profissional ou instituição especializada' },
    { key: 3, text: 'Indicadores de desempenho' },
    { key: 4, text: 'Levantamentos diagnósticos e análises da realidade local realizado por equipe interna' },
    { key: 5, text: 'Levantamentos diagnósticos e análises da realidade local realizado por profissional especializado' },
    { key: 6, text: 'Planejamento Estratégico' },
    { key: 7, text: 'Processo de monitoramento de ações/projetos/programas' },
    { key: 8, text: 'Teoria da Mudança' },
    { key: 9, text: 'Não se aplica' }
]

export const annualBudgetOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Até R$ 50.000,00', value: 1 },
    { key: 2, text: 'Até R$100.000,00', value: 2 },
    { key: 3, text: 'Até R$300.000,00', value: 3 },
    { key: 4, text: 'Até R$500.000,00', value: 4 },
    { key: 5, text: 'Até R$1.000.000,00', value: 5 },
    { key: 6, text: 'Mais de R$1.000.000,00', value: 6 },
    { key: 7, text: 'Não se aplica', value: 7 },
]

export const monthlyBillingOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Ainda não faturamos', value: 1 },
    { key: 2, text: 'Menos que R$10.000 mensais', value: 2 },
    { key: 3, text: 'Entre R$10.000 e R$50.000 mensais', value: 3 },
    { key: 4, text: 'Entre R$50.000 e R$90.000 mensais', value: 4 },
    { key: 5, text: 'Acima de R$90.000 mensais', value: 5 }
]